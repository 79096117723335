import { __async } from './chunk-2L3ZO4UM.mjs';
import { Web3Provider } from '@ethersproject/providers';
import React, { useCallback } from 'react';
import useSWRImmutable from 'swr/immutable';
import { WagmiConfig, useAccount, useNetwork, useSignMessage as useSignMessage$1 } from 'wagmi';
import { jsx } from 'react/jsx-runtime';

function WagmiProvider(props) {
  return /* @__PURE__ */ jsx(WagmiConfig, { client: props.client, children: /* @__PURE__ */ jsx(Web3LibraryProvider, { children: props.children }) });
}
var Web3LibraryContext = React.createContext(void 0);
var useWeb3LibraryContext = () => {
  return React.useContext(Web3LibraryContext);
};
var Web3LibraryProvider = (props) => {
  const { connector } = useAccount();
  const { chain } = useNetwork();
  const { data: library } = useSWRImmutable(connector && ["web3-library", connector, chain], () => __async(void 0, null, function* () {
    const provider = yield connector == null ? void 0 : connector.getProvider();
    return new Web3Provider(provider);
  }));
  return /* @__PURE__ */ jsx(Web3LibraryContext.Provider, { value: library, children: props.children });
};
function useWeb3React() {
  const { chain } = useNetwork();
  const { address, connector, isConnected, isConnecting } = useAccount();
  return {
    chainId: chain == null ? void 0 : chain.id,
    account: isConnected ? address : null,
    // TODO: migrate using `isConnected` instead of account to check wallet auth
    isConnected,
    isConnecting,
    chain,
    connector
  };
}
function useSignMessage() {
  const { address, connector } = useAccount();
  const { signMessageAsync: sign } = useSignMessage$1();
  return {
    signMessageAsync: useCallback(
      (args) => __async(this, null, function* () {
        var _a, _b;
        if ((connector == null ? void 0 : connector.id) === "bsc" && window.BinanceChain && address) {
          const res = yield (_b = (_a = window.BinanceChain).bnbSign) == null ? void 0 : _b.call(_a, address, args.message);
          if (res) {
            return res.signature;
          }
          return null;
        }
        return sign(args);
      }),
      [address, connector == null ? void 0 : connector.id, sign]
    )
  };
}

// src/chains/cronos.ts
var cronos = {
  id: 25,
  name: "Cronos",
  network: "cronos",
  nativeCurrency: {
    decimals: 18,
    name: "Cronos",
    symbol: "CRO"
  },
  rpcUrls: {
    public: { http: ["https://rpc.xstaking.sg"] },
    default: { http: ["https://rpc.xstaking.sg"] }
  },
  blockExplorers: {
    etherscan: { name: "CronoScan", url: "https://cronoscan.com" },
    default: { name: "CronoScan", url: "https://cronoscan.com" }
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 11907934
    }
  }
};

export { WagmiProvider, cronos, useSignMessage, useWeb3LibraryContext, useWeb3React };
