import { getVotingsContract } from 'utils/contractHelpers'
import { getBalanceAmount } from '@madmeerkatfinance/utils/formatBalance'
import { getFarmConfig } from '@madmeerkatfinance/farms/constants'
import { getVotingsAddress } from 'utils/addressHelpers'
import { multicallv2 } from 'utils/multicall'
import votingsABI from 'config/abi/votings.json'
import BigNumber from 'bignumber.js'
import { largeCapTokens } from 'config/constants/governance'
import { current } from '@reduxjs/toolkit'

export const fetchTokenGaugeAPR = async ({ selectedEpoch, chainId }: { selectedEpoch?: number; chainId: number }) => {
  const votingsContract = getVotingsContract(null, chainId)
  let currentEpoch = 0
  if (!selectedEpoch) {
    const temp = await votingsContract.getCurrentEpoch()
    currentEpoch = temp.toNumber()
  } else {
    currentEpoch = selectedEpoch
  }
  const votingsAddress = getVotingsAddress(chainId)
  const farmsConfig = await getFarmConfig(chainId)

  const tokenList = farmsConfig
  .filter(
    (farm) =>
      farm.pid !== 0 &&
      farm.multiplier !== '0X' &&
      !largeCapTokens.includes(farm.token.address)
  )
  .reduce((obj, farm) => ({ ...obj, [farm.token.address]: farm.token }), {})

  const bribeTokensCalls = Object.keys(tokenList).map((token) => ({
    address: votingsAddress,
    name: 'tokenBribeToken',
    params: [token, currentEpoch + 1]
  }))
  const bribeTokens = await multicallv2({
    abi: votingsABI,
    calls: bribeTokensCalls
  })

  const calls = Object.keys(tokenList).map((token) => ({
    address: votingsAddress,
    name: 'tokenBribeAmount',
    params: [token, currentEpoch + 1]
  }))
  const tokenBribeAmount = await multicallv2({
    abi: votingsABI,
    calls
  })

  const epochTokenBalanceCalls = Object.keys(tokenList).map((token) => ({
    address: votingsAddress,
    name: 'epochTokenBalance',
    params: [currentEpoch + 1, token]
  }))
  const epochTokenBalance = await multicallv2({
    abi: votingsABI,
    calls: epochTokenBalanceCalls
  })

  const voterCount = await votingsContract.voterCount(currentEpoch + 1)

  return {
    currentEpoch,
    isLoading: false,
    voterCount: voterCount.toNumber(),
    totalVEMMFHolder: 0,
    bribeInfo: Object.keys(tokenList).reduce(
      (obj, token, key) => ({
        ...obj,
        [Object.keys(tokenList)[key]]: {
          bribeAmount: getBalanceAmount(tokenBribeAmount[key] || new BigNumber('0')).toNumber(),
          bribeTokenAddress: bribeTokens[key]?.[(bribeTokens[key]?.length || 1) - 1],
          epochTokenBalance: getBalanceAmount(epochTokenBalance[key]?.toString()).toNumber()
        }
      }),
      {}
    )
  }
}

export const fetchTokenGaugePast = async ({ currentEpoch, chainId }: { currentEpoch: number; chainId: number }) => {
  const votingsAddress = getVotingsAddress(chainId)
  const farmsConfig = await getFarmConfig(chainId)
  const votingsContract = getVotingsContract(null, chainId)

  // TODO: we need to grab additional tokens that are registered against contract
  const tokenList = farmsConfig
  .filter(
    (farm) =>
      farm.pid !== 0 &&
      farm.multiplier !== '0X' &&
      !largeCapTokens.includes(farm.token.address)
  )
  .reduce((obj, farm) => ({ ...obj, [farm.token.address]: farm.token }), {})

  const bribeTokensCalls = Object.keys(tokenList).map((token) => ({
    address: votingsAddress,
    name: 'tokenBribeToken',
    params: [token, currentEpoch + 1]
  }))
  const bribeTokens = await multicallv2({
    abi: votingsABI,
    calls: bribeTokensCalls
  })

  const calls = Object.keys(tokenList).map((token) => ({
    address: votingsAddress,
    name: 'tokenBribeAmount',
    params: [token, currentEpoch + 1]
  }))
  const tokenBribeAmount = await multicallv2({
    abi: votingsABI,
    calls
  })

  const epochTokenBalanceCalls = Object.keys(tokenList).map((token) => ({
    address: votingsAddress,
    name: 'epochTokenBalance',
    params: [currentEpoch + 1, token]
  }))
  const epochTokenBalance = await multicallv2({
    abi: votingsABI,
    calls: epochTokenBalanceCalls
  })

  const voterCount = await votingsContract.voterCount(currentEpoch + 1)

  return {
    currentEpoch,
    isLoading: false,
    voterCount: voterCount.toNumber(),
    totalVEMMFHolder: 0,
    bribeInfo: Object.keys(tokenList).reduce(
      (obj, token, key) => ({
        ...obj,
        [Object.keys(tokenList)[key]]: {
          bribeAmount: getBalanceAmount(tokenBribeAmount[key] || new BigNumber('0')).toNumber(),
          bribeTokenAddress: bribeTokens[key][bribeTokens[key].length - 1],
          epochTokenBalance: getBalanceAmount(epochTokenBalance[key].toString()).toNumber()
        }
      }),
      {}
    )
  }
}

const getTokenVotes = async ({ contract, account, epoch, counter = 0, allTokens = [] }) => {
  try {
    const token = await contract.tokenVotes(account, epoch, counter)
    allTokens.push(token)
    if (token !== '0x0000000000000000000000000000000000000000') {
      return getTokenVotes({ contract, account, epoch, counter: counter + 1, allTokens })
    }
    return allTokens
  } catch (e) {
    return allTokens
  }
}

export const fetchUserVoted = async (account: string, contract, chainId: number) => {
  if (!account) {
    return {
      isLoading: false,
      balance: 0,
      tokenWeights: {}
    }
  }
  const votingsContract = getVotingsContract(null, chainId)
  const currentEpoch = await votingsContract.getCurrentEpoch()
  const votingsAddress = getVotingsAddress(chainId)

  try {
    const myVotedAmount = await contract.userBalance(account, currentEpoch.toNumber() + 1)
    let myVotedTokens = []
    if (new BigNumber(myVotedAmount._hex).toNumber() > 0) {
      myVotedTokens = await getTokenVotes({ contract, account, epoch: currentEpoch.toNumber() + 1 })
    }
    const tokenWeightCalls = myVotedTokens.map((token) => ({
      address: votingsAddress,
      name: 'tokenWeights',
      params: [account, currentEpoch.toNumber() + 1, token]
    }))
    const tokenWeights = await multicallv2({
      abi: votingsABI,
      calls: tokenWeightCalls
    })
    const tokenWeightsResult = myVotedTokens.reduce(
      (obj, token, index) => ({
        ...obj,
        [token]: getBalanceAmount(new BigNumber((tokenWeights[index] * myVotedAmount) / 100)).toNumber()
      }),
      {}
    )
    const userBalance = await votingsContract.userBalance(account, currentEpoch.toNumber() + 1)
    return {
      isLoading: false,
      balance: getBalanceAmount(userBalance.toString()).toNumber(),
      tokenWeights: tokenWeightsResult
    }
  } catch (error: any) {
    throw new Error(
      `Cannot load data for epoch ${currentEpoch} with wallet ${account}. (${
        error?.message || error?.toString() || error
      })`
    )
  }
}

export const fetchUserVotedPastEpoch = async (account: string, contract, currentEpoch: number, chainId: number) => {
  if (!account) {
    return {
      isLoading: false,
      balance: 0,
      tokenWeights: {}
    }
  }

  const votingsAddress = getVotingsAddress(chainId)
  const votingsContract = getVotingsContract(null, chainId)

  try {
    const myVotedTokens = await getTokenVotes({ contract, account, epoch: currentEpoch + 1 })
    const myVotedAmount = await contract.userBalance(account, currentEpoch + 1)
    const tokenWeightCalls = myVotedTokens.map((token) => ({
      address: votingsAddress,
      name: 'tokenWeights',
      params: [account, currentEpoch + 1, token]
    }))
    const tokenWeights = await multicallv2({
      abi: votingsABI,
      calls: tokenWeightCalls
    })
    const tokenWeightsResult = myVotedTokens.reduce(
      (obj, token, index) => ({
        ...obj,
        [token]: getBalanceAmount(new BigNumber((tokenWeights[index] * myVotedAmount) / 100)).toNumber()
      }),
      {}
    )
    const userBalance = await votingsContract.userBalance(account, currentEpoch + 1)

    const resultPendingRewards = {}
    await Promise.all(
      myVotedTokens.map(async (token) => {
        try {
          const data = await contract.pendingRewards(token, currentEpoch + 1, account)
          resultPendingRewards[token] = getBalanceAmount(data.toString()).toNumber()
        } catch (error) {
          console.log(error)
        }
      })
    )

    return {
      isLoading: false,
      balance: getBalanceAmount(userBalance.toString()).toNumber(),
      tokenWeights: tokenWeightsResult,
      resultPendingRewards
    }
  } catch (e) {
    console.log(e)
  }
  return {
    isLoading: false,
    balance: 0,
    tokenWeights: {}
  }
}
