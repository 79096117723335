var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// evm/legacyRouter/types/bestTrade.ts
var RouteType = /* @__PURE__ */ ((RouteType3) => {
  RouteType3[RouteType3["V2"] = 0] = "V2";
  RouteType3[RouteType3["STABLE_SWAP"] = 1] = "STABLE_SWAP";
  RouteType3[RouteType3["MIXED"] = 2] = "MIXED";
  return RouteType3;
})(RouteType || {});

// evm/legacyRouter/legacyRouter.ts
var legacyRouter_exports = {};
__export(legacyRouter_exports, {
  createStableSwapPair: () => createStableSwapPair,
  getAllCommonPairs: () => getAllCommonPairs,
  getBestTradeExactIn: () => getBestTradeExactIn,
  getBestTradeExactOut: () => getBestTradeExactOut,
  isStableSwapPair: () => isStableSwapPair,
  stableSwapPairsByChainId: () => stableSwapPairsByChainId
});

// evm/constants/common.ts
import { JSBI, Percent } from "@madmeerkatfinance/sdk";
var BIG_INT_TEN = JSBI.BigInt(10);
var BIPS_BASE = JSBI.BigInt(1e4);
var MIN_BNB = JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(16));
var BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), BIPS_BASE);

// evm/constants/exchange.ts
import { ChainId, WBNB, WNATIVE } from "@madmeerkatfinance/sdk";
import { bscTokens, bscTestnetTokens, BUSD, USDC, USDT, WBTC_ETH, arbitrumTokens, cronosTokens } from "@madmeerkatfinance/tokens";
var SWAP_ROUTER_ADDRESSES = {
  [ChainId.ETHEREUM]: "0x13f4EA83D0bd40E75C8222255bc855a974568Dd4",
  [ChainId.GOERLI]: "0x9a489505a00cE272eAa5e07Dba6491314CaE3796",
  [ChainId.BSC]: "0x13f4EA83D0bd40E75C8222255bc855a974568Dd4",
  [ChainId.ARBITRUM]: "0x20AB386813F59B92e1C4596f22591bEe92935d83",
  [ChainId.CRONOS]: "0x4e0186e2Cd19B383072f4f66829cbeEDa55c5f2e",
  [ChainId.BSC_TESTNET]: "0x9a489505a00cE272eAa5e07Dba6491314CaE3796"
};
var ROUTER_ADDRESS = {
  [ChainId.ETHEREUM]: "0x3BC722f252C7bAE2f55647e49aDcB9d33Ff6eBcC",
  [ChainId.GOERLI]: "0x3BC722f252C7bAE2f55647e49aDcB9d33Ff6eBcC",
  [ChainId.BSC]: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
  [ChainId.ARBITRUM]: "0x4F879a9d95e01041dDAA607A65F04e0EDbD04139",
  [ChainId.CRONOS]: "0x145677FC4d9b8F19B5D56d1820c48e0443049a30",
  [ChainId.BSC_TESTNET]: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1"
};
var STABLE_SWAP_INFO_ADDRESS = {
  [ChainId.ETHEREUM]: "",
  [ChainId.GOERLI]: "",
  [ChainId.BSC]: "0xa680d27f63Fa5E213C502d1B3Ca1EB6a3C1b31D6",
  [ChainId.ARBITRUM]: "",
  [ChainId.CRONOS]: "",
  [ChainId.BSC_TESTNET]: "0xaE6C14AAA753B3FCaB96149e1E10Bc4EDF39F546"
};
var BASES_TO_CHECK_TRADES_AGAINST = {
  [ChainId.ETHEREUM]: [WNATIVE[ChainId.ETHEREUM], USDC[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM], WBTC_ETH],
  [ChainId.GOERLI]: [WNATIVE[ChainId.GOERLI], USDC[ChainId.GOERLI], BUSD[ChainId.GOERLI]],
  [ChainId.BSC]: [
    bscTokens.wbnb,
    bscTokens.cake,
    bscTokens.busd,
    bscTokens.usdt,
    bscTokens.btcb,
    bscTokens.eth,
    bscTokens.usdc
  ],
  [ChainId.ARBITRUM]: [
    arbitrumTokens.weth,
    arbitrumTokens.mmf,
    arbitrumTokens.usdc,
    arbitrumTokens.usdt,
    arbitrumTokens.wbtc,
    arbitrumTokens.gmx
  ],
  [ChainId.CRONOS]: [
    cronosTokens.wcro,
    cronosTokens.mmf,
    cronosTokens.usdc,
    cronosTokens.usdt,
    cronosTokens.wbtc,
    cronosTokens.weth
  ],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.cake, bscTestnetTokens.busd]
};
var ADDITIONAL_BASES = {
  [ChainId.BSC]: {
    // SNFTS-SFUND
    [bscTokens.snfts.address]: [bscTokens.sfund]
  }
};
var CUSTOM_BASES = {
  [ChainId.BSC]: {
    [bscTokens.axlusdc.address]: [bscTokens.usdt]
  }
};
var SUGGESTED_BASES = {
  [ChainId.ETHEREUM]: [USDC[ChainId.ETHEREUM], WBNB[ChainId.ETHEREUM], BUSD[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM]],
  [ChainId.GOERLI]: [USDC[ChainId.GOERLI], WNATIVE[ChainId.GOERLI], BUSD[ChainId.GOERLI]],
  [ChainId.BSC]: [bscTokens.busd, bscTokens.cake, bscTokens.btcb],
  [ChainId.ARBITRUM]: [arbitrumTokens.usdc, arbitrumTokens.mmf, arbitrumTokens.wbtc, arbitrumTokens.weth, arbitrumTokens.usdt],
  [ChainId.CRONOS]: [cronosTokens.usdc, cronosTokens.mmf, cronosTokens.wbtc, cronosTokens.weth, cronosTokens.usdt, cronosTokens.wcro],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.cake, bscTestnetTokens.busd]
};
var BASES_TO_TRACK_LIQUIDITY_FOR = {
  [ChainId.ETHEREUM]: [
    USDC[ChainId.ETHEREUM],
    WNATIVE[ChainId.ETHEREUM],
    BUSD[ChainId.ETHEREUM],
    USDT[ChainId.ETHEREUM],
    WBNB[ChainId.ETHEREUM]
  ],
  [ChainId.GOERLI]: [USDC[ChainId.GOERLI], WNATIVE[ChainId.GOERLI], BUSD[ChainId.GOERLI]],
  [ChainId.BSC]: [bscTokens.wbnb, bscTokens.dai, bscTokens.busd, bscTokens.usdt, bscTokens.cake],
  [ChainId.ARBITRUM]: [arbitrumTokens.weth, arbitrumTokens.usdc, arbitrumTokens.usdt, arbitrumTokens.dai, arbitrumTokens.mmf],
  [ChainId.CRONOS]: [cronosTokens.weth, cronosTokens.usdc, cronosTokens.usdt, cronosTokens.dai, cronosTokens.mmf, cronosTokens.wcro],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.cake, bscTestnetTokens.busd]
};
var PINNED_PAIRS = {
  [ChainId.ETHEREUM]: [
    [WNATIVE[ChainId.ETHEREUM], USDC[ChainId.ETHEREUM]],
    [WBNB[ChainId.ETHEREUM], USDC[ChainId.ETHEREUM]],
    [WBNB[ChainId.ETHEREUM], BUSD[ChainId.ETHEREUM]],
    [WBNB[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM]],
    [WBNB[ChainId.ETHEREUM], WNATIVE[ChainId.ETHEREUM]]
  ],
  [ChainId.BSC]: [
    [bscTokens.cake, bscTokens.wbnb],
    [bscTokens.busd, bscTokens.usdt],
    [bscTokens.dai, bscTokens.usdt]
  ],
  [ChainId.ARBITRUM]: [
    [arbitrumTokens.mmf, arbitrumTokens.weth],
    [arbitrumTokens.usdc, arbitrumTokens.usdt],
    [arbitrumTokens.dai, arbitrumTokens.wbtc]
  ],
  [ChainId.CRONOS]: [
    [cronosTokens.mmf, cronosTokens.wcro],
    [cronosTokens.usdc, cronosTokens.usdt],
    [cronosTokens.dai, cronosTokens.wbtc]
  ]
};

// evm/constants/v3.ts
import { ChainId as ChainId2 } from "@madmeerkatfinance/sdk";
var V2_FEE_PATH_PLACEHOLDER = 8388608;
var MSG_SENDER = "0x0000000000000000000000000000000000000001";
var ADDRESS_THIS = "0x0000000000000000000000000000000000000002";
var MIXED_ROUTE_QUOTER_ADDRESSES = {
  [ChainId2.ETHEREUM]: "0x678Aa4bF4E210cf2166753e054d5b7c31cc7fa86",
  [ChainId2.GOERLI]: "0xB048Bbc1Ee6b733FFfCFb9e9CeF7375518e25997",
  [ChainId2.BSC]: "0x678Aa4bF4E210cf2166753e054d5b7c31cc7fa86",
  [ChainId2.ARBITRUM]: "0x85b6B458EA75e733eBe7982C37e931fCDeA65033",
  [ChainId2.CRONOS]: "0xc3e864E31733B54B91Bc38503E13faF68a68DaE7",
  [ChainId2.BSC_TESTNET]: "0xB048Bbc1Ee6b733FFfCFb9e9CeF7375518e25997"
};
var V3_QUOTER_ADDRESSES = {
  [ChainId2.ETHEREUM]: "0xB048Bbc1Ee6b733FFfCFb9e9CeF7375518e25997",
  [ChainId2.GOERLI]: "0xbC203d7f83677c7ed3F7acEc959963E7F4ECC5C2",
  [ChainId2.BSC]: "0xB048Bbc1Ee6b733FFfCFb9e9CeF7375518e25997",
  [ChainId2.ARBITRUM]: "0xE4E391A7a7d38B5eCE0724E66b132A8C011c2B20",
  [ChainId2.CRONOS]: "0x9ecEF39cAA0562cd65BA062E484a7ed12428B838",
  [ChainId2.BSC_TESTNET]: "0xbC203d7f83677c7ed3F7acEc959963E7F4ECC5C2"
};

// evm/constants/gasModel/index.ts
import { ChainId as ChainId4 } from "@madmeerkatfinance/sdk";
import { ethereumTokens, bscTokens as bscTokens2, bscTestnetTokens as bscTestnetTokens2, goerliTestnetTokens, arbitrumTokens as arbitrumTokens2, cronosTokens as cronosTokens2 } from "@madmeerkatfinance/tokens";

// evm/constants/gasModel/v2.ts
import { JSBI as JSBI2 } from "@madmeerkatfinance/sdk";
var BASE_SWAP_COST_V2 = JSBI2.BigInt(135e3);
var COST_PER_EXTRA_HOP_V2 = JSBI2.BigInt(5e4);

// evm/constants/gasModel/v3.ts
import { ChainId as ChainId3, JSBI as JSBI3 } from "@madmeerkatfinance/sdk";
var COST_PER_UNINIT_TICK = JSBI3.BigInt(0);
var BASE_SWAP_COST_V3 = (id) => {
  switch (id) {
    case ChainId3.BSC:
    case ChainId3.BSC_TESTNET:
    case ChainId3.ETHEREUM:
    case ChainId3.GOERLI:
      return JSBI3.BigInt(2e3);
    default:
      return JSBI3.BigInt(0);
  }
};
var COST_PER_INIT_TICK = (id) => {
  switch (id) {
    case ChainId3.BSC:
    case ChainId3.BSC_TESTNET:
    case ChainId3.ETHEREUM:
    case ChainId3.GOERLI:
      return JSBI3.BigInt(31e3);
    default:
      return JSBI3.BigInt(0);
  }
};
var COST_PER_HOP_V3 = (id) => {
  switch (id) {
    case ChainId3.BSC:
    case ChainId3.BSC_TESTNET:
    case ChainId3.ETHEREUM:
    case ChainId3.GOERLI:
      return JSBI3.BigInt(8e4);
    default:
      return JSBI3.BigInt(0);
  }
};

// evm/constants/gasModel/stableSwap.ts
import { JSBI as JSBI4 } from "@madmeerkatfinance/sdk";
var BASE_SWAP_COST_STABLE_SWAP = JSBI4.BigInt(18e4);
var COST_PER_EXTRA_HOP_STABLE_SWAP = JSBI4.BigInt(7e4);

// evm/constants/gasModel/index.ts
var usdGasTokensByChain = {
  [ChainId4.ETHEREUM]: [ethereumTokens.usdt],
  [ChainId4.GOERLI]: [goerliTestnetTokens.usdc],
  [ChainId4.BSC]: [bscTokens2.busd],
  [ChainId4.ARBITRUM]: [arbitrumTokens2.usdc],
  [ChainId4.CRONOS]: [cronosTokens2.usdc],
  [ChainId4.BSC_TESTNET]: [bscTestnetTokens2.busd]
};
var nativeWrappedTokenByChain = {
  [ChainId4.ETHEREUM]: ethereumTokens.weth,
  [ChainId4.GOERLI]: goerliTestnetTokens.weth,
  [ChainId4.BSC]: bscTokens2.wbnb,
  [ChainId4.ARBITRUM]: arbitrumTokens2.weth,
  [ChainId4.CRONOS]: cronosTokens2.wcro,
  [ChainId4.BSC_TESTNET]: bscTestnetTokens2.wbnb
};

// evm/legacyRouter/onchain/getPairs.ts
import { CurrencyAmount as CurrencyAmount2, Pair } from "@madmeerkatfinance/sdk";
import { createMulticall } from "@madmeerkatfinance/multicall";

// evm/utils/currency.ts
import { CurrencyAmount, Native, WNATIVE as WNATIVE2 } from "@madmeerkatfinance/sdk";
function wrappedCurrency(currency, chainId) {
  return (currency == null ? void 0 : currency.isNative) ? WNATIVE2[chainId] : (currency == null ? void 0 : currency.isToken) ? currency : void 0;
}
function wrappedCurrencyAmount(currencyAmount, chainId) {
  const token = currencyAmount && chainId ? wrappedCurrency(currencyAmount.currency, chainId) : void 0;
  return token && currencyAmount ? CurrencyAmount.fromRawAmount(token, currencyAmount.quotient) : void 0;
}

// evm/abis/IPancakePair.json
var IPancakePair_default = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256"
      }
    ],
    name: "Approval",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount0",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount1",
        type: "uint256"
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address"
      }
    ],
    name: "Burn",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount0",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount1",
        type: "uint256"
      }
    ],
    name: "Mint",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount0In",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount1In",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount0Out",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount1Out",
        type: "uint256"
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address"
      }
    ],
    name: "Swap",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint112",
        name: "reserve0",
        type: "uint112"
      },
      {
        indexed: false,
        internalType: "uint112",
        name: "reserve1",
        type: "uint112"
      }
    ],
    name: "Sync",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256"
      }
    ],
    name: "Transfer",
    type: "event"
  },
  {
    inputs: [],
    name: "DOMAIN_SEPARATOR",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "MINIMUM_LIQUIDITY",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "pure",
    type: "function"
  },
  {
    inputs: [],
    name: "PERMIT_TYPEHASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32"
      }
    ],
    stateMutability: "pure",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        internalType: "address",
        name: "spender",
        type: "address"
      }
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256"
      }
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      }
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address"
      }
    ],
    name: "burn",
    outputs: [
      {
        internalType: "uint256",
        name: "amount0",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "amount1",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8"
      }
    ],
    stateMutability: "pure",
    type: "function"
  },
  {
    inputs: [],
    name: "factory",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "getReserves",
    outputs: [
      {
        internalType: "uint112",
        name: "reserve0",
        type: "uint112"
      },
      {
        internalType: "uint112",
        name: "reserve1",
        type: "uint112"
      },
      {
        internalType: "uint32",
        name: "blockTimestampLast",
        type: "uint32"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      },
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "kLast",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address"
      }
    ],
    name: "mint",
    outputs: [
      {
        internalType: "uint256",
        name: "liquidity",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string"
      }
    ],
    stateMutability: "pure",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      }
    ],
    name: "nonces",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        internalType: "address",
        name: "spender",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "deadline",
        type: "uint256"
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8"
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32"
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32"
      }
    ],
    name: "permit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "price0CumulativeLast",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "price1CumulativeLast",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address"
      }
    ],
    name: "skim",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount0Out",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "amount1Out",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes"
      }
    ],
    name: "swap",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string"
      }
    ],
    stateMutability: "pure",
    type: "function"
  },
  {
    inputs: [],
    name: "sync",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "token0",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "token1",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256"
      }
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256"
      }
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  }
];

// evm/legacyRouter/onchain/getPairs.ts
function getPairs(_0, _1) {
  return __async(this, arguments, function* (currencyPairs, { provider, chainId }) {
    const tokens = currencyPairs.map(([currencyA, currencyB]) => [
      wrappedCurrency(currencyA, chainId),
      wrappedCurrency(currencyB, chainId)
    ]);
    const pairAddresses = tokens.map(getPairAddress);
    const { multicallv2 } = createMulticall(provider);
    const reserveCalls = pairAddresses.map((address) => ({
      address,
      name: "getReserves",
      params: []
    }));
    const results = yield multicallv2({
      abi: IPancakePair_default,
      calls: reserveCalls,
      chainId,
      options: {
        requireSuccess: false
      }
    });
    const resultWithState = results.map((result, i) => {
      if (!result)
        return [1 /* NOT_EXISTS */, null];
      const tokenA = tokens[i][0];
      const tokenB = tokens[i][1];
      if (!tokenA || !tokenB || tokenA.equals(tokenB))
        return [3 /* INVALID */, null];
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA];
      const { reserve0, reserve1 } = result;
      return [
        2 /* EXISTS */,
        new Pair(
          CurrencyAmount2.fromRawAmount(token0, reserve0.toString()),
          CurrencyAmount2.fromRawAmount(token1, reserve1.toString())
        )
      ];
    });
    const successfulResult = resultWithState.filter(
      (result) => Boolean(result[0] === 2 /* EXISTS */ && result[1])
    );
    return successfulResult.map(([, pair]) => pair);
  });
}
function getPairAddress([tokenA, tokenB]) {
  let addr = "";
  try {
    addr = tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB) : "";
  } catch (error) {
    console.error(error.msg, `- pairAddresses: ${tokenA == null ? void 0 : tokenA.address}-${tokenB == null ? void 0 : tokenB.address}`, `chainId: ${tokenA == null ? void 0 : tokenA.chainId}`);
  }
  return addr;
}

// evm/legacyRouter/onchain/getStableSwapOutputAmount.ts
import { CurrencyAmount as CurrencyAmount3 } from "@madmeerkatfinance/sdk";
import { createMulticall as createMulticall2 } from "@madmeerkatfinance/multicall";

// evm/legacyRouter/utils/pair.ts
function involvesToken(pair, token) {
  const { token0, token1 } = pair;
  return token0.wrapped.equals(token.wrapped) || token1.wrapped.equals(token.wrapped);
}
function isSamePair(one, another) {
  return involvesToken(another, one.token0) && involvesToken(another, one.token1);
}
function getOutputToken(pair, inputToken) {
  return inputToken.wrapped.equals(pair.token0.wrapped) ? pair.token1 : pair.token0;
}
function isStableSwapPair(pair) {
  return !!pair.stableSwapAddress;
}

// evm/abis/StableSwapPair.json
var StableSwapPair_default = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "provider",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256[2]",
        name: "token_amounts",
        type: "uint256[2]"
      },
      {
        indexed: false,
        internalType: "uint256[2]",
        name: "fees",
        type: "uint256[2]"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "invariant",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "token_supply",
        type: "uint256"
      }
    ],
    name: "AddLiquidity",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "deadline",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "fee",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "admin_fee",
        type: "uint256"
      }
    ],
    name: "CommitNewFee",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [],
    name: "DonateAdminFees",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [],
    name: "Kill",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "fee",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "admin_fee",
        type: "uint256"
      }
    ],
    name: "NewFee",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address"
      }
    ],
    name: "OwnershipTransferred",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "old_A",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "new_A",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "initial_time",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "future_time",
        type: "uint256"
      }
    ],
    name: "RampA",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "provider",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256[2]",
        name: "token_amounts",
        type: "uint256[2]"
      },
      {
        indexed: false,
        internalType: "uint256[2]",
        name: "fees",
        type: "uint256[2]"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "token_supply",
        type: "uint256"
      }
    ],
    name: "RemoveLiquidity",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "provider",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256[2]",
        name: "token_amounts",
        type: "uint256[2]"
      },
      {
        indexed: false,
        internalType: "uint256[2]",
        name: "fees",
        type: "uint256[2]"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "invariant",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "token_supply",
        type: "uint256"
      }
    ],
    name: "RemoveLiquidityImbalance",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "provider",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "token_amount",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "coin_amount",
        type: "uint256"
      }
    ],
    name: "RemoveLiquidityOne",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [],
    name: "RevertParameters",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "A",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "t",
        type: "uint256"
      }
    ],
    name: "StopRampA",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "buyer",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "sold_id",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokens_sold",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "bought_id",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "tokens_bought",
        type: "uint256"
      }
    ],
    name: "TokenExchange",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [],
    name: "Unkill",
    type: "event"
  },
  {
    inputs: [],
    name: "A",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "ADMIN_ACTIONS_DELAY",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "FEE_DENOMINATOR",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "KILL_DEADLINE_DT",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "MAX_A",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "MAX_ADMIN_FEE",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "MAX_A_CHANGE",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "MAX_DECIMAL",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "MAX_FEE",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "MIN_RAMP_TIME",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "N_COINS",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "PRECISION",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    name: "PRECISION_MUL",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    name: "RATES",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "STABLESWAP_FACTORY",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256[2]",
        name: "amounts",
        type: "uint256[2]"
      },
      {
        internalType: "uint256",
        name: "min_mint_amount",
        type: "uint256"
      }
    ],
    name: "add_liquidity",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "admin_actions_deadline",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "i",
        type: "uint256"
      }
    ],
    name: "admin_balances",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "admin_fee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "apply_new_fee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    name: "balances",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256[2]",
        name: "amounts",
        type: "uint256[2]"
      },
      {
        internalType: "bool",
        name: "deposit",
        type: "bool"
      }
    ],
    name: "calc_token_amount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_token_amount",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "i",
        type: "uint256"
      }
    ],
    name: "calc_withdraw_one_coin",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    name: "coins",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "new_fee",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "new_admin_fee",
        type: "uint256"
      }
    ],
    name: "commit_new_fee",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "donate_admin_fees",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "i",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "j",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "dx",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "min_dy",
        type: "uint256"
      }
    ],
    name: "exchange",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "fee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "future_A",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "future_A_time",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "future_admin_fee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "future_fee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "i",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "j",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "dx",
        type: "uint256"
      }
    ],
    name: "get_dy",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "i",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "j",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "dx",
        type: "uint256"
      }
    ],
    name: "get_dy_underlying",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "get_virtual_price",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "initial_A",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "initial_A_time",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address[2]",
        name: "_coins",
        type: "address[2]"
      },
      {
        internalType: "uint256",
        name: "_A",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "_fee",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "_admin_fee",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "_owner",
        type: "address"
      }
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "isInitialized",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "is_killed",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "kill_deadline",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "kill_me",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_future_A",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "_future_time",
        type: "uint256"
      }
    ],
    name: "ramp_A",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256"
      },
      {
        internalType: "uint256[2]",
        name: "min_amounts",
        type: "uint256[2]"
      }
    ],
    name: "remove_liquidity",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256[2]",
        name: "amounts",
        type: "uint256[2]"
      },
      {
        internalType: "uint256",
        name: "max_burn_amount",
        type: "uint256"
      }
    ],
    name: "remove_liquidity_imbalance",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_token_amount",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "i",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "min_amount",
        type: "uint256"
      }
    ],
    name: "remove_liquidity_one_coin",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "revert_new_parameters",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "stop_rampget_A",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "token",
    outputs: [
      {
        internalType: "contract PancakeStableSwapLP",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address"
      }
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "unkill_me",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "withdraw_admin_fees",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  }
];

// evm/legacyRouter/onchain/getStableSwapOutputAmount.ts
function getStableSwapOutputAmount(_0, _1, _2) {
  return __async(this, arguments, function* (pair, inputAmount, { provider }) {
    const { multicallv2 } = createMulticall2(provider);
    const wrappedInputAmount = wrappedCurrencyAmount(inputAmount, inputAmount.currency.chainId);
    if (!wrappedInputAmount) {
      throw new Error(`No wrapped token amount found for input amount: ${inputAmount.currency.name}`);
    }
    const chainId = inputAmount.currency.chainId;
    const inputToken = wrappedInputAmount.currency;
    const outputToken = getOutputToken(pair, inputToken);
    const inputRawAmount = inputAmount.wrapped.quotient.toString();
    const isOutputToken0 = pair.token0.equals(outputToken);
    const args = isOutputToken0 ? [1, 0, inputRawAmount] : [0, 1, inputRawAmount];
    const call = {
      address: pair.stableSwapAddress,
      name: "get_dy",
      params: args
    };
    const result = yield multicallv2({
      abi: StableSwapPair_default,
      calls: [call],
      chainId,
      options: {
        requireSuccess: true
      }
    });
    return CurrencyAmount3.fromRawAmount(outputToken, result.toString());
  });
}

// evm/legacyRouter/onchain/getStableSwapFee.ts
import { createMulticall as createMulticall3 } from "@madmeerkatfinance/multicall";
import { CurrencyAmount as CurrencyAmount4 } from "@madmeerkatfinance/sdk";

// evm/abis/InfoStableSwap.json
var InfoStableSwap_default = [
  {
    inputs: [],
    name: "FEE_DENOMINATOR",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "N_COINS",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "PRECISION",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_swap",
        type: "address"
      }
    ],
    name: "PRECISION_MUL",
    outputs: [
      {
        internalType: "uint256[2]",
        name: "swapPRECISION_MUL",
        type: "uint256[2]"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_swap",
        type: "address"
      }
    ],
    name: "RATES",
    outputs: [
      {
        internalType: "uint256[2]",
        name: "swapRATES",
        type: "uint256[2]"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_swap",
        type: "address"
      },
      {
        internalType: "uint256[2]",
        name: "_balances",
        type: "uint256[2]"
      }
    ],
    name: "_xp_mem",
    outputs: [
      {
        internalType: "uint256[2]",
        name: "result",
        type: "uint256[2]"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_swap",
        type: "address"
      }
    ],
    name: "balances",
    outputs: [
      {
        internalType: "uint256[2]",
        name: "swapBalances",
        type: "uint256[2]"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_swap",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256"
      }
    ],
    name: "calc_coins_amount",
    outputs: [
      {
        internalType: "uint256[2]",
        name: "",
        type: "uint256[2]"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_swap",
        type: "address"
      },
      {
        internalType: "uint256[2]",
        name: "_balances",
        type: "uint256[2]"
      },
      {
        internalType: "uint256",
        name: "amp",
        type: "uint256"
      }
    ],
    name: "get_D_mem",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_swap",
        type: "address"
      },
      {
        internalType: "uint256[2]",
        name: "amounts",
        type: "uint256[2]"
      }
    ],
    name: "get_add_liquidity_fee",
    outputs: [
      {
        internalType: "uint256[2]",
        name: "liquidityFee",
        type: "uint256[2]"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_swap",
        type: "address"
      },
      {
        internalType: "uint256[2]",
        name: "amounts",
        type: "uint256[2]"
      }
    ],
    name: "get_add_liquidity_mint_amount",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_swap",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "i",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "j",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "dx",
        type: "uint256"
      }
    ],
    name: "get_exchange_fee",
    outputs: [
      {
        internalType: "uint256",
        name: "exFee",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "exAdminFee",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_swap",
        type: "address"
      },
      {
        internalType: "uint256[2]",
        name: "amounts",
        type: "uint256[2]"
      }
    ],
    name: "get_remove_liquidity_imbalance_fee",
    outputs: [
      {
        internalType: "uint256[2]",
        name: "liquidityFee",
        type: "uint256[2]"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_swap",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "_token_amount",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "i",
        type: "uint256"
      }
    ],
    name: "get_remove_liquidity_one_coin_fee",
    outputs: [
      {
        internalType: "uint256",
        name: "adminFee",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_swap",
        type: "address"
      }
    ],
    name: "token",
    outputs: [
      {
        internalType: "contract IERC20",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  }
];

// evm/legacyRouter/onchain/getStableSwapFee.ts
function getStableSwapFeeCall(pair, inputAmount) {
  const { chainId } = inputAmount.currency;
  const wrappedInputAmount = wrappedCurrencyAmount(inputAmount, chainId);
  if (!wrappedInputAmount) {
    throw new Error(`No wrapped token amount found for input amount: ${inputAmount.currency.name}`);
  }
  const { stableSwapAddress } = pair;
  const inputToken = wrappedInputAmount.currency;
  const outputToken = getOutputToken(pair, inputToken);
  const inputRawAmount = inputAmount.wrapped.quotient.toString();
  const isOutputToken0 = pair.token0.equals(outputToken);
  const args = isOutputToken0 ? [stableSwapAddress, 1, 0, inputRawAmount] : [stableSwapAddress, 0, 1, inputRawAmount];
  return {
    address: pair.infoStableSwapAddress || STABLE_SWAP_INFO_ADDRESS[chainId],
    name: "get_exchange_fee",
    params: args
  };
}
function getStableSwapFee(_0, _1, _2) {
  return __async(this, arguments, function* (pair, inputAmount, { provider }) {
    const chainId = inputAmount.currency.chainId;
    const { multicallv2 } = createMulticall3(provider);
    const call = getStableSwapFeeCall(pair, inputAmount);
    const outputToken = getOutputToken(pair, inputAmount.currency);
    const [[feeRaw, adminFeeRaw]] = yield multicallv2({
      abi: InfoStableSwap_default,
      calls: [call],
      chainId,
      options: {
        requireSuccess: true
      }
    });
    return {
      fee: CurrencyAmount4.fromRawAmount(outputToken, feeRaw.toString()),
      adminFee: CurrencyAmount4.fromRawAmount(outputToken, adminFeeRaw.toString())
    };
  });
}

// evm/utils/flatMap.ts
function flatMap(list, map) {
  return list.reduce((acc, cur) => [...acc, ...map(cur)], []);
}

// evm/legacyRouter/getAllCommonPairs.ts
function getAllCommonPairs(_0, _1, _2) {
  return __async(this, arguments, function* (currencyA, currencyB, { provider }) {
    var _a, _b, _c, _d, _e;
    const chainId = currencyA.chainId;
    if (!chainId || chainId !== currencyB.chainId) {
      return [];
    }
    const [tokenA, tokenB] = chainId ? [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)] : [void 0, void 0];
    const common = (_a = BASES_TO_CHECK_TRADES_AGAINST[chainId]) != null ? _a : [];
    const additionalA = tokenA ? (_c = (_b = ADDITIONAL_BASES[chainId]) == null ? void 0 : _b[tokenA.address]) != null ? _c : [] : [];
    const additionalB = tokenB ? (_e = (_d = ADDITIONAL_BASES[chainId]) == null ? void 0 : _d[tokenB.address]) != null ? _e : [] : [];
    const bases = [...common, ...additionalA, ...additionalB];
    const basePairs = flatMap(
      bases,
      (base) => bases.map((otherBase) => [base, otherBase])
    );
    const allPairCombinations = getAllPairCombinations(tokenA, tokenB, bases, basePairs, chainId);
    const allPairs = yield getPairs(allPairCombinations, { chainId, provider });
    return Object.values(
      allPairs.reduce((memo, curr) => {
        var _a2;
        memo[curr.liquidityToken.address] = (_a2 = memo[curr.liquidityToken.address]) != null ? _a2 : curr;
        return memo;
      }, {})
    );
  });
}
var getAllPairCombinations = (tokenA, tokenB, bases, basePairs, chainId) => {
  return tokenA && tokenB ? [
    // the direct pair
    [tokenA, tokenB],
    // token A against all bases
    ...bases.map((base) => [tokenA, base]),
    // token B against all bases
    ...bases.map((base) => [tokenB, base]),
    // each base against all bases
    ...basePairs
  ].filter((tokens) => Boolean(tokens[0] && tokens[1])).filter(([t0, t1]) => t0.address !== t1.address).filter(([tokenA_, tokenB_]) => {
    if (!chainId)
      return true;
    const customBases = CUSTOM_BASES[chainId];
    const customBasesA = customBases == null ? void 0 : customBases[tokenA_.address];
    const customBasesB = customBases == null ? void 0 : customBases[tokenB_.address];
    if (!customBasesA && !customBasesB)
      return true;
    if (customBasesA && !customBasesA.find((base) => tokenB_.equals(base)))
      return false;
    if (customBasesB && !customBasesB.find((base) => tokenA_.equals(base)))
      return false;
    return true;
  }) : [];
};

// evm/legacyRouter/getBestTrade.ts
import { TradeType as TradeType5 } from "@madmeerkatfinance/sdk";

// evm/legacyRouter/getBestTradeFromStablePools.ts
import { Price as Price2, TradeType as TradeType2 } from "@madmeerkatfinance/sdk";

// evm/legacyRouter/getStableSwapPairs.ts
import { CurrencyAmount as CurrencyAmount6 } from "@madmeerkatfinance/sdk";

// ../token-lists/dist/chunk-BTPGGL4V.mjs
import { Token as Token5 } from "@madmeerkatfinance/swap-sdk-core";
var WrappedTokenInfo = class extends Token5 {
  constructor(tokenInfo) {
    super(tokenInfo.chainId, tokenInfo.address, tokenInfo.decimals, tokenInfo.symbol, tokenInfo.name);
    this.logoURI = tokenInfo.logoURI;
  }
  get serialize() {
    return {
      address: this.address,
      chainId: this.chainId,
      decimals: this.decimals,
      symbol: this.symbol,
      name: this.name,
      projectLink: this.projectLink,
      logoURI: this.logoURI
    };
  }
};
function deserializeToken(serializedToken) {
  if (serializedToken.logoURI) {
    return new WrappedTokenInfo({
      chainId: serializedToken.chainId,
      address: serializedToken.address,
      decimals: serializedToken.decimals,
      symbol: serializedToken.symbol || "Unknown",
      name: serializedToken.name || "Unknown",
      logoURI: serializedToken.logoURI
    });
  }
  return new Token5(
    serializedToken.chainId,
    serializedToken.address,
    serializedToken.decimals,
    serializedToken.symbol,
    serializedToken.name,
    serializedToken.projectLink
  );
}

// evm/legacyRouter/getStableSwapPairs.ts
import fromPairs_ from "lodash/fromPairs";

// evm/legacyRouter/stableSwap.ts
import { Percent as Percent2, Price, ERC20Token } from "@madmeerkatfinance/sdk";
import invariant from "tiny-invariant";
function createStableSwapPair(pair, stableSwapAddress = "", lpAddress = "", infoStableSwapAddress = "", stableLpFee = 0, stableLpFeeRateOfTotalFee = 0) {
  return __spreadProps(__spreadValues({}, pair), {
    stableSwapAddress,
    lpAddress,
    infoStableSwapAddress,
    liquidityToken: new ERC20Token(pair.token0.chainId, lpAddress, 18, "Stable-LP", "Pancake StableSwap LPs"),
    // default price & fees are zero, need to get the actual price from chain
    price: new Price(pair.token0, pair.token1, "0", "1"),
    fee: new Percent2(0),
    adminFee: new Percent2(0),
    involvesToken: (token) => token.equals(pair.token0) || token.equals(pair.token1),
    stableLpFee,
    stableLpFeeRateOfTotalFee
  });
}
function createRouteWithStableSwap({
  routeType,
  input,
  pairs,
  output
}) {
  const wrappedInput = input.wrapped;
  const path = [wrappedInput];
  for (const [i, pair] of pairs.entries()) {
    const out = getOutputToken(pair, path[i]);
    path.push(out);
  }
  return {
    routeType,
    input,
    output,
    pairs,
    path
  };
}
function createTradeWithStableSwap({
  routeType,
  pairs,
  inputAmount,
  outputAmount,
  tradeType
}) {
  return {
    tradeType,
    inputAmount,
    outputAmount,
    route: createRouteWithStableSwap({
      routeType,
      pairs,
      input: inputAmount.currency,
      output: outputAmount.currency
    })
  };
}
function createTradeWithStableSwapFromV2Trade({
  tradeType,
  inputAmount,
  outputAmount,
  route: { pairs }
}) {
  return createTradeWithStableSwap({
    routeType: 0 /* V2 */,
    pairs,
    inputAmount,
    outputAmount,
    tradeType
  });
}
function getFeePercent(inputAmount, outputAmount, { fee, adminFee }) {
  invariant(fee.currency.equals(outputAmount.currency), "FEE_CURRENCY_MATCH");
  invariant(adminFee.currency.equals(outputAmount.currency), "FEE_CURRENCY_MATCH");
  const priceWithoutFee = new Price({ baseAmount: outputAmount.add(fee), quoteAmount: inputAmount });
  const inputFee = priceWithoutFee.quote(fee);
  const inputAdminFee = priceWithoutFee.quote(adminFee);
  return {
    fee: new Percent2(inputFee.quotient, inputAmount.quotient),
    adminFee: new Percent2(inputAdminFee.quotient, inputAmount.quotient)
  };
}

// evm/constants/stableSwap/pools.ts
import { ChainId as ChainId10 } from "@madmeerkatfinance/sdk";

// evm/constants/stableSwap/56.ts
import { bscTokens as bscTokens3 } from "@madmeerkatfinance/tokens";
var pools = [
  {
    lpSymbol: "HAY-BUSD LP",
    lpAddress: "0xB6040A9F294477dDAdf5543a24E5463B8F2423Ae",
    token: bscTokens3.hay,
    quoteToken: bscTokens3.busd,
    stableSwapAddress: "0x49079d07ef47449af808a4f36c2a8dec975594ec",
    infoStableSwapAddress: "0xa680d27f63Fa5E213C502d1B3Ca1EB6a3C1b31D6",
    stableLpFee: 2e-4,
    stableLpFeeRateOfTotalFee: 0.5
  },
  {
    lpSymbol: "USDT-BUSD LP",
    lpAddress: "0x36842F8fb99D55477C0Da638aF5ceb6bBf86aA98",
    token: bscTokens3.usdt,
    quoteToken: bscTokens3.busd,
    stableSwapAddress: "0x169F653A54ACD441aB34B73dA9946e2C451787EF",
    infoStableSwapAddress: "0xa680d27f63Fa5E213C502d1B3Ca1EB6a3C1b31D6",
    stableLpFee: 5e-5,
    stableLpFeeRateOfTotalFee: 0.5
  },
  {
    lpSymbol: "USDC-BUSD LP",
    lpAddress: "0x1A77C359D0019cD8F4d36b7CDf5a88043D801072",
    token: bscTokens3.usdc,
    quoteToken: bscTokens3.busd,
    stableSwapAddress: "0xc2F5B9a3d9138ab2B74d581fC11346219eBf43Fe",
    infoStableSwapAddress: "0xa680d27f63Fa5E213C502d1B3Ca1EB6a3C1b31D6",
    stableLpFee: 5e-5,
    stableLpFeeRateOfTotalFee: 0.5
  },
  {
    lpSymbol: "USDT-USDC LP",
    lpAddress: "0xee1bcc9F1692E81A281b3a302a4b67890BA4be76",
    token: bscTokens3.usdt,
    quoteToken: bscTokens3.usdc,
    stableSwapAddress: "0x3EFebC418efB585248A0D2140cfb87aFcc2C63DD",
    infoStableSwapAddress: "0xa680d27f63Fa5E213C502d1B3Ca1EB6a3C1b31D6",
    stableLpFee: 5e-5,
    stableLpFeeRateOfTotalFee: 0.5
  },
  {
    lpSymbol: "axlUSDC-USDT LP",
    lpAddress: "0x1c7e5a3A72b6D94DE5Ec20812E3e68713978a584",
    token: bscTokens3.axlusdc,
    quoteToken: bscTokens3.usdt,
    stableSwapAddress: "0x6D8fba276ec6F1EDa2344DA48565AdbCA7e4FFa5",
    infoStableSwapAddress: "0x150c8AbEB487137acCC541925408e73b92F39A50",
    stableLpFee: 2e-4,
    stableLpFeeRateOfTotalFee: 0.5
  },
  {
    lpSymbol: "WBNB-stkBNB LP",
    lpAddress: "0x9976f5c8BEfDee650226d5571d5F5551e8722b75",
    token: bscTokens3.wbnb,
    quoteToken: bscTokens3.stkbnb,
    stableSwapAddress: "0x0b03e3d6Ec0c5e5bBf993dED8D947C6fb6eEc18D",
    infoStableSwapAddress: "0x150c8AbEB487137acCC541925408e73b92F39A50",
    stableLpFee: 2e-4,
    stableLpFeeRateOfTotalFee: 0.5
  },
  {
    lpSymbol: "BNBx-BNB LP",
    lpAddress: "0x92357Ab9003CA881E08e32CDAE59B10B3161b05C",
    token: bscTokens3.bnbx,
    quoteToken: bscTokens3.wbnb,
    stableSwapAddress: "0x9c138bE1D76ee4C5162E0fe9D4eEA5542a23D1bD",
    infoStableSwapAddress: "0x150c8AbEB487137acCC541925408e73b92F39A50",
    stableLpFee: 2e-4,
    stableLpFeeRateOfTotalFee: 0.5
  },
  {
    lpSymbol: "HAY-USDT LP",
    lpAddress: "0xB2Aa63f363196caba3154D4187949283F085a488",
    token: bscTokens3.hay,
    quoteToken: bscTokens3.usdt,
    stableSwapAddress: "0xb1Da7D2C257c5700612BdE35C8d7187dc80d79f1",
    infoStableSwapAddress: "0x150c8AbEB487137acCC541925408e73b92F39A50",
    stableLpFee: 4e-4,
    stableLpFeeRateOfTotalFee: 0.5
  }
];

// evm/constants/stableSwap/97.ts
import { ChainId as ChainId9, ERC20Token as ERC20Token2 } from "@madmeerkatfinance/sdk";
import { bscTestnetTokens as bscTestnetTokens3 } from "@madmeerkatfinance/tokens";
var mockUSDT = new ERC20Token2(
  ChainId9.BSC_TESTNET,
  "0x0fB5D7c73FA349A90392f873a4FA1eCf6a3d0a96",
  18,
  "USDT",
  "MOCK Token"
);
var pools2 = [
  // {
  //   lpSymbol: 'USDC-BUSD LP',
  //   lpAddress: '0xd1742b5eC6798cEB8C791e0ebbEf606A4946f67E',
  //   token: bscTestnetTokens.usdc, // coins[0]
  //   quoteToken: bscTestnetTokens.busd, // coins[1]
  //   stableSwapAddress: '0x1288026D2c5a76A5bfb0730F615131A448f4Ad06',
  //   infoStableSwapAddress: '0xaE6C14AAA753B3FCaB96149e1E10Bc4EDF39F546',
  // },
  {
    lpSymbol: "USDT-WBNB LP",
    lpAddress: "0x4c091Dc4418Bd3353A87488535528FD86954da2c",
    token: mockUSDT,
    // coins[0]
    quoteToken: bscTestnetTokens3.wbnb,
    // coins[1]
    stableSwapAddress: "0xBcd585Ee8B8Ac8de6b0e45dA32Aa31703036b2a1",
    infoStableSwapAddress: "0x0A548d59D04096Bc01206D58C3D63c478e1e06dB",
    stableLpFee: 4e-4,
    stableLpFeeRateOfTotalFee: 0.5
  },
  {
    lpSymbol: "USDT-BUSD LP",
    lpAddress: "0x746a7063101E2D3305D1556888ee08193f2B9a07",
    token: mockUSDT,
    // coins[0]
    quoteToken: bscTestnetTokens3.busd,
    // coins[1]
    stableSwapAddress: "0xE25A1352477f3DB9B3008B31e9b7a07a18f8A9e6",
    infoStableSwapAddress: "0x0A548d59D04096Bc01206D58C3D63c478e1e06dB",
    stableLpFee: 4e-4,
    stableLpFeeRateOfTotalFee: 0.5
  },
  {
    lpSymbol: "BUSD-USDC LP",
    lpAddress: "0x7CA885d338462790DD1B5416ebe6bec75ee045a1",
    token: bscTestnetTokens3.mockBusd,
    // coins[0]
    quoteToken: bscTestnetTokens3.usdc,
    // coins[1]
    stableSwapAddress: "0xd5E56CD4c8111643a94Ee084df31F44055a1EC9F",
    infoStableSwapAddress: "0xaE6C14AAA753B3FCaB96149e1E10Bc4EDF39F546",
    stableLpFee: 2e-4,
    stableLpFeeRateOfTotalFee: 0.5
  },
  {
    lpSymbol: "USDT-BUSD LP",
    lpAddress: "0x9Fa2Ef2C3dF6F903F4b73047311e861C51a11B60",
    token: bscTestnetTokens3.usdt,
    // coins[0]
    quoteToken: bscTestnetTokens3.mockBusd,
    // coins[1]
    stableSwapAddress: "0xc418d68751Cbe0407C8fdd90Cde73cE95b892f39",
    infoStableSwapAddress: "0xaE6C14AAA753B3FCaB96149e1E10Bc4EDF39F546",
    stableLpFee: 2e-4,
    stableLpFeeRateOfTotalFee: 0.5
  }
];

// evm/constants/stableSwap/pools.ts
var isStableSwapSupported = (chainId) => STABLE_SUPPORTED_CHAIN_IDS.includes(chainId);
var STABLE_SUPPORTED_CHAIN_IDS = [ChainId10.BSC, ChainId10.BSC_TESTNET];
var STABLE_POOL_MAP = {
  [ChainId10.BSC]: pools,
  [ChainId10.BSC_TESTNET]: pools2
};

// evm/constants/stableSwap/index.ts
function getStableSwapPools(chainId) {
  if (!isStableSwapSupported(chainId)) {
    return [];
  }
  return STABLE_POOL_MAP[chainId] || [];
}

// evm/legacyRouter/getStableSwapPairs.ts
function getStableSwapPairs(chainId) {
  const pools3 = getStableSwapPools(chainId);
  return pools3.map(
    ({
      token,
      quoteToken,
      stableSwapAddress,
      lpAddress,
      infoStableSwapAddress,
      stableLpFee,
      stableLpFeeRateOfTotalFee
    }) => {
      const token0 = deserializeToken(token);
      const token1 = deserializeToken(quoteToken);
      return createStableSwapPair(
        {
          token0,
          token1,
          reserve0: CurrencyAmount6.fromRawAmount(token0, "0"),
          reserve1: CurrencyAmount6.fromRawAmount(token1, "0")
        },
        stableSwapAddress,
        lpAddress,
        infoStableSwapAddress,
        stableLpFee,
        stableLpFeeRateOfTotalFee
      );
    }
  );
}
var stableSwapPairsByChainId = fromPairs_(
  STABLE_SUPPORTED_CHAIN_IDS.map((chainId) => [chainId, getStableSwapPairs(chainId)])
);

// evm/legacyRouter/getBestTradeFromStablePools.ts
function getBestTradeFromStablePools(_0, _1, _2) {
  return __async(this, arguments, function* (amount, output, { maxHops, provider }) {
    const {
      currency: { chainId }
    } = amount;
    const pairs = stableSwapPairsByChainId[chainId] || [];
    const routes = computeAllRoutes(amount.currency, output, pairs, maxHops);
    const trades = yield Promise.all(routes.map((r) => getStableTrade(amount, r, { provider })));
    if (!trades.length) {
      return null;
    }
    let bestTrade = trades[0];
    for (let i = 1; i < trades.length; i += 1) {
      const trade = trades[i];
      if (trade.outputAmount.greaterThan(bestTrade.outputAmount)) {
        bestTrade = trade;
      }
    }
    return bestTrade;
  });
}
function getStableTrade(_0, _1, _2) {
  return __async(this, arguments, function* (amountIn, pairs, { provider }) {
    let outputToken = amountIn.currency;
    let outputAmount = amountIn;
    const pairsWithPrice = [];
    for (const pair of pairs) {
      const inputAmount = outputAmount;
      const results = yield Promise.all([
        getStableSwapOutputAmount(pair, inputAmount, { provider }),
        getStableSwapFee(pair, inputAmount, { provider })
      ]);
      outputAmount = results[0];
      const fees = results[1];
      outputToken = getOutputToken(pair, outputToken);
      const { fee, adminFee } = getFeePercent(inputAmount, outputAmount, fees);
      pairsWithPrice.push(__spreadProps(__spreadValues({}, pair), {
        price: new Price2({ baseAmount: inputAmount, quoteAmount: outputAmount.add(fees.fee) }),
        fee,
        adminFee
      }));
    }
    return createTradeWithStableSwap({
      routeType: 1 /* STABLE_SWAP */,
      inputAmount: amountIn,
      outputAmount,
      pairs: pairsWithPrice,
      tradeType: TradeType2.EXACT_INPUT
    });
  });
}
function computeAllRoutes(input, output, pairs, maxHops = 3) {
  const poolsUsed = Array(pairs.length).fill(false);
  const routes = [];
  const computeRoutes = (currencyIn, currencyOut, currentRoute, _previousCurrencyOut) => {
    if (currentRoute.length > maxHops) {
      return;
    }
    if (currentRoute.length > 0 && involvesToken(currentRoute[currentRoute.length - 1], currencyOut)) {
      routes.push([...currentRoute]);
      return;
    }
    for (let i = 0; i < pairs.length; i++) {
      if (poolsUsed[i]) {
        continue;
      }
      const curPool = pairs[i];
      const previousCurrencyOut = _previousCurrencyOut || currencyIn;
      if (!involvesToken(curPool, previousCurrencyOut)) {
        continue;
      }
      const currentTokenOut = getOutputToken(curPool, previousCurrencyOut);
      currentRoute.push(curPool);
      poolsUsed[i] = true;
      computeRoutes(currencyIn, currencyOut, currentRoute, currentTokenOut);
      poolsUsed[i] = false;
      currentRoute.pop();
    }
  };
  computeRoutes(input, output, []);
  return routes;
}

// evm/legacyRouter/getBestTradeFromV2.ts
import { Trade as Trade2, TradeType as TradeType3, isTradeBetter } from "@madmeerkatfinance/sdk";
var getBestTradeFromV2ExactIn = createGetBestTradeFromV2(TradeType3.EXACT_INPUT);
var getBestTradeFromV2ExactOut = createGetBestTradeFromV2(TradeType3.EXACT_OUTPUT);
function createGetBestTradeFromV2(tradeType) {
  function getBestTrade2(pairs, amountIn, output, options) {
    if (tradeType === TradeType3.EXACT_INPUT) {
      return Trade2.bestTradeExactIn(pairs, amountIn, output, options);
    }
    return Trade2.bestTradeExactOut(pairs, output, amountIn, options);
  }
  return function bestTradeFromV2(amountIn, output, options) {
    return __async(this, null, function* () {
      var _b, _c;
      const _a = options, { provider, allCommonPairs } = _a, restOptions = __objRest(_a, ["provider", "allCommonPairs"]);
      const { maxHops = 3 } = restOptions;
      const getAllowedPairs = () => __async(this, null, function* () {
        if (Array.isArray(allCommonPairs)) {
          return allCommonPairs;
        }
        if (allCommonPairs) {
          return allCommonPairs(amountIn.currency, output);
        }
        return getAllCommonPairs(amountIn.currency, output, { provider });
      });
      const allowedPairs = yield getAllowedPairs();
      if (!allowedPairs.length) {
        return null;
      }
      if (maxHops === 1) {
        return (_b = getBestTrade2(allowedPairs, amountIn, output, restOptions)[0]) != null ? _b : null;
      }
      let bestTradeSoFar = null;
      for (let i = 1; i <= maxHops; i++) {
        const currentTrade = (_c = getBestTrade2(allowedPairs, amountIn, output, __spreadProps(__spreadValues({}, restOptions), {
          maxHops: i,
          maxNumResults: 1
        }))[0]) != null ? _c : null;
        if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
          bestTradeSoFar = currentTrade;
        }
      }
      return bestTradeSoFar;
    });
  };
}

// evm/legacyRouter/getBestTradeWithStableSwap.ts
import { CurrencyAmount as CurrencyAmount9, Price as Price3 } from "@madmeerkatfinance/sdk";
function getBestTradeWithStableSwap(baseTrade, stableSwapPairs, options) {
  return __async(this, null, function* () {
    const { provider } = options;
    const { inputAmount, route, tradeType } = baseTrade;
    if (!stableSwapPairs.length) {
      return createTradeWithStableSwapFromV2Trade(baseTrade);
    }
    const findStableSwapPair = (pair) => stableSwapPairs.find((p) => isSamePair(p, pair));
    let outputAmount = inputAmount;
    let outputToken = inputAmount.currency;
    const shouldRecalculateOutputAmount = () => !outputToken.equals(outputAmount.currency);
    const getLatestOutputAmount = () => __async(this, null, function* () {
      if (outputAmount.currency.equals(inputAmount.currency) && outputToken.equals(baseTrade.outputAmount.currency)) {
        return baseTrade.outputAmount;
      }
      return shouldRecalculateOutputAmount() ? getOutputAmountFromV2(outputAmount, outputToken, options) : outputAmount;
    });
    let routeType = null;
    const setCurrentRouteType = (type) => {
      routeType = routeType === null || routeType === type ? type : 2 /* MIXED */;
    };
    const pairsWithStableSwap = [];
    for (const [index, pair] of route.pairs.entries()) {
      const stableSwapPair = findStableSwapPair(pair);
      if (stableSwapPair) {
        const stableInputAmount = yield getLatestOutputAmount();
        const results = yield Promise.all([
          getStableSwapOutputAmount(stableSwapPair, stableInputAmount, { provider }),
          getStableSwapFee(stableSwapPair, stableInputAmount, { provider })
        ]);
        outputAmount = results[0];
        const fees = results[1];
        outputToken = getOutputToken(stableSwapPair, outputToken);
        const { fee, adminFee } = getFeePercent(stableInputAmount, outputAmount, fees);
        pairsWithStableSwap.push(__spreadProps(__spreadValues({}, stableSwapPair), {
          price: new Price3({ baseAmount: stableInputAmount, quoteAmount: outputAmount.add(fees.fee) }),
          fee,
          adminFee
        }));
        setCurrentRouteType(1 /* STABLE_SWAP */);
        continue;
      }
      outputToken = getOutputToken(pair, outputToken);
      if (index === route.pairs.length - 1) {
        outputAmount = yield getLatestOutputAmount();
      }
      pairsWithStableSwap.push(pair);
      setCurrentRouteType(0 /* V2 */);
    }
    if (routeType === null) {
      throw new Error(`No valid route found`);
    }
    return createTradeWithStableSwap({
      routeType,
      pairs: pairsWithStableSwap,
      inputAmount,
      // Make sure the output currency is the same as the output currency of v2 trade
      outputAmount: CurrencyAmount9.fromFractionalAmount(
        baseTrade.outputAmount.currency,
        outputAmount.numerator,
        outputAmount.denominator
      ),
      tradeType
    });
  });
}
function getOutputAmountFromV2(inputAmount, outputToken, options) {
  return __async(this, null, function* () {
    const trade = yield getBestTradeFromV2ExactIn(inputAmount, outputToken, options);
    if (!trade) {
      throw new Error(`Cannot get valid trade from ${inputAmount.currency.name} to ${outputToken.name}`);
    }
    return trade.outputAmount;
  });
}

// evm/legacyRouter/getBestTrade.ts
var getBestTradeExactIn = createGetBestTrade(TradeType5.EXACT_INPUT);
var getBestTradeExactOut = createGetBestTrade(TradeType5.EXACT_OUTPUT);
function createGetBestTrade(tradeType) {
  const isExactIn = tradeType === TradeType5.EXACT_INPUT;
  const getBestTradeFromV2 = isExactIn ? getBestTradeFromV2ExactIn : getBestTradeFromV2ExactOut;
  return function getBestTrade2(amountIn, output, options) {
    return __async(this, null, function* () {
      const { provider } = options;
      const {
        currency: { chainId }
      } = amountIn;
      const bestTradeV2 = yield getBestTradeFromV2(amountIn, output, options);
      const bestTradeStable = (bestTradeV2 || isExactIn) && (yield getBestTradeFromStablePools(
        (bestTradeV2 == null ? void 0 : bestTradeV2.inputAmount) || amountIn,
        (bestTradeV2 == null ? void 0 : bestTradeV2.outputAmount.currency) || output,
        options
      ));
      if (!bestTradeV2) {
        if (bestTradeStable) {
          return bestTradeStable;
        }
        return null;
      }
      const stableSwapPairs = stableSwapPairsByChainId[chainId] || [];
      const bestTradeWithStableSwap = yield getBestTradeWithStableSwap(bestTradeV2, stableSwapPairs, { provider });
      const { outputAmount: outputAmountWithStableSwap } = bestTradeWithStableSwap;
      if (bestTradeStable && bestTradeStable.outputAmount.greaterThan(outputAmountWithStableSwap) && bestTradeStable.outputAmount.greaterThan(bestTradeV2.outputAmount)) {
        return bestTradeStable;
      }
      if (outputAmountWithStableSwap.lessThan(bestTradeV2.outputAmount)) {
        return createTradeWithStableSwapFromV2Trade(bestTradeV2);
      }
      return bestTradeWithStableSwap;
    });
  };
}

// evm/legacyRouter/utils/route.ts
import { Price as Price4 } from "@madmeerkatfinance/sdk";
function getMidPrice(route) {
  const prices = [];
  for (const [i, pair] of route.pairs.entries()) {
    if (isStableSwapPair(pair)) {
      prices.push(route.path[i].wrapped.equals(pair.price.baseCurrency.wrapped) ? pair.price : pair.price.invert());
      continue;
    }
    prices.push(
      route.path[i].wrapped.equals(pair.token0.wrapped) ? new Price4(pair.reserve0.currency, pair.reserve1.currency, pair.reserve0.quotient, pair.reserve1.quotient) : new Price4(pair.reserve1.currency, pair.reserve0.currency, pair.reserve1.quotient, pair.reserve0.quotient)
    );
  }
  const reduced = prices.slice(1).reduce((accumulator, currentValue) => accumulator.multiply(currentValue), prices[0]);
  return new Price4(route.input, route.output, reduced.denominator, reduced.numerator);
}

// evm/legacyRouter/route.ts
var Route = {
  midPrice: getMidPrice
};

// evm/legacyRouter/trade.ts
import invariant2 from "tiny-invariant";
import {
  TradeType as TradeType6,
  ZERO,
  CurrencyAmount as CurrencyAmount11,
  Fraction,
  ONE,
  Price as Price5,
  computePriceImpact
} from "@madmeerkatfinance/sdk";
var Trade4 = {
  maximumAmountIn,
  minimumAmountOut,
  executionPrice,
  priceImpact
};
function maximumAmountIn(trade, slippageTolerance) {
  invariant2(!slippageTolerance.lessThan(ZERO), "SLIPPAGE_TOLERANCE");
  if (trade.tradeType === TradeType6.EXACT_INPUT) {
    return trade.inputAmount;
  }
  const slippageAdjustedAmountIn = new Fraction(ONE).add(slippageTolerance).multiply(trade.inputAmount.quotient).quotient;
  return CurrencyAmount11.fromRawAmount(trade.inputAmount.currency, slippageAdjustedAmountIn);
}
function minimumAmountOut(trade, slippageTolerance) {
  invariant2(!slippageTolerance.lessThan(ZERO), "SLIPPAGE_TOLERANCE");
  if (trade.tradeType === TradeType6.EXACT_OUTPUT) {
    return trade.outputAmount;
  }
  const slippageAdjustedAmountOut = new Fraction(ONE).add(slippageTolerance).invert().multiply(trade.outputAmount.quotient).quotient;
  return CurrencyAmount11.fromRawAmount(trade.outputAmount.currency, slippageAdjustedAmountOut);
}
function executionPrice({
  inputAmount,
  outputAmount
}) {
  return new Price5(inputAmount.currency, outputAmount.currency, inputAmount.quotient, outputAmount.quotient);
}
function priceImpact({
  route,
  inputAmount,
  outputAmount
}) {
  return computePriceImpact(getMidPrice(route), inputAmount, outputAmount);
}

// evm/stableSwap/index.ts
var stableSwap_exports = {};
__export(stableSwap_exports, {
  getD: () => getD,
  getLPOutput: () => getLPOutput,
  getLPOutputWithoutFee: () => getLPOutputWithoutFee,
  getSwapInput: () => getSwapInput,
  getSwapInputWithtouFee: () => getSwapInputWithtouFee,
  getSwapOutput: () => getSwapOutput,
  getSwapOutputWithoutFee: () => getSwapOutputWithoutFee
});

// evm/stableSwap/getLPOutputWithoutFee.ts
import { Percent as Percent5 } from "@madmeerkatfinance/sdk";

// evm/stableSwap/getLPOutput.ts
import { CurrencyAmount as CurrencyAmount13, JSBI as JSBI7, ZERO as ZERO3 } from "@madmeerkatfinance/sdk";
import invariant4 from "tiny-invariant";

// evm/stableSwap/amm.ts
import { JSBI as JSBI5, ZERO as ZERO2, ONE as ONE2 } from "@madmeerkatfinance/sdk";
import invariant3 from "tiny-invariant";
function getD({ amplifier, balances }) {
  const numOfCoins = balances.length;
  invariant3(numOfCoins > 1, "To get constant D, pool should have at least two coins.");
  const sum2 = balances.reduce((s, cur) => JSBI5.add(s, JSBI5.BigInt(cur)), ZERO2);
  if (JSBI5.equal(sum2, ZERO2)) {
    return ZERO2;
  }
  const n = JSBI5.BigInt(numOfCoins);
  const precision = ONE2;
  const ann = JSBI5.multiply(JSBI5.BigInt(amplifier), n);
  let dPrev = ZERO2;
  let d = sum2;
  for (let i = 0; i < 255; i += 1) {
    let dp = d;
    for (const b of balances) {
      dp = JSBI5.divide(JSBI5.multiply(dp, d), JSBI5.add(JSBI5.multiply(JSBI5.BigInt(b), n), JSBI5.BigInt(1)));
    }
    dPrev = d;
    d = JSBI5.divide(
      JSBI5.multiply(JSBI5.add(JSBI5.multiply(ann, sum2), JSBI5.multiply(dp, n)), d),
      JSBI5.add(JSBI5.multiply(JSBI5.subtract(ann, ONE2), d), JSBI5.multiply(JSBI5.add(n, ONE2), dp))
    );
    if (JSBI5.greaterThan(d, dPrev) && JSBI5.lessThanOrEqual(JSBI5.subtract(d, dPrev), precision)) {
      break;
    }
    if (JSBI5.lessThanOrEqual(d, dPrev) && JSBI5.lessThanOrEqual(JSBI5.subtract(dPrev, d), precision)) {
      break;
    }
  }
  return d;
}
function getY({ amplifier, balances, i, j, x }) {
  const numOfCoins = balances.length;
  invariant3(numOfCoins > 1, "To get y, pool should have at least two coins.");
  invariant3(i !== j && i >= 0 && j >= 0 && i < numOfCoins && j < numOfCoins, `Invalid i: ${i} and j: ${j}`);
  const n = JSBI5.BigInt(numOfCoins);
  const d = getD({ amplifier, balances });
  let sum2 = ZERO2;
  let c = d;
  const ann = JSBI5.multiply(JSBI5.BigInt(amplifier), n);
  for (const [index, b2] of balances.entries()) {
    if (index === j) {
      continue;
    }
    let balanceAfterDeposit = JSBI5.BigInt(b2);
    if (index === i) {
      balanceAfterDeposit = JSBI5.add(balanceAfterDeposit, JSBI5.BigInt(x));
    }
    invariant3(JSBI5.greaterThan(balanceAfterDeposit, ZERO2), "Insufficient liquidity");
    sum2 = JSBI5.add(sum2, balanceAfterDeposit);
    c = JSBI5.divide(JSBI5.multiply(c, d), JSBI5.multiply(balanceAfterDeposit, n));
  }
  c = JSBI5.divide(JSBI5.multiply(c, d), JSBI5.multiply(ann, n));
  const b = JSBI5.add(sum2, JSBI5.divide(d, ann));
  const precision = ONE2;
  let yPrev = ZERO2;
  let y = d;
  for (let k = 0; k < 255; k += 1) {
    yPrev = y;
    y = JSBI5.divide(JSBI5.add(JSBI5.multiply(y, y), c), JSBI5.subtract(JSBI5.add(JSBI5.multiply(JSBI5.BigInt(2), y), b), d));
    if (JSBI5.greaterThan(y, yPrev) && JSBI5.lessThanOrEqual(JSBI5.subtract(y, yPrev), precision)) {
      break;
    }
    if (JSBI5.lessThanOrEqual(y, yPrev) && JSBI5.lessThanOrEqual(JSBI5.subtract(yPrev, y), precision)) {
      break;
    }
  }
  return y;
}

// evm/stableSwap/utils.ts
import { JSBI as JSBI6, CurrencyAmount as CurrencyAmount12 } from "@madmeerkatfinance/sdk";
var PRECISION = JSBI6.exponentiate(JSBI6.BigInt(10), JSBI6.BigInt(18));
var getRawAmount = (amount) => {
  return JSBI6.divide(
    JSBI6.multiply(amount.quotient, PRECISION),
    JSBI6.exponentiate(JSBI6.BigInt(10), JSBI6.BigInt(amount.currency.decimals))
  );
};
var parseAmount = (currency, rawAmount) => {
  return CurrencyAmount12.fromRawAmount(
    currency,
    JSBI6.divide(
      JSBI6.multiply(rawAmount, JSBI6.exponentiate(JSBI6.BigInt(10), JSBI6.BigInt(currency.decimals))),
      PRECISION
    )
  );
};

// evm/stableSwap/getLPOutput.ts
function getLPOutput({
  amplifier,
  balances,
  totalSupply,
  amounts,
  fee
}) {
  const lpToken = totalSupply.currency;
  const lpTotalSupply = totalSupply.quotient;
  if (JSBI7.equal(lpTotalSupply, ZERO3) || !balances.length || balances.every((b) => JSBI7.equal(b.quotient, ZERO3))) {
    const d = getD({ amplifier, balances: amounts.map(getRawAmount) });
    return CurrencyAmount13.fromRawAmount(lpToken, d);
  }
  const currentBalances = [];
  const newBalances = [];
  for (const [i, balance] of balances.entries()) {
    const amount = amounts[i] || CurrencyAmount13.fromRawAmount(balance.currency, 0);
    invariant4(
      amount.currency.wrapped.equals(balance.currency.wrapped),
      "User input currency should be the same as pool balance currency."
    );
    const balanceRaw = getRawAmount(balance);
    const amountRaw = getRawAmount(amount);
    currentBalances.push(balanceRaw);
    newBalances.push(JSBI7.add(balanceRaw, amountRaw));
  }
  const d0 = getD({ amplifier, balances: currentBalances });
  const d1 = getD({ amplifier, balances: newBalances });
  invariant4(JSBI7.greaterThanOrEqual(d1, d0), "D1 should be greater than or equal than d0.");
  const isFirstSupply = JSBI7.lessThanOrEqual(lpTotalSupply, ZERO3);
  if (isFirstSupply) {
    return CurrencyAmount13.fromRawAmount(totalSupply.currency, d1);
  }
  const n = currentBalances.length;
  const eachTokenFee = fee.multiply(n).divide(4 * (n - 1));
  let d2 = d1;
  for (const [i, b] of currentBalances.entries()) {
    const idealBalance = JSBI7.divide(JSBI7.multiply(d1, b), d0);
    let diff = ZERO3;
    if (JSBI7.greaterThan(idealBalance, newBalances[i])) {
      diff = JSBI7.subtract(idealBalance, newBalances[i]);
    } else {
      diff = JSBI7.subtract(newBalances[i], idealBalance);
    }
    const feeAmount = eachTokenFee.multiply(diff).quotient;
    newBalances[i] = JSBI7.subtract(newBalances[i], feeAmount);
  }
  d2 = getD({ amplifier, balances: newBalances });
  const expectedMintLP = JSBI7.divide(JSBI7.multiply(lpTotalSupply, JSBI7.subtract(d2, d0)), d0);
  return CurrencyAmount13.fromRawAmount(totalSupply.currency, expectedMintLP);
}

// evm/stableSwap/getLPOutputWithoutFee.ts
function getLPOutputWithoutFee(params) {
  return getLPOutput(__spreadProps(__spreadValues({}, params), { fee: new Percent5(0) }));
}

// evm/stableSwap/getSwapOutput.ts
import { CurrencyAmount as CurrencyAmount15, JSBI as JSBI8, Percent as Percent6, ZERO as ZERO4, ONE_HUNDRED_PERCENT } from "@madmeerkatfinance/sdk";
import invariant5 from "tiny-invariant";
function getSwapOutput({
  amplifier,
  balances: balanceAmounts,
  outputCurrency,
  amount,
  fee
}) {
  const validateAmountOut = (a) => invariant5(!a.lessThan(ZERO4), "Insufficient liquidity to perform the swap");
  let i = null;
  let j = null;
  const balances = [];
  for (const [index, b] of balanceAmounts.entries()) {
    balances.push(getRawAmount(b));
    if (b.currency.wrapped.equals(amount.currency.wrapped)) {
      i = index;
      continue;
    }
    if (b.currency.wrapped.equals(outputCurrency.wrapped)) {
      j = index;
      continue;
    }
  }
  invariant5(
    i !== null && j !== null && i !== j,
    "Input currency or output currency does not match currencies of token balances."
  );
  if (JSBI8.lessThan(amount.quotient, ZERO4)) {
    const x = ONE_HUNDRED_PERCENT.subtract(fee).invert().multiply(getRawAmount(amount)).quotient;
    const y2 = getY({ amplifier, balances, i, j, x });
    const dy2 = JSBI8.subtract(y2, balances[j]);
    const amountOut2 = parseAmount(outputCurrency, dy2);
    validateAmountOut(amountOut2);
    return amountOut2;
  }
  const y = getY({ amplifier, balances, i, j, x: getRawAmount(amount) });
  const dy = JSBI8.subtract(balances[j], y);
  const feeAmount = fee.multiply(dy).quotient;
  const amountOut = parseAmount(outputCurrency, JSBI8.subtract(dy, feeAmount));
  validateAmountOut(amountOut);
  return amountOut;
}
function getSwapOutputWithoutFee(params) {
  return getSwapOutput(__spreadProps(__spreadValues({}, params), { fee: new Percent6(0) }));
}
function getSwapInput(_a) {
  var _b = _a, { amount } = _b, rest = __objRest(_b, ["amount"]);
  return getSwapOutput(__spreadProps(__spreadValues({}, rest), {
    amount: CurrencyAmount15.fromRawAmount(amount.currency, JSBI8.unaryMinus(amount.quotient))
  }));
}
function getSwapInputWithtouFee(params) {
  return getSwapInput(__spreadProps(__spreadValues({}, params), { fee: new Percent6(0) }));
}

// evm/v3-router/smartRouter.ts
var smartRouter_exports = {};
__export(smartRouter_exports, {
  createOffChainQuoteProvider: () => createOffChainQuoteProvider,
  createPoolProvider: () => createPoolProvider,
  createQuoteProvider: () => createQuoteProvider,
  createStaticPoolProvider: () => createStaticPoolProvider,
  getBestTrade: () => getBestTrade,
  getExecutionPrice: () => getExecutionPrice,
  getMidPrice: () => getMidPrice2,
  getPairCombinations: () => getPairCombinations,
  getStablePoolsOnChain: () => getStablePoolsOnChain,
  getV2PoolSubgraph: () => getV2PoolSubgraph,
  getV2PoolsOnChain: () => getV2PoolsOnChain,
  getV3PoolSubgraph: () => getV3PoolSubgraph,
  getV3PoolsWithoutTicksOnChain: () => getV3PoolsWithoutTicksOnChain,
  involvesCurrency: () => involvesCurrency,
  isStablePool: () => isStablePool,
  isV2Pool: () => isV2Pool,
  isV3Pool: () => isV3Pool,
  maximumAmountIn: () => maximumAmountIn2,
  metric: () => metric,
  minimumAmountOut: () => minimumAmountOut2,
  v2PoolSubgraphSelection: () => v2PoolSubgraphSelection,
  v3PoolSubgraphSelection: () => v3PoolSubgraphSelection
});

// evm/v3-router/getBestTrade.ts
import { TradeType as TradeType11, ZERO as ZERO6 } from "@madmeerkatfinance/sdk";

// evm/v3-router/functions/getAmountDistribution.ts
import { Fraction as Fraction2 } from "@madmeerkatfinance/sdk";
function getAmountDistribution(amount, distributionPercent) {
  const percents = [];
  const amounts = [];
  for (let i = 1; i <= 100 / distributionPercent; i++) {
    percents.push(i * distributionPercent);
    amounts.push(amount.multiply(new Fraction2(i * distributionPercent, 100)));
  }
  return [percents, amounts];
}

// evm/v3-router/utils/pool.ts
import { JSBI as JSBI10, Pair as Pair6, Price as Price6 } from "@madmeerkatfinance/sdk";
import { Pool as SDKV3Pool } from "@madmeerkatfinance/v3-sdk";

// ../utils/tryParseAmount.ts
import { parseUnits } from "@ethersproject/units";
import { CurrencyAmount as CurrencyAmount17, JSBI as JSBI9 } from "@madmeerkatfinance/swap-sdk-core";
function tryParseAmount(value, currency) {
  if (!value || !currency) {
    return void 0;
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString();
    if (typedValueParsed !== "0") {
      return CurrencyAmount17.fromRawAmount(currency, JSBI9.BigInt(typedValueParsed));
    }
  } catch (error) {
    console.debug(`Failed to parse input amount: "${value}"`, error);
  }
  return void 0;
}
var tryParseAmount_default = tryParseAmount;

// evm/v3-router/types/pool.ts
var PoolType = /* @__PURE__ */ ((PoolType3) => {
  PoolType3[PoolType3["V2"] = 0] = "V2";
  PoolType3[PoolType3["V3"] = 1] = "V3";
  PoolType3[PoolType3["STABLE"] = 2] = "STABLE";
  return PoolType3;
})(PoolType || {});

// evm/v3-router/types/route.ts
var RouteType2 = /* @__PURE__ */ ((RouteType3) => {
  RouteType3[RouteType3["V2"] = 0] = "V2";
  RouteType3[RouteType3["V3"] = 1] = "V3";
  RouteType3[RouteType3["STABLE"] = 2] = "STABLE";
  RouteType3[RouteType3["MIXED"] = 3] = "MIXED";
  return RouteType3;
})(RouteType2 || {});

// evm/v3-router/utils/pool.ts
function isV2Pool(pool) {
  return pool.type === 0 /* V2 */;
}
function isV3Pool(pool) {
  return pool.type === 1 /* V3 */;
}
function isStablePool(pool) {
  return pool.type === 2 /* STABLE */ && pool.balances.length >= 2;
}
function involvesCurrency(pool, currency) {
  const token = currency.wrapped;
  if (isV2Pool(pool)) {
    const { reserve0, reserve1 } = pool;
    return reserve0.currency.equals(token) || reserve1.currency.equals(token);
  }
  if (isV3Pool(pool)) {
    const { token0, token1 } = pool;
    return token0.equals(token) || token1.equals(token);
  }
  if (isStablePool(pool)) {
    const { balances } = pool;
    return balances.some((b) => b.currency.equals(token));
  }
  return false;
}
function getOutputCurrency(pool, currencyIn) {
  const tokenIn = currencyIn.wrapped;
  if (isV2Pool(pool)) {
    const { reserve0, reserve1 } = pool;
    return reserve0.currency.equals(tokenIn) ? reserve1.currency : reserve0.currency;
  }
  if (isV3Pool(pool)) {
    const { token0, token1 } = pool;
    return token0.equals(tokenIn) ? token1 : token0;
  }
  if (isStablePool(pool)) {
    const { balances } = pool;
    return balances[0].currency.equals(tokenIn) ? balances[1].currency : balances[0].currency;
  }
  throw new Error("Cannot get output currency by invalid pool");
}
function getPoolAddress(pool) {
  if (isStablePool(pool) || isV3Pool(pool)) {
    return pool.address;
  }
  if (isV2Pool(pool)) {
    const { reserve0, reserve1 } = pool;
    return Pair6.getAddress(reserve0.currency.wrapped, reserve1.currency.wrapped);
  }
  return "";
}
function getTokenPrice(pool, base, quote) {
  if (isV3Pool(pool)) {
    const { token0, token1, fee, liquidity, sqrtRatioX96, tick } = pool;
    const v3Pool = new SDKV3Pool(token0.wrapped, token1.wrapped, fee, sqrtRatioX96, liquidity, tick);
    return v3Pool.priceOf(base.wrapped);
  }
  if (isV2Pool(pool)) {
    const pair = new Pair6(pool.reserve0.wrapped, pool.reserve1.wrapped);
    return pair.priceOf(base.wrapped);
  }
  if (isStablePool(pool)) {
    const { amplifier, balances, fee } = pool;
    const baseIn = tryParseAmount_default("1", base);
    if (!baseIn) {
      throw new Error(`Cannot parse amount for ${base.symbol}`);
    }
    const quoteOut = getSwapOutput({
      amplifier,
      balances,
      fee,
      outputCurrency: quote,
      amount: baseIn
    });
    return new Price6({
      baseAmount: baseIn,
      quoteAmount: quoteOut
    });
  }
  return new Price6(base, quote, JSBI10.BigInt(1), JSBI10.BigInt(0));
}

// evm/v3-router/utils/isCurrenciesSameChain.ts
function isCurrenciesSameChain(...currencies) {
  var _a;
  const chainId = (_a = currencies[0]) == null ? void 0 : _a.chainId;
  for (const currency of currencies) {
    if (currency.chainId !== chainId) {
      return false;
    }
  }
  return true;
}

// evm/v3-router/utils/route.ts
function buildBaseRoute(pools3, currencyIn, currencyOut) {
  const path = [currencyIn.wrapped];
  let prevIn = path[0];
  let routeType = null;
  const updateRouteType = (pool, currentRouteType) => {
    if (currentRouteType === null) {
      return getRouteTypeFromPool(pool);
    }
    if (currentRouteType === 3 /* MIXED */ || currentRouteType !== getRouteTypeFromPool(pool)) {
      return 3 /* MIXED */;
    }
    return currentRouteType;
  };
  for (const pool of pools3) {
    prevIn = getOutputCurrency(pool, prevIn);
    path.push(prevIn);
    routeType = updateRouteType(pool, routeType);
  }
  if (routeType === null) {
    throw new Error(`Invalid route type when constructing base route`);
  }
  return {
    path,
    pools: pools3,
    type: routeType,
    input: currencyIn,
    output: currencyOut
  };
}
function getRouteTypeFromPool(pool) {
  switch (pool.type) {
    case 0 /* V2 */:
      return 0 /* V2 */;
    case 1 /* V3 */:
      return 1 /* V3 */;
    case 2 /* STABLE */:
      return 2 /* STABLE */;
    default:
      return 3 /* MIXED */;
  }
}
function getQuoteCurrency({ input, output }, baseCurrency) {
  return baseCurrency.equals(input) ? output : input;
}
function getMidPrice2({ path, pools: pools3 }) {
  let i = 0;
  let price = null;
  for (const pool of pools3) {
    const input = path[i].wrapped;
    const output = path[i + 1].wrapped;
    const poolPrice = getTokenPrice(pool, input, output);
    price = price ? price.multiply(poolPrice) : poolPrice;
    i += 1;
  }
  if (!price) {
    throw new Error("Get mid price failed");
  }
  return price;
}

// evm/v3-router/utils/encodeMixedRouteToPath.ts
import { pack } from "@ethersproject/solidity";
function encodeMixedRouteToPath(route, exactOutput) {
  const firstInputToken = route.input.wrapped;
  const { path, types } = route.pools.reduce(
    ({ inputToken, path: path2, types: types2 }, pool, index) => {
      const outputToken = getOutputCurrency(pool, inputToken).wrapped;
      const fee = isV3Pool(pool) ? pool.fee : V2_FEE_PATH_PLACEHOLDER;
      if (index === 0) {
        return {
          inputToken: outputToken,
          types: ["address", "uint24", "address"],
          path: [inputToken.address, fee, outputToken.address]
        };
      }
      return {
        inputToken: outputToken,
        types: [...types2, "uint24", "address"],
        path: [...path2, fee, outputToken.address]
      };
    },
    { inputToken: firstInputToken, path: [], types: [] }
  );
  return exactOutput ? pack(types.reverse(), path.reverse()) : pack(types, path);
}

// evm/v3-router/utils/getUsdGasToken.ts
function getUsdGasToken(chainId) {
  var _a, _b;
  return (_b = (_a = usdGasTokensByChain[chainId]) == null ? void 0 : _a[0]) != null ? _b : null;
}

// evm/v3-router/utils/getNativeWrappedToken.ts
function getNativeWrappedToken(chainId) {
  var _a;
  return (_a = nativeWrappedTokenByChain[chainId]) != null ? _a : null;
}

// evm/v3-router/utils/getExecutionPrice.ts
import { Price as Price7, JSBI as JSBI11, ZERO as ZERO5 } from "@madmeerkatfinance/sdk";
function getExecutionPrice({ inputAmount, outputAmount }) {
  if (JSBI11.equal(inputAmount.quotient, ZERO5) || JSBI11.equal(outputAmount.quotient, ZERO5)) {
    return null;
  }
  return new Price7(inputAmount.currency, outputAmount.currency, inputAmount.quotient, outputAmount.quotient);
}

// evm/v3-router/utils/maximumAmount.ts
import { TradeType as TradeType8, Fraction as Fraction3, ONE as ONE3, CurrencyAmount as CurrencyAmount18 } from "@madmeerkatfinance/sdk";
function maximumAmountIn2(trade, slippage, amountIn = trade.inputAmount) {
  if (trade.tradeType === TradeType8.EXACT_INPUT) {
    return amountIn;
  }
  const slippageAdjustedAmountIn = new Fraction3(ONE3).add(slippage).multiply(amountIn.quotient).quotient;
  return CurrencyAmount18.fromRawAmount(amountIn.currency, slippageAdjustedAmountIn);
}
function minimumAmountOut2(trade, slippage, amountOut = trade.outputAmount) {
  if (trade.tradeType === TradeType8.EXACT_OUTPUT) {
    return amountOut;
  }
  const slippageAdjustedAmountOut = new Fraction3(ONE3).add(slippage).invert().multiply(amountOut.quotient).quotient;
  return CurrencyAmount18.fromRawAmount(amountOut.currency, slippageAdjustedAmountOut);
}

// evm/v3-router/utils/metric.ts
import debug from "debug";
var metric = debug("smart-router:metric");

// evm/v3-router/functions/getPairCombinations.ts
var getPairCombinations = (currencyA, currencyB) => {
  var _a, _b, _c, _d, _e;
  const chainId = currencyA == null ? void 0 : currencyA.chainId;
  if (!chainId || !currencyA || !currencyB || !isCurrenciesSameChain(currencyA, currencyB)) {
    return [];
  }
  const [tokenA, tokenB] = chainId ? [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)] : [void 0, void 0];
  if (!tokenA || !tokenB) {
    return [];
  }
  const common = (_a = BASES_TO_CHECK_TRADES_AGAINST[chainId]) != null ? _a : [];
  const additionalA = tokenA ? (_c = (_b = ADDITIONAL_BASES[chainId]) == null ? void 0 : _b[tokenA.address]) != null ? _c : [] : [];
  const additionalB = tokenB ? (_e = (_d = ADDITIONAL_BASES[chainId]) == null ? void 0 : _d[tokenB.address]) != null ? _e : [] : [];
  const bases = [...common, ...additionalA, ...additionalB];
  const basePairs = flatMap(
    bases,
    (base) => bases.map((otherBase) => [base, otherBase])
  );
  return [
    // the direct pair
    [tokenA, tokenB],
    // token A against all bases
    ...bases.map((base) => [tokenA, base]),
    // token B against all bases
    ...bases.map((base) => [tokenB, base]),
    // each base against all bases
    ...basePairs
  ].filter((tokens) => Boolean(tokens[0] && tokens[1])).filter(([t0, t1]) => !t0.equals(t1)).filter(([tokenA_, tokenB_]) => {
    if (!chainId)
      return true;
    const customBases = CUSTOM_BASES[chainId];
    const customBasesA = customBases == null ? void 0 : customBases[tokenA_.wrapped.address];
    const customBasesB = customBases == null ? void 0 : customBases[tokenB_.wrapped.address];
    if (!customBasesA && !customBasesB)
      return true;
    if (customBasesA && !customBasesA.find((base) => tokenB_.equals(base)))
      return false;
    if (customBasesB && !customBasesB.find((base) => tokenA_.equals(base)))
      return false;
    return true;
  });
};

// evm/v3-router/functions/computeAllRoutes.ts
function computeAllRoutes2(input, output, candidatePools, maxHops = 3) {
  metric("Computing routes from", candidatePools.length, "pools");
  const poolsUsed = Array(candidatePools.length).fill(false);
  const routes = [];
  const computeRoutes = (currencyIn, currencyOut, currentRoute, _previousCurrencyOut) => {
    if (currentRoute.length > maxHops) {
      return;
    }
    if (currentRoute.length > 0 && involvesCurrency(currentRoute[currentRoute.length - 1], currencyOut)) {
      routes.push(buildBaseRoute([...currentRoute], currencyIn, currencyOut));
      return;
    }
    for (let i = 0; i < candidatePools.length; i++) {
      if (poolsUsed[i]) {
        continue;
      }
      const curPool = candidatePools[i];
      const previousCurrencyOut = _previousCurrencyOut || currencyIn;
      if (!involvesCurrency(curPool, previousCurrencyOut)) {
        continue;
      }
      const currentTokenOut = getOutputCurrency(curPool, previousCurrencyOut);
      currentRoute.push(curPool);
      poolsUsed[i] = true;
      computeRoutes(currencyIn, currencyOut, currentRoute, currentTokenOut);
      poolsUsed[i] = false;
      currentRoute.pop();
    }
  };
  computeRoutes(input, output, []);
  metric("Computed routes from", candidatePools.length, "pools", routes.length, "routes");
  return routes;
}

// evm/v3-router/functions/getBestRouteCombinationByQuotes.ts
import { CurrencyAmount as CurrencyAmount19, JSBI as JSBI12, TradeType as TradeType9 } from "@madmeerkatfinance/sdk";
import FixedReverseHeap from "mnemonist/fixed-reverse-heap";
import Queue from "mnemonist/queue";

// evm/utils/mapValues.ts
function mapValues(obj, map) {
  const result = {};
  for (const key of Object.keys(obj)) {
    result[key] = map(obj[key]);
  }
  return result;
}

// evm/v3-router/functions/getBestRouteCombinationByQuotes.ts
function getBestRouteCombinationByQuotes(amount, quoteCurrency, routesWithQuote, tradeType, config) {
  const chainId = amount.currency.chainId;
  const percents = [];
  const percentToQuotes = {};
  for (const routeWithQuote of routesWithQuote) {
    if (!percentToQuotes[routeWithQuote.percent]) {
      percentToQuotes[routeWithQuote.percent] = [];
      percents.push(routeWithQuote.percent);
    }
    percentToQuotes[routeWithQuote.percent].push(routeWithQuote);
  }
  const swapRoute = getBestSwapRouteBy(
    tradeType,
    percentToQuotes,
    percents.sort((a, b) => a - b),
    chainId,
    (rq) => rq.quoteAdjustedForGas,
    config
  );
  if (!swapRoute) {
    return null;
  }
  const { routes: routeAmounts } = swapRoute;
  const totalAmount = routeAmounts.reduce(
    (total, routeAmount) => total.add(routeAmount.amount),
    CurrencyAmount19.fromRawAmount(routeAmounts[0].amount.currency, 0)
  );
  const missingAmount = amount.subtract(totalAmount);
  if (missingAmount.greaterThan(0)) {
    console.log(
      {
        missingAmount: missingAmount.quotient.toString()
      },
      `Optimal route's amounts did not equal exactIn/exactOut total. Adding missing amount to last route in array.`
    );
    routeAmounts[routeAmounts.length - 1].amount = routeAmounts[routeAmounts.length - 1].amount.add(missingAmount);
  }
  console.log(
    {
      routes: routeAmounts,
      numSplits: routeAmounts.length,
      amount: amount.toExact(),
      quote: swapRoute.quote.toExact(),
      quoteGasAdjusted: swapRoute.quoteGasAdjusted.toFixed(Math.min(swapRoute.quoteGasAdjusted.currency.decimals, 2)),
      estimatedGasUSD: swapRoute.estimatedGasUsedUSD.toFixed(
        Math.min(swapRoute.estimatedGasUsedUSD.currency.decimals, 2)
      ),
      estimatedGasToken: swapRoute.estimatedGasUsedQuoteToken.toFixed(
        Math.min(swapRoute.estimatedGasUsedQuoteToken.currency.decimals, 2)
      )
    },
    `Found best swap route. ${routeAmounts.length} split.`
  );
  const { routes, quote: quoteAmount, estimatedGasUsed, estimatedGasUsedUSD } = swapRoute;
  const quote = CurrencyAmount19.fromRawAmount(quoteCurrency, quoteAmount.quotient);
  const isExactIn = tradeType === TradeType9.EXACT_INPUT;
  return {
    routes: routes.map(({ type, amount: routeAmount, quote: routeQuoteAmount, pools: pools3, path, percent }) => {
      const routeQuote = CurrencyAmount19.fromRawAmount(quoteCurrency, routeQuoteAmount.quotient);
      return {
        percent,
        type,
        pools: pools3,
        path,
        inputAmount: isExactIn ? routeAmount : routeQuote,
        outputAmount: isExactIn ? routeQuote : routeAmount
      };
    }),
    gasEstimate: estimatedGasUsed,
    gasEstimateInUSD: estimatedGasUsedUSD,
    inputAmount: isExactIn ? amount : quote,
    outputAmount: isExactIn ? quote : amount
  };
}
function getBestSwapRouteBy(tradeType, percentToQuotes, percents, chainId, by, { maxSplits = 4, minSplits = 0 }) {
  var _a;
  const percentToSortedQuotes = mapValues(percentToQuotes, (routeQuotes) => {
    return routeQuotes.sort((routeQuoteA, routeQuoteB) => {
      if (tradeType === TradeType9.EXACT_INPUT) {
        return by(routeQuoteA).greaterThan(by(routeQuoteB)) ? -1 : 1;
      }
      return by(routeQuoteA).lessThan(by(routeQuoteB)) ? -1 : 1;
    });
  });
  const quoteCompFn = tradeType === TradeType9.EXACT_INPUT ? (a, b) => a.greaterThan(b) : (a, b) => a.lessThan(b);
  const sumFn = (currencyAmounts) => {
    let sum2 = currencyAmounts[0];
    for (let i = 1; i < currencyAmounts.length; i++) {
      sum2 = sum2.add(currencyAmounts[i]);
    }
    return sum2;
  };
  let bestQuote;
  let bestSwap;
  const bestSwapsPerSplit = new FixedReverseHeap(
    Array,
    (a, b) => {
      return quoteCompFn(a.quote, b.quote) ? -1 : 1;
    },
    3
  );
  console.log({ bestSwapsPerSplit });
  if (!percentToSortedQuotes[100] || minSplits > 1) {
    console.log(
      {
        percentToSortedQuotes: mapValues(percentToSortedQuotes, (p) => p.length)
      },
      "Did not find a valid route without any splits. Continuing search anyway."
    );
  } else {
    bestQuote = by(percentToSortedQuotes[100][0]);
    bestSwap = [percentToSortedQuotes[100][0]];
    for (const routeWithQuote of percentToSortedQuotes[100].slice(0, 5)) {
      bestSwapsPerSplit.push({
        quote: by(routeWithQuote),
        routes: [routeWithQuote]
      });
    }
  }
  const queue = new Queue();
  for (let i = percents.length; i >= 0; i--) {
    const percent = percents[i];
    if (!percentToSortedQuotes[percent]) {
      continue;
    }
    queue.enqueue({
      curRoutes: [percentToSortedQuotes[percent][0]],
      percentIndex: i,
      remainingPercent: 100 - percent,
      special: false
    });
    if (!percentToSortedQuotes[percent] || !percentToSortedQuotes[percent][1]) {
      continue;
    }
    queue.enqueue({
      curRoutes: [percentToSortedQuotes[percent][1]],
      percentIndex: i,
      remainingPercent: 100 - percent,
      special: true
    });
  }
  let splits = 1;
  while (queue.size > 0) {
    console.log(
      {
        top5: Array.from(bestSwapsPerSplit.consume()).map(
          (q) => `${q.quote.toExact()} (${q.routes.map(
            (r) => `${r.percent}% ${r.amount.toExact()} ${r.pools.map((p) => {
              if (isV2Pool(p)) {
                return `V2 ${p.reserve0.currency.symbol}-${p.reserve1.currency.symbol}`;
              }
              if (isV3Pool(p)) {
                return `V3 fee ${p.fee} ${p.token0.symbol}-${p.token1.symbol}`;
              }
              return `Stable ${p}`;
            }).join(", ")} ${r.quote.toExact()}`
          ).join(", ")})`
        ),
        onQueue: queue.size
      },
      `Top 3 with ${splits} splits`
    );
    bestSwapsPerSplit.clear();
    let layer = queue.size;
    splits++;
    if (splits >= 3 && bestSwap && bestSwap.length < splits - 1) {
      break;
    }
    if (splits > maxSplits) {
      console.log("Max splits reached. Stopping search.");
      break;
    }
    while (layer > 0) {
      layer--;
      const { remainingPercent, curRoutes, percentIndex, special } = queue.dequeue();
      for (let i = percentIndex; i >= 0; i--) {
        const percentA = percents[i];
        if (percentA > remainingPercent) {
          continue;
        }
        if (!percentToSortedQuotes[percentA]) {
          continue;
        }
        const candidateRoutesA = percentToSortedQuotes[percentA];
        const routeWithQuoteA = findFirstRouteNotUsingUsedPools(curRoutes, candidateRoutesA);
        if (!routeWithQuoteA) {
          continue;
        }
        const remainingPercentNew = remainingPercent - percentA;
        const curRoutesNew = [...curRoutes, routeWithQuoteA];
        if (remainingPercentNew === 0 && splits >= minSplits) {
          console.log({ curRoutesNew });
          const quotesNew = curRoutesNew.map((r) => by(r));
          const quoteNew = sumFn(quotesNew);
          const gasCostL1QuoteToken2 = CurrencyAmount19.fromRawAmount(quoteNew.currency, 0);
          const quoteAfterL1Adjust = tradeType === TradeType9.EXACT_INPUT ? quoteNew.subtract(gasCostL1QuoteToken2) : quoteNew.add(gasCostL1QuoteToken2);
          bestSwapsPerSplit.push({
            quote: quoteAfterL1Adjust,
            routes: curRoutesNew
          });
          if (!bestQuote || quoteCompFn(quoteAfterL1Adjust, bestQuote)) {
            bestQuote = quoteAfterL1Adjust;
            bestSwap = curRoutesNew;
          }
        } else {
          queue.enqueue({
            curRoutes: curRoutesNew,
            remainingPercent: remainingPercentNew,
            percentIndex: i,
            special
          });
        }
      }
    }
  }
  if (!bestSwap) {
    console.log(`Could not find a valid swap`);
    return null;
  }
  let quoteGasAdjusted = sumFn(bestSwap.map((routeWithValidQuote) => routeWithValidQuote.quoteAdjustedForGas));
  const estimatedGasUsed = bestSwap.map((routeWithValidQuote) => routeWithValidQuote.gasEstimate).reduce((sum2, routeWithValidQuote) => JSBI12.add(sum2, routeWithValidQuote), JSBI12.BigInt(0));
  if (!usdGasTokensByChain[chainId] || !usdGasTokensByChain[chainId][0]) {
    throw new Error(`Could not find a USD token for computing gas costs on ${chainId}`);
  }
  const usdToken = usdGasTokensByChain[chainId][0];
  const usdTokenDecimals = usdToken.decimals;
  const gasCostsL1ToL2 = {
    gasUsedL1: JSBI12.BigInt(0),
    gasCostL1USD: CurrencyAmount19.fromRawAmount(usdToken, 0),
    gasCostL1QuoteToken: CurrencyAmount19.fromRawAmount(
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      (_a = bestSwap[0]) == null ? void 0 : _a.quote.currency.wrapped,
      0
    )
  };
  const { gasCostL1USD, gasCostL1QuoteToken } = gasCostsL1ToL2;
  const estimatedGasUsedUSDs = bestSwap.map((routeWithValidQuote) => {
    const decimalsDiff = Math.abs(usdTokenDecimals - routeWithValidQuote.gasCostInUSD.currency.decimals);
    if (decimalsDiff === 0) {
      return CurrencyAmount19.fromRawAmount(usdToken, routeWithValidQuote.gasCostInUSD.quotient);
    }
    return CurrencyAmount19.fromRawAmount(
      usdToken,
      JSBI12.multiply(
        routeWithValidQuote.gasCostInUSD.quotient,
        JSBI12.exponentiate(JSBI12.BigInt(10), JSBI12.BigInt(decimalsDiff))
      )
    );
  });
  let estimatedGasUsedUSD = sumFn(estimatedGasUsedUSDs);
  if (!estimatedGasUsedUSD.currency.equals(gasCostL1USD.currency)) {
    const decimalsDiff = usdTokenDecimals - gasCostL1USD.currency.decimals;
    estimatedGasUsedUSD = estimatedGasUsedUSD.add(
      CurrencyAmount19.fromRawAmount(
        usdToken,
        JSBI12.multiply(gasCostL1USD.quotient, JSBI12.exponentiate(JSBI12.BigInt(10), JSBI12.BigInt(decimalsDiff)))
      )
    );
  } else {
    estimatedGasUsedUSD = estimatedGasUsedUSD.add(gasCostL1USD);
  }
  const estimatedGasUsedQuoteToken = sumFn(
    bestSwap.map((routeWithValidQuote) => routeWithValidQuote.gasCostInToken)
  ).add(gasCostL1QuoteToken);
  const quote = sumFn(bestSwap.map((routeWithValidQuote) => routeWithValidQuote.quote));
  if (tradeType === TradeType9.EXACT_INPUT) {
    const quoteGasAdjustedForL1 = quoteGasAdjusted.subtract(gasCostL1QuoteToken);
    quoteGasAdjusted = quoteGasAdjustedForL1;
  } else {
    const quoteGasAdjustedForL1 = quoteGasAdjusted.add(gasCostL1QuoteToken);
    quoteGasAdjusted = quoteGasAdjustedForL1;
  }
  const routeWithQuotes = bestSwap.sort(
    (routeAmountA, routeAmountB) => routeAmountB.amount.greaterThan(routeAmountA.amount) ? 1 : -1
  );
  return {
    quote,
    quoteGasAdjusted,
    estimatedGasUsed,
    estimatedGasUsedUSD,
    estimatedGasUsedQuoteToken,
    routes: routeWithQuotes
  };
}
var findFirstRouteNotUsingUsedPools = (usedRoutes, candidateRouteQuotes) => {
  const poolAddressSet = /* @__PURE__ */ new Set();
  const usedPoolAddresses = flatMap(usedRoutes, ({ pools: pools3 }) => pools3.map(getPoolAddress));
  for (const poolAddress of usedPoolAddresses) {
    poolAddressSet.add(poolAddress);
  }
  for (const routeQuote of candidateRouteQuotes) {
    const { pools: pools3 } = routeQuote;
    const poolAddresses = pools3.map(getPoolAddress);
    if (poolAddresses.some((poolAddress) => poolAddressSet.has(poolAddress))) {
      continue;
    }
    return routeQuote;
  }
  return null;
};

// evm/v3-router/gasModel.ts
import { CurrencyAmount as CurrencyAmount20, JSBI as JSBI13 } from "@madmeerkatfinance/sdk";

// evm/utils/sum.ts
function sum(list) {
  let result;
  for (const item of list) {
    if (result === void 0) {
      result = item;
      continue;
    }
    result += item;
  }
  if (result === void 0)
    return 0;
  return result;
}

// evm/v3-router/gasModel.ts
function createGasModel(_0) {
  return __async(this, arguments, function* ({
    gasPriceWei,
    poolProvider,
    quoteCurrency,
    blockNumber
  }) {
    const { chainId } = quoteCurrency;
    const usdToken = getUsdGasToken(chainId);
    if (!usdToken) {
      throw new Error(`No valid usd token found on chain ${chainId}`);
    }
    const nativeWrappedToken = getNativeWrappedToken(chainId);
    if (!nativeWrappedToken) {
      throw new Error(`Unsupported chain ${chainId}. Native wrapped token not found.`);
    }
    const gasPrice = JSBI13.BigInt(typeof gasPriceWei === "function" ? yield gasPriceWei() : gasPriceWei);
    const [usdPool, nativePool] = yield Promise.all([
      getHighestLiquidityUSDPool(poolProvider, chainId, blockNumber),
      getHighestLiquidityNativePool(poolProvider, quoteCurrency, blockNumber)
    ]);
    const estimateGasCost = ({ pools: pools3 }, { initializedTickCrossedList }) => {
      const isQuoteNative = nativeWrappedToken.equals(quoteCurrency.wrapped);
      const totalInitializedTicksCrossed = JSBI13.BigInt(Math.max(1, sum(initializedTickCrossedList)));
      const poolTypeSet = /* @__PURE__ */ new Set();
      let baseGasUse = JSBI13.BigInt(0);
      for (const pool of pools3) {
        const { type } = pool;
        if (isV2Pool(pool)) {
          if (!poolTypeSet.has(type)) {
            baseGasUse = JSBI13.add(baseGasUse, BASE_SWAP_COST_V2);
            poolTypeSet.add(type);
            continue;
          }
          baseGasUse = JSBI13.add(baseGasUse, COST_PER_EXTRA_HOP_V2);
          continue;
        }
        if (isV3Pool(pool)) {
          if (!poolTypeSet.has(type)) {
            baseGasUse = JSBI13.add(baseGasUse, BASE_SWAP_COST_V3(chainId));
            poolTypeSet.add(type);
          }
          baseGasUse = JSBI13.add(baseGasUse, COST_PER_HOP_V3(chainId));
          continue;
        }
        if (isStablePool(pool)) {
          if (!poolTypeSet.has(type)) {
            baseGasUse = JSBI13.add(baseGasUse, BASE_SWAP_COST_STABLE_SWAP);
            poolTypeSet.add(type);
            continue;
          }
          baseGasUse = JSBI13.add(baseGasUse, COST_PER_EXTRA_HOP_STABLE_SWAP);
          continue;
        }
      }
      const tickGasUse = JSBI13.multiply(COST_PER_INIT_TICK(chainId), totalInitializedTicksCrossed);
      const uninitializedTickGasUse = JSBI13.multiply(COST_PER_UNINIT_TICK, JSBI13.BigInt(0));
      baseGasUse = JSBI13.add(JSBI13.add(baseGasUse, tickGasUse), uninitializedTickGasUse);
      const baseGasCostWei = JSBI13.multiply(gasPrice, baseGasUse);
      const totalGasCostNativeCurrency = CurrencyAmount20.fromRawAmount(nativeWrappedToken, baseGasCostWei);
      let gasCostInToken = CurrencyAmount20.fromRawAmount(quoteCurrency.wrapped, 0);
      let gasCostInUSD = CurrencyAmount20.fromRawAmount(usdToken, 0);
      if (isQuoteNative) {
        gasCostInToken = totalGasCostNativeCurrency;
      }
      if (!isQuoteNative && nativePool) {
        const price = getTokenPrice(nativePool, nativeWrappedToken, quoteCurrency.wrapped);
        gasCostInToken = price.quote(totalGasCostNativeCurrency);
      }
      if (usdPool) {
        const nativeTokenUsdPrice = getTokenPrice(usdPool, nativeWrappedToken, usdToken);
        gasCostInUSD = nativeTokenUsdPrice.quote(totalGasCostNativeCurrency);
      }
      return {
        gasEstimate: baseGasUse,
        gasCostInToken,
        gasCostInUSD
      };
    };
    return {
      estimateGasCost
    };
  });
}
function getHighestLiquidityNativePool(poolProvider, currency, blockNumber) {
  return __async(this, null, function* () {
    var _a;
    const nativeWrappedToken = getNativeWrappedToken(currency.chainId);
    if (!nativeWrappedToken || currency.wrapped.equals(nativeWrappedToken)) {
      return null;
    }
    const pools3 = yield poolProvider.getPools([[nativeWrappedToken, currency]], { blockNumber });
    return (_a = pools3[0]) != null ? _a : null;
  });
}
function getHighestLiquidityUSDPool(poolProvider, chainId, blockNumber) {
  return __async(this, null, function* () {
    var _a;
    const usdToken = getUsdGasToken(chainId);
    const nativeWrappedToken = getNativeWrappedToken(chainId);
    if (!usdToken || !nativeWrappedToken) {
      return null;
    }
    const pools3 = yield poolProvider.getPools([[nativeWrappedToken, usdToken]], { blockNumber });
    return (_a = pools3[0]) != null ? _a : null;
  });
}

// evm/v3-router/getRoutesWithValidQuote.ts
import { TradeType as TradeType10 } from "@madmeerkatfinance/sdk";

// evm/utils/chunk.ts
function chunk(list, size = 1) {
  const safeSize = Math.max(Number.parseInt(String(size)), 0);
  const length = list == null ? 0 : list.length;
  if (!length || safeSize < 1) {
    return [];
  }
  let index = 0;
  let resIndex = 0;
  const result = new Array(Math.ceil(length / safeSize));
  while (index < length) {
    result[resIndex++] = list.slice(index, index += safeSize);
  }
  return result;
}

// evm/v3-router/getRoutesWithValidQuote.ts
function getRoutesWithValidQuote(_0) {
  return __async(this, arguments, function* ({
    amount,
    baseRoutes,
    distributionPercent,
    quoteProvider,
    tradeType,
    blockNumber,
    gasModel,
    quoterOptimization = true
  }) {
    const [percents, amounts] = getAmountDistribution(amount, distributionPercent);
    const routesWithoutQuote = amounts.reduce(
      (acc, curAmount, i) => [
        ...acc,
        ...baseRoutes.map((r) => __spreadProps(__spreadValues({}, r), {
          amount: curAmount,
          percent: percents[i]
        }))
      ],
      []
    );
    const getRoutesWithQuote = tradeType === TradeType10.EXACT_INPUT ? quoteProvider.getRouteWithQuotesExactIn : quoteProvider.getRouteWithQuotesExactOut;
    if (!quoterOptimization) {
      return getRoutesWithQuote(routesWithoutQuote, { blockNumber, gasModel });
    }
    const requestCallback = typeof window === "undefined" ? setTimeout : window.requestIdleCallback || window.setTimeout;
    metric("Get quotes", "from", routesWithoutQuote.length, "routes", routesWithoutQuote);
    const getQuotes = (routes) => new Promise((resolve, reject) => {
      requestCallback(() => __async(this, null, function* () {
        try {
          const result2 = yield getRoutesWithQuote(routes, { blockNumber, gasModel });
          resolve(result2);
        } catch (e) {
          reject(e);
        }
      }));
    });
    const chunks = chunk(routesWithoutQuote, 10);
    const result = yield Promise.all(chunks.map(getQuotes));
    const quotes = result.reduce((acc, cur) => [...acc, ...cur], []);
    metric("Get quotes", "success, got", quotes.length, "quoted routes", quotes);
    return quotes;
  });
}

// evm/v3-router/getBestTrade.ts
function getBestTrade(amount, currency, tradeType, config) {
  return __async(this, null, function* () {
    const { blockNumber: blockNumberFromConfig } = config;
    const blockNumber = typeof blockNumberFromConfig === "function" ? yield blockNumberFromConfig() : blockNumberFromConfig;
    const bestRoutes = yield getBestRoutes(amount, currency, tradeType, __spreadProps(__spreadValues({}, config), {
      blockNumber
    }));
    if (!bestRoutes || bestRoutes.outputAmount.equalTo(ZERO6)) {
      throw new Error("Cannot find a valid swap route");
    }
    const { routes, gasEstimateInUSD, gasEstimate, inputAmount, outputAmount } = bestRoutes;
    return {
      tradeType,
      routes,
      gasEstimate,
      gasEstimateInUSD,
      inputAmount,
      outputAmount,
      blockNumber
    };
  });
}
function getBestRoutes(_0, _1, _2, _3) {
  return __async(this, arguments, function* (amount, currency, tradeType, {
    maxHops = 3,
    maxSplits = 4,
    distributionPercent = 5,
    poolProvider,
    quoteProvider,
    blockNumber,
    gasPriceWei,
    allowedPoolTypes,
    quoterOptimization
  }) {
    const isExactIn = tradeType === TradeType11.EXACT_INPUT;
    const inputCurrency = isExactIn ? amount.currency : currency;
    const outputCurrency = isExactIn ? currency : amount.currency;
    const candidatePools = yield poolProvider == null ? void 0 : poolProvider.getCandidatePools(amount.currency, currency, {
      blockNumber,
      protocols: allowedPoolTypes
    });
    let baseRoutes = computeAllRoutes2(inputCurrency, outputCurrency, candidatePools, maxHops);
    if (tradeType === TradeType11.EXACT_OUTPUT) {
      baseRoutes = baseRoutes.filter(({ type }) => type !== 3 /* MIXED */);
    }
    const gasModel = yield createGasModel({ gasPriceWei, poolProvider, quoteCurrency: currency, blockNumber });
    const routesWithValidQuote = yield getRoutesWithValidQuote({
      amount,
      baseRoutes,
      distributionPercent,
      quoteProvider,
      tradeType,
      blockNumber,
      gasModel,
      quoterOptimization
    });
    return getBestRouteCombinationByQuotes(amount, currency, routesWithValidQuote, tradeType, { maxSplits });
  });
}

// evm/v3-router/providers/poolProviderWithCache.ts
function createPoolProviderWithCache(provider) {
  return provider;
}

// evm/v3-router/providers/onChainPoolProviderFactory.ts
import {
  CurrencyAmount as CurrencyAmount23,
  Pair as Pair7,
  JSBI as JSBI14,
  Percent as Percent8,
  INIT_CODE_HASH_MAP_V3
} from "@madmeerkatfinance/sdk";
import { createMulticall as createMulticall4 } from "@madmeerkatfinance/multicall";
import { computePoolAddress, FeeAmount, DEPLOYER_ADDRESSES, parseProtocolFees } from "@madmeerkatfinance/v3-sdk";

// evm/abis/IPancakeV3Pool.json
var IPancakeV3Pool_default = [
  { inputs: [], stateMutability: "nonpayable", type: "constructor" },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "owner", type: "address" },
      { indexed: true, internalType: "int24", name: "tickLower", type: "int24" },
      { indexed: true, internalType: "int24", name: "tickUpper", type: "int24" },
      { indexed: false, internalType: "uint128", name: "amount", type: "uint128" },
      { indexed: false, internalType: "uint256", name: "amount0", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "amount1", type: "uint256" }
    ],
    name: "Burn",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "owner", type: "address" },
      { indexed: false, internalType: "address", name: "recipient", type: "address" },
      { indexed: true, internalType: "int24", name: "tickLower", type: "int24" },
      { indexed: true, internalType: "int24", name: "tickUpper", type: "int24" },
      { indexed: false, internalType: "uint128", name: "amount0", type: "uint128" },
      { indexed: false, internalType: "uint128", name: "amount1", type: "uint128" }
    ],
    name: "Collect",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "sender", type: "address" },
      { indexed: true, internalType: "address", name: "recipient", type: "address" },
      { indexed: false, internalType: "uint128", name: "amount0", type: "uint128" },
      { indexed: false, internalType: "uint128", name: "amount1", type: "uint128" }
    ],
    name: "CollectProtocol",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "sender", type: "address" },
      { indexed: true, internalType: "address", name: "recipient", type: "address" },
      { indexed: false, internalType: "uint256", name: "amount0", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "amount1", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "paid0", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "paid1", type: "uint256" }
    ],
    name: "Flash",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint16", name: "observationCardinalityNextOld", type: "uint16" },
      { indexed: false, internalType: "uint16", name: "observationCardinalityNextNew", type: "uint16" }
    ],
    name: "IncreaseObservationCardinalityNext",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint160", name: "sqrtPriceX96", type: "uint160" },
      { indexed: false, internalType: "int24", name: "tick", type: "int24" }
    ],
    name: "Initialize",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "address", name: "sender", type: "address" },
      { indexed: true, internalType: "address", name: "owner", type: "address" },
      { indexed: true, internalType: "int24", name: "tickLower", type: "int24" },
      { indexed: true, internalType: "int24", name: "tickUpper", type: "int24" },
      { indexed: false, internalType: "uint128", name: "amount", type: "uint128" },
      { indexed: false, internalType: "uint256", name: "amount0", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "amount1", type: "uint256" }
    ],
    name: "Mint",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint32", name: "feeProtocol0Old", type: "uint32" },
      { indexed: false, internalType: "uint32", name: "feeProtocol1Old", type: "uint32" },
      { indexed: false, internalType: "uint32", name: "feeProtocol0New", type: "uint32" },
      { indexed: false, internalType: "uint32", name: "feeProtocol1New", type: "uint32" }
    ],
    name: "SetFeeProtocol",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: "address", name: "addr", type: "address" }],
    name: "SetLmPoolEvent",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "sender", type: "address" },
      { indexed: true, internalType: "address", name: "recipient", type: "address" },
      { indexed: false, internalType: "int256", name: "amount0", type: "int256" },
      { indexed: false, internalType: "int256", name: "amount1", type: "int256" },
      { indexed: false, internalType: "uint160", name: "sqrtPriceX96", type: "uint160" },
      { indexed: false, internalType: "uint128", name: "liquidity", type: "uint128" },
      { indexed: false, internalType: "int24", name: "tick", type: "int24" },
      { indexed: false, internalType: "uint128", name: "protocolFeesToken0", type: "uint128" },
      { indexed: false, internalType: "uint128", name: "protocolFeesToken1", type: "uint128" }
    ],
    name: "Swap",
    type: "event"
  },
  {
    inputs: [
      { internalType: "int24", name: "tickLower", type: "int24" },
      { internalType: "int24", name: "tickUpper", type: "int24" },
      { internalType: "uint128", name: "amount", type: "uint128" }
    ],
    name: "burn",
    outputs: [
      { internalType: "uint256", name: "amount0", type: "uint256" },
      { internalType: "uint256", name: "amount1", type: "uint256" }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "int24", name: "tickLower", type: "int24" },
      { internalType: "int24", name: "tickUpper", type: "int24" },
      { internalType: "uint128", name: "amount0Requested", type: "uint128" },
      { internalType: "uint128", name: "amount1Requested", type: "uint128" }
    ],
    name: "collect",
    outputs: [
      { internalType: "uint128", name: "amount0", type: "uint128" },
      { internalType: "uint128", name: "amount1", type: "uint128" }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint128", name: "amount0Requested", type: "uint128" },
      { internalType: "uint128", name: "amount1Requested", type: "uint128" }
    ],
    name: "collectProtocol",
    outputs: [
      { internalType: "uint128", name: "amount0", type: "uint128" },
      { internalType: "uint128", name: "amount1", type: "uint128" }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "factory",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "fee",
    outputs: [{ internalType: "uint24", name: "", type: "uint24" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "feeGrowthGlobal0X128",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "feeGrowthGlobal1X128",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "amount0", type: "uint256" },
      { internalType: "uint256", name: "amount1", type: "uint256" },
      { internalType: "bytes", name: "data", type: "bytes" }
    ],
    name: "flash",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint16", name: "observationCardinalityNext", type: "uint16" }],
    name: "increaseObservationCardinalityNext",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint160", name: "sqrtPriceX96", type: "uint160" }],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "liquidity",
    outputs: [{ internalType: "uint128", name: "", type: "uint128" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "lmPool",
    outputs: [{ internalType: "contract IPancakeV3LmPool", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "maxLiquidityPerTick",
    outputs: [{ internalType: "uint128", name: "", type: "uint128" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "int24", name: "tickLower", type: "int24" },
      { internalType: "int24", name: "tickUpper", type: "int24" },
      { internalType: "uint128", name: "amount", type: "uint128" },
      { internalType: "bytes", name: "data", type: "bytes" }
    ],
    name: "mint",
    outputs: [
      { internalType: "uint256", name: "amount0", type: "uint256" },
      { internalType: "uint256", name: "amount1", type: "uint256" }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    name: "observations",
    outputs: [
      { internalType: "uint32", name: "blockTimestamp", type: "uint32" },
      { internalType: "int56", name: "tickCumulative", type: "int56" },
      { internalType: "uint160", name: "secondsPerLiquidityCumulativeX128", type: "uint160" },
      { internalType: "bool", name: "initialized", type: "bool" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint32[]", name: "secondsAgos", type: "uint32[]" }],
    name: "observe",
    outputs: [
      { internalType: "int56[]", name: "tickCumulatives", type: "int56[]" },
      { internalType: "uint160[]", name: "secondsPerLiquidityCumulativeX128s", type: "uint160[]" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    name: "positions",
    outputs: [
      { internalType: "uint128", name: "liquidity", type: "uint128" },
      { internalType: "uint256", name: "feeGrowthInside0LastX128", type: "uint256" },
      { internalType: "uint256", name: "feeGrowthInside1LastX128", type: "uint256" },
      { internalType: "uint128", name: "tokensOwed0", type: "uint128" },
      { internalType: "uint128", name: "tokensOwed1", type: "uint128" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "protocolFees",
    outputs: [
      { internalType: "uint128", name: "token0", type: "uint128" },
      { internalType: "uint128", name: "token1", type: "uint128" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint32", name: "feeProtocol0", type: "uint32" },
      { internalType: "uint32", name: "feeProtocol1", type: "uint32" }
    ],
    name: "setFeeProtocol",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "_lmPool", type: "address" }],
    name: "setLmPool",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "slot0",
    outputs: [
      { internalType: "uint160", name: "sqrtPriceX96", type: "uint160" },
      { internalType: "int24", name: "tick", type: "int24" },
      { internalType: "uint16", name: "observationIndex", type: "uint16" },
      { internalType: "uint16", name: "observationCardinality", type: "uint16" },
      { internalType: "uint16", name: "observationCardinalityNext", type: "uint16" },
      { internalType: "uint32", name: "feeProtocol", type: "uint32" },
      { internalType: "bool", name: "unlocked", type: "bool" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "int24", name: "tickLower", type: "int24" },
      { internalType: "int24", name: "tickUpper", type: "int24" }
    ],
    name: "snapshotCumulativesInside",
    outputs: [
      { internalType: "int56", name: "tickCumulativeInside", type: "int56" },
      { internalType: "uint160", name: "secondsPerLiquidityInsideX128", type: "uint160" },
      { internalType: "uint32", name: "secondsInside", type: "uint32" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "bool", name: "zeroForOne", type: "bool" },
      { internalType: "int256", name: "amountSpecified", type: "int256" },
      { internalType: "uint160", name: "sqrtPriceLimitX96", type: "uint160" },
      { internalType: "bytes", name: "data", type: "bytes" }
    ],
    name: "swap",
    outputs: [
      { internalType: "int256", name: "amount0", type: "int256" },
      { internalType: "int256", name: "amount1", type: "int256" }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [{ internalType: "int16", name: "", type: "int16" }],
    name: "tickBitmap",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "tickSpacing",
    outputs: [{ internalType: "int24", name: "", type: "int24" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "int24", name: "", type: "int24" }],
    name: "ticks",
    outputs: [
      { internalType: "uint128", name: "liquidityGross", type: "uint128" },
      { internalType: "int128", name: "liquidityNet", type: "int128" },
      { internalType: "uint256", name: "feeGrowthOutside0X128", type: "uint256" },
      { internalType: "uint256", name: "feeGrowthOutside1X128", type: "uint256" },
      { internalType: "int56", name: "tickCumulativeOutside", type: "int56" },
      { internalType: "uint160", name: "secondsPerLiquidityOutsideX128", type: "uint160" },
      { internalType: "uint32", name: "secondsOutside", type: "uint32" },
      { internalType: "bool", name: "initialized", type: "bool" }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "token0",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "token1",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  }
];

// evm/v3-router/providers/onChainPoolProviderFactory.ts
var getV2PoolsOnChain = createOnChainPoolFactory({
  abi: IPancakePair_default,
  getPossiblePoolMetas: ([currencyA, currencyB]) => [
    { address: Pair7.getAddress(currencyA.wrapped, currencyB.wrapped), currencyA, currencyB }
  ],
  buildPoolInfoCalls: (address) => [
    {
      address,
      name: "getReserves",
      params: []
    }
  ],
  buildPool: ({ currencyA, currencyB }, [reserves]) => {
    if (!reserves) {
      return null;
    }
    const { reserve0, reserve1 } = reserves;
    const [token0, token1] = currencyA.wrapped.sortsBefore(currencyB.wrapped) ? [currencyA, currencyB] : [currencyB, currencyA];
    return {
      type: 0 /* V2 */,
      reserve0: CurrencyAmount23.fromRawAmount(token0, reserve0),
      reserve1: CurrencyAmount23.fromRawAmount(token1, reserve1)
    };
  }
});
var getStablePoolsOnChain = createOnChainPoolFactory({
  abi: StableSwapPair_default,
  getPossiblePoolMetas: ([currencyA, currencyB]) => {
    const poolConfigs = getStableSwapPools(currencyA.chainId);
    return poolConfigs.filter(({ token, quoteToken }) => {
      const tokenA = deserializeToken(token);
      const tokenB = deserializeToken(quoteToken);
      return tokenA.equals(currencyA.wrapped) && tokenB.equals(currencyB.wrapped) || tokenA.equals(currencyB.wrapped) && tokenB.equals(currencyA.wrapped);
    }).map(({ stableSwapAddress }) => ({
      address: stableSwapAddress,
      currencyA,
      currencyB
    }));
  },
  buildPoolInfoCalls: (address) => [
    {
      address,
      name: "balances",
      params: [0]
    },
    {
      address,
      name: "balances",
      params: [1]
    },
    {
      address,
      name: "A",
      params: []
    },
    {
      address,
      name: "fee",
      params: []
    },
    {
      address,
      name: "FEE_DENOMINATOR",
      params: []
    }
  ],
  buildPool: ({ currencyA, currencyB, address }, [balance0, balance1, a, fee, feeDenominator]) => {
    if (!balance0 || !balance1 || !a || !fee || !feeDenominator) {
      return null;
    }
    const [token0, token1] = currencyA.wrapped.sortsBefore(currencyB.wrapped) ? [currencyA, currencyB] : [currencyB, currencyA];
    return {
      address,
      type: 2 /* STABLE */,
      balances: [CurrencyAmount23.fromRawAmount(token0, balance0), CurrencyAmount23.fromRawAmount(token1, balance1)],
      amplifier: JSBI14.BigInt(a),
      fee: new Percent8(JSBI14.BigInt(fee), JSBI14.BigInt(feeDenominator))
    };
  }
});
var getV3PoolsWithoutTicksOnChain = createOnChainPoolFactory({
  abi: IPancakeV3Pool_default,
  getPossiblePoolMetas: ([currencyA, currencyB]) => {
    const deployerAddress = DEPLOYER_ADDRESSES[currencyA.chainId];
    if (!deployerAddress) {
      return [];
    }
    return [FeeAmount.LOWEST, FeeAmount.LOW, FeeAmount.MEDIUM, FeeAmount.HIGH].map((fee) => ({
      address: computePoolAddress({
        deployerAddress,
        tokenA: currencyA.wrapped,
        tokenB: currencyB.wrapped,
        fee
      }),
      currencyA,
      currencyB,
      fee,
      initCodeHashManualOverride: INIT_CODE_HASH_MAP_V3[currencyA.chainId]
    }));
  },
  buildPoolInfoCalls: (address) => [
    {
      address,
      name: "liquidity",
      params: []
    },
    {
      address,
      name: "slot0",
      params: []
    }
  ],
  buildPool: ({ currencyA, currencyB, fee, address }, [liquidity, slot0]) => {
    if (!liquidity || !slot0) {
      return null;
    }
    const { sqrtPriceX96, tick, feeProtocol } = slot0;
    const [token0, token1] = currencyA.wrapped.sortsBefore(currencyB.wrapped) ? [currencyA, currencyB] : [currencyB, currencyA];
    const [token0ProtocolFee, token1ProtocolFee] = parseProtocolFees(feeProtocol);
    return {
      type: 1 /* V3 */,
      token0,
      token1,
      fee,
      liquidity: JSBI14.BigInt(liquidity),
      sqrtRatioX96: JSBI14.BigInt(sqrtPriceX96),
      tick: Number(tick),
      address,
      token0ProtocolFee,
      token1ProtocolFee
    };
  }
});
function createOnChainPoolFactory({
  abi,
  getPossiblePoolMetas,
  buildPoolInfoCalls,
  buildPool
}) {
  return function poolFactory(pairs, provider, blockNumber) {
    return __async(this, null, function* () {
      var _a, _b;
      const chainId = (_b = (_a = pairs[0]) == null ? void 0 : _a[0]) == null ? void 0 : _b.chainId;
      if (!chainId) {
        return [];
      }
      const { multicallv2 } = createMulticall4(provider);
      const poolAddressSet = /* @__PURE__ */ new Set();
      const poolMetas = [];
      for (const pair of pairs) {
        const possiblePoolMetas = getPossiblePoolMetas(pair);
        for (const meta of possiblePoolMetas) {
          if (!poolAddressSet.has(meta.address)) {
            poolMetas.push(meta);
            poolAddressSet.add(meta.address);
          }
        }
      }
      let calls = [];
      let poolCallSize = 0;
      for (const { address } of poolMetas) {
        const poolCalls = buildPoolInfoCalls(address);
        if (!poolCallSize) {
          poolCallSize = poolCalls.length;
        }
        if (!poolCallSize || poolCallSize !== poolCalls.length) {
          throw new Error("Inconsistent pool data call");
        }
        calls = [...calls, ...poolCalls];
      }
      if (!calls.length) {
        return [];
      }
      const results = yield multicallv2({
        abi,
        calls,
        chainId,
        options: {
          requireSuccess: false,
          blockTag: JSBI14.toNumber(JSBI14.BigInt(blockNumber))
        }
      });
      const pools3 = [];
      for (let i = 0; i < poolMetas.length; i += 1) {
        const poolResults = results.slice(i * poolCallSize, (i + 1) * poolCallSize);
        const pool = buildPool(poolMetas[i], poolResults);
        if (pool) {
          pools3.push(pool);
        }
      }
      return pools3;
    });
  };
}

// evm/v3-router/providers/hybridPoolProvider.ts
function createHybridPoolProvider({ onChainProvider }) {
  const hybridPoolProvider = {
    getCandidatePools: (currencyA, currencyB, options) => __async(this, null, function* () {
      const pairs = getPairCombinations(currencyA, currencyB);
      return hybridPoolProvider.getPools(pairs, options);
    }),
    getPools: (_0, _1) => __async(this, [_0, _1], function* (pairs, { blockNumber, protocols }) {
      return getPools(pairs, { provider: onChainProvider, blockNumber, protocols });
    })
  };
  return createPoolProviderWithCache(hybridPoolProvider);
}
function getPools(_0, _1) {
  return __async(this, arguments, function* (pairs, { provider, blockNumber, protocols = [1 /* V3 */, 0 /* V2 */, 2 /* STABLE */] }) {
    var _a, _b;
    const chainId = (_b = (_a = pairs[0]) == null ? void 0 : _a[0]) == null ? void 0 : _b.chainId;
    if (!chainId) {
      return [];
    }
    const poolSets = yield Promise.all(
      protocols.map((protocol) => {
        if (protocol === 0 /* V2 */) {
          return getV2PoolsOnChain(pairs, provider, blockNumber);
        }
        if (protocol === 1 /* V3 */) {
          return getV3PoolsWithoutTicksOnChain(pairs, provider, blockNumber);
        }
        return getStablePoolsOnChain(pairs, provider, blockNumber);
      })
    );
    return poolSets.reduce((acc, cur) => [...acc, ...cur], []);
  });
}

// evm/v3-router/providers/poolProviders.ts
function createPoolProvider(config) {
  const hybridPoolProvider = createHybridPoolProvider(config);
  return hybridPoolProvider;
}

// evm/v3-router/providers/offChainQuoteProvider.ts
import { JSBI as JSBI15, Pair as Pair8, ZERO as ZERO7 } from "@madmeerkatfinance/sdk";
import { Pool as V3Pool3, TickList } from "@madmeerkatfinance/v3-sdk";
function createOffChainQuoteProvider() {
  const createGetRoutesWithQuotes = (isExactIn = true) => {
    const getV2Quote = createGetV2Quote(isExactIn);
    const getStableQuote = createGetStableQuote(isExactIn);
    const getV3Quote = createGetV3Quote(isExactIn);
    function* each(pools3) {
      let i = isExactIn ? 0 : pools3.length - 1;
      const hasNext = () => isExactIn ? i < pools3.length : i >= 0;
      while (hasNext()) {
        yield [pools3[i], i];
        if (isExactIn) {
          i += 1;
        } else {
          i -= 1;
        }
      }
    }
    const adjustQuoteForGas = (quote, gasCostInToken) => {
      if (isExactIn) {
        return quote.subtract(gasCostInToken);
      }
      return quote.add(gasCostInToken);
    };
    return function getRoutesWithQuotes(_0, _1) {
      return __async(this, arguments, function* (routes, { gasModel }) {
        const routesWithQuote = [];
        for (const route of routes) {
          try {
            const { pools: pools3, amount } = route;
            let quote = amount;
            const initializedTickCrossedList = Array(pools3.length).fill(0);
            let quoteSuccess = true;
            for (const [pool, i] of each(pools3)) {
              if (isV2Pool(pool)) {
                quote = getV2Quote(pool, quote);
                continue;
              }
              if (isStablePool(pool)) {
                quote = getStableQuote(pool, quote);
                continue;
              }
              if (isV3Pool(pool)) {
                const v3QuoteResult = yield getV3Quote(pool, quote);
                if (!v3QuoteResult || JSBI15.equal(v3QuoteResult.quote.quotient, ZERO7)) {
                  quoteSuccess = false;
                  break;
                }
                const { quote: v3Quote, numOfTicksCrossed } = v3QuoteResult;
                quote = v3Quote;
                initializedTickCrossedList[i] = numOfTicksCrossed;
              }
            }
            if (!quoteSuccess) {
              continue;
            }
            const { gasEstimate, gasCostInUSD, gasCostInToken } = gasModel.estimateGasCost(
              __spreadProps(__spreadValues({}, route), {
                quote
              }),
              { initializedTickCrossedList }
            );
            routesWithQuote.push(__spreadProps(__spreadValues({}, route), {
              quote,
              quoteAdjustedForGas: adjustQuoteForGas(quote, gasCostInToken),
              gasEstimate,
              gasCostInUSD,
              gasCostInToken
            }));
          } catch (e) {
          }
        }
        return routesWithQuote;
      });
    };
  };
  return {
    getRouteWithQuotesExactIn: createGetRoutesWithQuotes(true),
    getRouteWithQuotesExactOut: createGetRoutesWithQuotes(false)
  };
}
function createGetV2Quote(isExactIn = true) {
  return function getV2Quote({ reserve0, reserve1 }, amount) {
    const pair = new Pair8(reserve0.wrapped, reserve1.wrapped);
    const [quote] = isExactIn ? pair.getOutputAmount(amount.wrapped) : pair.getInputAmount(amount.wrapped);
    return quote;
  };
}
function createGetStableQuote(isExactIn = true) {
  const getQuote = isExactIn ? getSwapOutput : getSwapInput;
  return function getStableQuote(pool, amount) {
    const { amplifier, balances, fee } = pool;
    return getQuote({
      amount,
      balances,
      amplifier,
      outputCurrency: getOutputCurrency(pool, amount.currency),
      fee
    });
  };
}
function createGetV3Quote(isExactIn = true) {
  return function getV3Quote(pool, amount) {
    return __async(this, null, function* () {
      const { token0, token1, fee, sqrtRatioX96, liquidity, ticks, tick } = pool;
      if (!(ticks == null ? void 0 : ticks.length)) {
        return null;
      }
      try {
        const v3Pool = new V3Pool3(token0.wrapped, token1.wrapped, fee, sqrtRatioX96, liquidity, tick, ticks);
        const getQuotePromise = isExactIn ? v3Pool.getOutputAmount(amount.wrapped) : v3Pool.getInputAmount(amount.wrapped);
        const [quote, poolAfter] = yield getQuotePromise;
        const { tickCurrent: tickAfter } = poolAfter;
        const numOfTicksCrossed = TickList.countInitializedTicksCrossed(ticks, tick, tickAfter);
        return {
          quote,
          numOfTicksCrossed
        };
      } catch (e) {
        return null;
      }
    });
  };
}

// evm/v3-router/providers/onChainQuoteProvider.ts
import { ChainId as ChainId17, CurrencyAmount as CurrencyAmount25, JSBI as JSBI17 } from "@madmeerkatfinance/sdk";
import { Interface } from "@ethersproject/abi";
import retry from "async-retry";
import stats2 from "stats-lite";

// evm/abis/IMixedRouteQuoterV1.json
var IMixedRouteQuoterV1_default = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_deployer",
        type: "address"
      },
      {
        internalType: "address",
        name: "_factory",
        type: "address"
      },
      {
        internalType: "address",
        name: "_factoryV2",
        type: "address"
      },
      {
        internalType: "address",
        name: "_factoryStable",
        type: "address"
      },
      {
        internalType: "address",
        name: "_WETH9",
        type: "address"
      }
    ],
    stateMutability: "nonpayable",
    type: "constructor"
  },
  {
    inputs: [],
    name: "WETH9",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "deployer",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "factory",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "factoryStable",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "factoryV2",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "int256",
        name: "amount0Delta",
        type: "int256"
      },
      {
        internalType: "int256",
        name: "amount1Delta",
        type: "int256"
      },
      {
        internalType: "bytes",
        name: "path",
        type: "bytes"
      }
    ],
    name: "pancakeV3SwapCallback",
    outputs: [],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "path",
        type: "bytes"
      },
      {
        internalType: "uint256[]",
        name: "flag",
        type: "uint256[]"
      },
      {
        internalType: "uint256",
        name: "amountIn",
        type: "uint256"
      }
    ],
    name: "quoteExactInput",
    outputs: [
      {
        internalType: "uint256",
        name: "amountOut",
        type: "uint256"
      },
      {
        internalType: "uint160[]",
        name: "v3SqrtPriceX96AfterList",
        type: "uint160[]"
      },
      {
        internalType: "uint32[]",
        name: "v3InitializedTicksCrossedList",
        type: "uint32[]"
      },
      {
        internalType: "uint256",
        name: "v3SwapGasEstimate",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "tokenIn",
            type: "address"
          },
          {
            internalType: "address",
            name: "tokenOut",
            type: "address"
          },
          {
            internalType: "uint256",
            name: "amountIn",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "flag",
            type: "uint256"
          }
        ],
        internalType: "struct IMixedRouteQuoterV1.QuoteExactInputSingleStableParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "quoteExactInputSingleStable",
    outputs: [
      {
        internalType: "uint256",
        name: "amountOut",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "tokenIn",
            type: "address"
          },
          {
            internalType: "address",
            name: "tokenOut",
            type: "address"
          },
          {
            internalType: "uint256",
            name: "amountIn",
            type: "uint256"
          }
        ],
        internalType: "struct IMixedRouteQuoterV1.QuoteExactInputSingleV2Params",
        name: "params",
        type: "tuple"
      }
    ],
    name: "quoteExactInputSingleV2",
    outputs: [
      {
        internalType: "uint256",
        name: "amountOut",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "tokenIn",
            type: "address"
          },
          {
            internalType: "address",
            name: "tokenOut",
            type: "address"
          },
          {
            internalType: "uint256",
            name: "amountIn",
            type: "uint256"
          },
          {
            internalType: "uint24",
            name: "fee",
            type: "uint24"
          },
          {
            internalType: "uint160",
            name: "sqrtPriceLimitX96",
            type: "uint160"
          }
        ],
        internalType: "struct IMixedRouteQuoterV1.QuoteExactInputSingleV3Params",
        name: "params",
        type: "tuple"
      }
    ],
    name: "quoteExactInputSingleV3",
    outputs: [
      {
        internalType: "uint256",
        name: "amountOut",
        type: "uint256"
      },
      {
        internalType: "uint160",
        name: "sqrtPriceX96After",
        type: "uint160"
      },
      {
        internalType: "uint32",
        name: "initializedTicksCrossed",
        type: "uint32"
      },
      {
        internalType: "uint256",
        name: "gasEstimate",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  }
];

// evm/abis/IQuoterV2.json
var IQuoterV2_default = [
  {
    inputs: [
      {
        internalType: "bytes",
        name: "path",
        type: "bytes"
      },
      {
        internalType: "uint256",
        name: "amountIn",
        type: "uint256"
      }
    ],
    name: "quoteExactInput",
    outputs: [
      {
        internalType: "uint256",
        name: "amountOut",
        type: "uint256"
      },
      {
        internalType: "uint160[]",
        name: "sqrtPriceX96AfterList",
        type: "uint160[]"
      },
      {
        internalType: "uint32[]",
        name: "initializedTicksCrossedList",
        type: "uint32[]"
      },
      {
        internalType: "uint256",
        name: "gasEstimate",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "tokenIn",
            type: "address"
          },
          {
            internalType: "address",
            name: "tokenOut",
            type: "address"
          },
          {
            internalType: "uint256",
            name: "amountIn",
            type: "uint256"
          },
          {
            internalType: "uint24",
            name: "fee",
            type: "uint24"
          },
          {
            internalType: "uint160",
            name: "sqrtPriceLimitX96",
            type: "uint160"
          }
        ],
        internalType: "struct IQuoterV2.QuoteExactInputSingleParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "quoteExactInputSingle",
    outputs: [
      {
        internalType: "uint256",
        name: "amountOut",
        type: "uint256"
      },
      {
        internalType: "uint160",
        name: "sqrtPriceX96After",
        type: "uint160"
      },
      {
        internalType: "uint32",
        name: "initializedTicksCrossed",
        type: "uint32"
      },
      {
        internalType: "uint256",
        name: "gasEstimate",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "path",
        type: "bytes"
      },
      {
        internalType: "uint256",
        name: "amountOut",
        type: "uint256"
      }
    ],
    name: "quoteExactOutput",
    outputs: [
      {
        internalType: "uint256",
        name: "amountIn",
        type: "uint256"
      },
      {
        internalType: "uint160[]",
        name: "sqrtPriceX96AfterList",
        type: "uint160[]"
      },
      {
        internalType: "uint32[]",
        name: "initializedTicksCrossedList",
        type: "uint32[]"
      },
      {
        internalType: "uint256",
        name: "gasEstimate",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "tokenIn",
            type: "address"
          },
          {
            internalType: "address",
            name: "tokenOut",
            type: "address"
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256"
          },
          {
            internalType: "uint24",
            name: "fee",
            type: "uint24"
          },
          {
            internalType: "uint160",
            name: "sqrtPriceLimitX96",
            type: "uint160"
          }
        ],
        internalType: "struct IQuoterV2.QuoteExactOutputSingleParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "quoteExactOutputSingle",
    outputs: [
      {
        internalType: "uint256",
        name: "amountIn",
        type: "uint256"
      },
      {
        internalType: "uint160",
        name: "sqrtPriceX96After",
        type: "uint160"
      },
      {
        internalType: "uint32",
        name: "initializedTicksCrossed",
        type: "uint32"
      },
      {
        internalType: "uint256",
        name: "gasEstimate",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  }
];

// evm/v3-router/providers/multicallSwapProvider.ts
import { JSBI as JSBI16, ChainId as ChainId15 } from "@madmeerkatfinance/sdk";
import { Contract } from "@ethersproject/contracts";
import stats from "stats-lite";

// evm/abis/InterfaceMulticall.json
var InterfaceMulticall_default = [
  {
    inputs: [],
    name: "getCurrentBlockTimestamp",
    outputs: [
      {
        internalType: "uint256",
        name: "timestamp",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "addr",
        type: "address"
      }
    ],
    name: "getEthBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "balance",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "target",
            type: "address"
          },
          {
            internalType: "uint256",
            name: "gasLimit",
            type: "uint256"
          },
          {
            internalType: "bytes",
            name: "callData",
            type: "bytes"
          }
        ],
        internalType: "struct PancakeInterfaceMulticall.Call[]",
        name: "calls",
        type: "tuple[]"
      }
    ],
    name: "multicall",
    outputs: [
      {
        internalType: "uint256",
        name: "blockNumber",
        type: "uint256"
      },
      {
        components: [
          {
            internalType: "bool",
            name: "success",
            type: "bool"
          },
          {
            internalType: "uint256",
            name: "gasUsed",
            type: "uint256"
          },
          {
            internalType: "bytes",
            name: "returnData",
            type: "bytes"
          }
        ],
        internalType: "struct PancakeInterfaceMulticall.Result[]",
        name: "returnData",
        type: "tuple[]"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  }
];

// evm/v3-router/providers/multicallProvider.ts
var IMulticallProvider = class {
};

// evm/v3-router/providers/multicallSwapProvider.ts
var PANCAKE_MULTICALL_ADDRESSES = {
  [ChainId15.ETHEREUM]: "0xac1cE734566f390A94b00eb9bf561c2625BF44ea",
  [ChainId15.GOERLI]: "0x3D00CdB4785F0ef20C903A13596e0b9B2c652227",
  [ChainId15.BSC]: "0xac1cE734566f390A94b00eb9bf561c2625BF44ea",
  [ChainId15.ARBITRUM]: "0xFa34F4E90BB251E845D2FF8Ced49C6454737c85E",
  [ChainId15.CRONOS]: "0x036CF96160c932000dF45858373a89398392D851",
  [ChainId15.BSC_TESTNET]: "0x3D00CdB4785F0ef20C903A13596e0b9B2c652227"
};
var PancakeswapMulticallProvider = class extends IMulticallProvider {
  constructor(chainId, provider, gasLimitPerCall = 1e6) {
    super();
    this.chainId = chainId;
    this.provider = provider;
    this.gasLimitPerCall = gasLimitPerCall;
    const multicallAddress = PANCAKE_MULTICALL_ADDRESSES[this.chainId];
    if (!multicallAddress) {
      throw new Error(`No address for Pancakeswap Multicall Contract on chain id: ${chainId}`);
    }
    this.multicallContract = new Contract(multicallAddress, InterfaceMulticall_default, this.provider);
  }
  callSameFunctionOnMultipleContracts(params) {
    return __async(this, null, function* () {
      var _a;
      const { addresses, contractInterface, functionName, functionParams, providerConfig } = params;
      const blockNumberOverride = (_a = providerConfig == null ? void 0 : providerConfig.blockNumber) != null ? _a : void 0;
      const fragment = contractInterface.getFunction(functionName);
      const callData = contractInterface.encodeFunctionData(fragment, functionParams);
      const calls = addresses.map((address) => {
        return {
          target: address,
          callData,
          gasLimit: this.gasLimitPerCall
        };
      });
      const { blockNumber, returnData: aggregateResults } = yield this.multicallContract.callStatic.multicall(calls, {
        blockTag: blockNumberOverride && JSBI16.toNumber(JSBI16.BigInt(blockNumberOverride))
      });
      const results = [];
      for (let i = 0; i < aggregateResults.length; i++) {
        const { success, returnData } = aggregateResults[i];
        if (!success || returnData.length <= 2) {
          results.push({
            success: false,
            returnData
          });
          continue;
        }
        results.push({
          success: true,
          result: contractInterface.decodeFunctionResult(fragment, returnData)
        });
      }
      return { blockNumber: JSBI16.BigInt(blockNumber), results };
    });
  }
  callSameFunctionOnContractWithMultipleParams(params) {
    return __async(this, null, function* () {
      var _a, _b;
      const { address, contractInterface, functionName, functionParams, additionalConfig, providerConfig } = params;
      const fragment = contractInterface.getFunction(functionName);
      const gasLimitPerCall = (_a = additionalConfig == null ? void 0 : additionalConfig.gasLimitPerCallOverride) != null ? _a : this.gasLimitPerCall;
      const blockNumberOverride = (_b = providerConfig == null ? void 0 : providerConfig.blockNumber) != null ? _b : void 0;
      const calls = functionParams.map((functionParam) => {
        const callData = contractInterface.encodeFunctionData(fragment, functionParam);
        return {
          target: address,
          callData,
          gasLimit: gasLimitPerCall
        };
      });
      const { blockNumber, returnData: aggregateResults } = yield this.multicallContract.callStatic.multicall(calls, {
        blockTag: blockNumberOverride && JSBI16.toNumber(JSBI16.BigInt(blockNumberOverride))
      });
      const results = [];
      const gasUsedForSuccess = [];
      for (let i = 0; i < aggregateResults.length; i++) {
        const { success, returnData, gasUsed } = aggregateResults[i];
        if (!success || returnData.length <= 2) {
          results.push({
            success: false,
            returnData
          });
          continue;
        }
        gasUsedForSuccess.push(gasUsed.toNumber());
        results.push({
          success: true,
          result: contractInterface.decodeFunctionResult(fragment, returnData)
        });
      }
      return {
        blockNumber: JSBI16.BigInt(blockNumber),
        results,
        approxGasUsedPerSuccessCall: stats.percentile(gasUsedForSuccess, 99)
      };
    });
  }
  callMultipleFunctionsOnSameContract(params) {
    return __async(this, null, function* () {
      var _a, _b;
      const { address, contractInterface, functionNames, functionParams, additionalConfig, providerConfig } = params;
      const gasLimitPerCall = (_a = additionalConfig == null ? void 0 : additionalConfig.gasLimitPerCallOverride) != null ? _a : this.gasLimitPerCall;
      const blockNumberOverride = (_b = providerConfig == null ? void 0 : providerConfig.blockNumber) != null ? _b : void 0;
      const calls = functionNames.map((functionName, i) => {
        const fragment = contractInterface.getFunction(functionName);
        const param = functionParams ? functionParams[i] : [];
        const callData = contractInterface.encodeFunctionData(fragment, param);
        return {
          target: address,
          callData,
          gasLimit: gasLimitPerCall
        };
      });
      const { blockNumber, returnData: aggregateResults } = yield this.multicallContract.callStatic.multicall(calls, {
        blockTag: blockNumberOverride && JSBI16.toNumber(JSBI16.BigInt(blockNumberOverride))
      });
      const results = [];
      const gasUsedForSuccess = [];
      for (let i = 0; i < aggregateResults.length; i++) {
        const fragment = contractInterface.getFunction(functionNames[i]);
        const { success, returnData, gasUsed } = aggregateResults[i];
        if (!success || returnData.length <= 2) {
          results.push({
            success: false,
            returnData
          });
          continue;
        }
        gasUsedForSuccess.push(gasUsed.toNumber());
        results.push({
          success: true,
          result: contractInterface.decodeFunctionResult(fragment, returnData)
        });
      }
      return {
        blockNumber: JSBI16.BigInt(blockNumber),
        results,
        approxGasUsedPerSuccessCall: stats.percentile(gasUsedForSuccess, 99)
      };
    });
  }
};

// evm/constants/multicall.ts
import { ChainId as ChainId16 } from "@madmeerkatfinance/sdk";
var BATCH_MULTICALL_CONFIGS = {
  [ChainId16.BSC_TESTNET]: {
    defaultConfig: {
      multicallChunk: 150,
      gasLimitOverride: 1e6
    },
    gasErrorFailureOverride: {
      gasLimitOverride: 1e6,
      multicallChunk: 30
    },
    successRateFailureOverrides: {
      gasLimitOverride: 1e6,
      multicallChunk: 50
    }
  },
  [ChainId16.BSC]: {
    defaultConfig: {
      multicallChunk: 50,
      gasLimitOverride: 1e6
    },
    gasErrorFailureOverride: {
      gasLimitOverride: 1e6,
      multicallChunk: 40
    },
    successRateFailureOverrides: {
      gasLimitOverride: 1e6,
      multicallChunk: 45
    }
  },
  [ChainId16.ETHEREUM]: {
    defaultConfig: {
      multicallChunk: 150,
      gasLimitOverride: 1e6
    },
    gasErrorFailureOverride: {
      gasLimitOverride: 1e6,
      multicallChunk: 30
    },
    successRateFailureOverrides: {
      gasLimitOverride: 1e6,
      multicallChunk: 40
    }
  },
  [ChainId16.ARBITRUM]: {
    defaultConfig: {
      multicallChunk: 50,
      gasLimitOverride: 1e6
    },
    gasErrorFailureOverride: {
      gasLimitOverride: 1e6,
      multicallChunk: 40
    },
    successRateFailureOverrides: {
      gasLimitOverride: 1e6,
      multicallChunk: 45
    }
  },
  [ChainId16.CRONOS]: {
    defaultConfig: {
      multicallChunk: 50,
      gasLimitOverride: 1e6
    },
    gasErrorFailureOverride: {
      gasLimitOverride: 1e6,
      multicallChunk: 40
    },
    successRateFailureOverrides: {
      gasLimitOverride: 1e6,
      multicallChunk: 45
    }
  },
  [ChainId16.GOERLI]: {
    defaultConfig: {
      multicallChunk: 150,
      gasLimitOverride: 1e6
    },
    gasErrorFailureOverride: {
      gasLimitOverride: 1e6,
      multicallChunk: 30
    },
    successRateFailureOverrides: {
      gasLimitOverride: 1e6,
      multicallChunk: 40
    }
  }
};

// evm/utils/uniq.ts
function uniq(list) {
  const uniqSet = /* @__PURE__ */ new Set();
  const result = [];
  for (const item of list) {
    if (uniqSet.has(item)) {
      continue;
    }
    uniqSet.add(item);
    result.push(item);
  }
  return result;
}

// evm/v3-router/providers/onChainQuoteProvider.ts
var DEFAULT_BATCH_RETRIES = 2;
var SUCCESS_RATE_CONFIG = {
  [ChainId17.BSC_TESTNET]: 0.1,
  [ChainId17.BSC]: 0.1,
  [ChainId17.ARBITRUM]: 0.1,
  [ChainId17.CRONOS]: 0.1,
  [ChainId17.ETHEREUM]: 0.1,
  [ChainId17.GOERLI]: 0.1
};
var BlockConflictError = class extends Error {
  constructor() {
    super(...arguments);
    this.name = "BlockConflictError";
  }
};
var SuccessRateError = class extends Error {
  constructor() {
    super(...arguments);
    this.name = "SuccessRateError";
  }
};
var ProviderBlockHeaderError = class extends Error {
  constructor() {
    super(...arguments);
    this.name = "ProviderBlockHeaderError";
  }
};
var ProviderTimeoutError = class extends Error {
  constructor() {
    super(...arguments);
    this.name = "ProviderTimeoutError";
  }
};
var ProviderGasError = class extends Error {
  constructor() {
    super(...arguments);
    this.name = "ProviderGasError";
  }
};
function onChainQuoteProviderFactory({
  getQuoteFunctionName,
  getQuoterAddress,
  contractInterface,
  getCallInputs
}) {
  return function createOnChainQuoteProvider({
    onChainProvider,
    multicallConfigs: multicallConfigsOverride
  }) {
    const createGetRoutesWithQuotes = (isExactIn = true) => {
      const functionName = getQuoteFunctionName(isExactIn);
      const adjustQuoteForGas = (quote, gasCostInToken) => isExactIn ? quote.subtract(gasCostInToken) : quote.add(gasCostInToken);
      return function getRoutesWithQuote(_0, _1) {
        return __async(this, arguments, function* (routes, { blockNumber: blockNumberFromConfig, gasModel }) {
          if (!routes.length) {
            return [];
          }
          const chainId = routes[0].amount.currency.chainId;
          const multicallConfigs = multicallConfigsOverride || BATCH_MULTICALL_CONFIGS[chainId] || BATCH_MULTICALL_CONFIGS[ChainId17.ETHEREUM];
          const chainProvider = onChainProvider({ chainId });
          let { multicallChunk, gasLimitOverride } = multicallConfigs.defaultConfig;
          const { gasErrorFailureOverride, successRateFailureOverrides } = multicallConfigs;
          const retryOptions = {
            retries: DEFAULT_BATCH_RETRIES,
            minTimeout: 25,
            maxTimeout: 250
          };
          const providerConfig = { blockNumber: blockNumberFromConfig };
          const rollback = { enabled: false, rollbackBlockOffset: 0, attemptsBeforeRollback: 2 };
          const multicall2Provider = new PancakeswapMulticallProvider(
            chainId,
            chainProvider,
            gasLimitOverride
          );
          const inputs = routes.map((route) => getCallInputs(route, isExactIn));
          const normalizedChunk = Math.ceil(inputs.length / Math.ceil(inputs.length / multicallChunk));
          const inputsChunked = chunk(inputs, normalizedChunk);
          let quoteStates = inputsChunked.map((inputChunk, index) => {
            return {
              order: index,
              status: "pending",
              inputs: inputChunk
            };
          });
          let haveRetriedForSuccessRate = false;
          let haveRetriedForBlockHeader = false;
          let blockHeaderRetryAttemptNumber = 0;
          let haveIncrementedBlockHeaderFailureCounter = false;
          let blockHeaderRolledBack = false;
          let haveRetriedForBlockConflictError = false;
          let haveRetriedForOutOfGas = false;
          let haveRetriedForTimeout = false;
          let haveRetriedForUnknownReason = false;
          let finalAttemptNumber = 1;
          const expectedCallsMade = quoteStates.length;
          let totalCallsMade = 0;
          const {
            results: quoteResults,
            blockNumber,
            approxGasUsedPerSuccessCall
          } = yield retry(
            (_bail, attemptNumber) => __async(this, null, function* () {
              haveIncrementedBlockHeaderFailureCounter = false;
              finalAttemptNumber = attemptNumber;
              const [success, failed, pending] = partitionQuotes(quoteStates);
              quoteStates = yield Promise.all(
                quoteStates.map((quoteState, idx) => __async(this, null, function* () {
                  if (quoteState.status === "success") {
                    return quoteState;
                  }
                  const { inputs: inputs2, order } = quoteState;
                  try {
                    totalCallsMade += 1;
                    const results = yield multicall2Provider.callSameFunctionOnContractWithMultipleParams({
                      address: getQuoterAddress(chainId),
                      contractInterface,
                      functionName,
                      functionParams: inputs2,
                      providerConfig,
                      additionalConfig: {
                        gasLimitPerCallOverride: gasLimitOverride
                      }
                    });
                    return {
                      order,
                      status: "success",
                      inputs: inputs2,
                      results
                    };
                  } catch (err) {
                    if (err.message.includes("header not found")) {
                      return {
                        order,
                        status: "failed",
                        inputs: inputs2,
                        reason: new ProviderBlockHeaderError(err.message.slice(0, 500))
                      };
                    }
                    if (err.message.includes("timeout")) {
                      return {
                        order,
                        status: "failed",
                        inputs: inputs2,
                        reason: new ProviderTimeoutError(
                          `Req ${idx}/${quoteStates.length}. Request had ${inputs2.length} inputs. ${err.message.slice(
                            0,
                            500
                          )}`
                        )
                      };
                    }
                    if (err.message.includes("out of gas")) {
                      return {
                        order,
                        status: "failed",
                        inputs: inputs2,
                        reason: new ProviderGasError(err.message.slice(0, 500))
                      };
                    }
                    return {
                      order,
                      status: "failed",
                      inputs: inputs2,
                      reason: new Error(`Unknown error from provider: ${err.message.slice(0, 500)}`)
                    };
                  }
                }))
              );
              const [successfulQuoteStates, failedQuoteStates, pendingQuoteStates] = partitionQuotes(quoteStates);
              if (pendingQuoteStates.length > 0) {
                throw new Error("Pending quote after waiting for all promises.");
              }
              let retryAll = false;
              const blockNumberError = validateBlockNumbers(successfulQuoteStates, inputsChunked.length, gasLimitOverride);
              if (blockNumberError) {
                retryAll = true;
              }
              const reasonForFailureStr = failedQuoteStates.map((failedQuoteState) => failedQuoteState.reason.name).join(", ");
              if (failedQuoteStates.length > 0) {
                for (const failedQuoteState of failedQuoteStates) {
                  const { reason: error } = failedQuoteState;
                  if (error instanceof BlockConflictError) {
                    if (!haveRetriedForBlockConflictError) {
                      haveRetriedForBlockConflictError = true;
                    }
                    retryAll = true;
                  } else if (error instanceof ProviderBlockHeaderError) {
                    if (!haveRetriedForBlockHeader) {
                      haveRetriedForBlockHeader = true;
                    }
                    if (!haveIncrementedBlockHeaderFailureCounter) {
                      blockHeaderRetryAttemptNumber += 1;
                      haveIncrementedBlockHeaderFailureCounter = true;
                    }
                    if (rollback.enabled) {
                      const { rollbackBlockOffset, attemptsBeforeRollback } = rollback;
                      if (blockHeaderRetryAttemptNumber >= attemptsBeforeRollback && !blockHeaderRolledBack) {
                        providerConfig.blockNumber = providerConfig.blockNumber ? JSBI17.add(JSBI17.BigInt(yield providerConfig.blockNumber), JSBI17.BigInt(rollbackBlockOffset)) : JSBI17.add(JSBI17.BigInt(yield chainProvider.getBlockNumber()), JSBI17.BigInt(rollbackBlockOffset));
                        retryAll = true;
                        blockHeaderRolledBack = true;
                      }
                    }
                  } else if (error instanceof ProviderTimeoutError) {
                    if (!haveRetriedForTimeout) {
                      haveRetriedForTimeout = true;
                    }
                  } else if (error instanceof ProviderGasError) {
                    if (!haveRetriedForOutOfGas) {
                      haveRetriedForOutOfGas = true;
                    }
                    gasLimitOverride = gasErrorFailureOverride.gasLimitOverride;
                    multicallChunk = gasErrorFailureOverride.multicallChunk;
                    retryAll = true;
                  } else {
                    if (!haveRetriedForUnknownReason) {
                      haveRetriedForUnknownReason = true;
                    }
                  }
                }
              }
              let successRateError;
              if (failedQuoteStates.length === 0) {
                successRateError = validateSuccessRate(
                  quoteStates.reduce(
                    (acc, cur) => {
                      var _a;
                      return cur.status === "success" ? [...acc, ...((_a = cur.results) == null ? void 0 : _a.results) || []] : acc;
                    },
                    []
                  ),
                  haveRetriedForSuccessRate,
                  SUCCESS_RATE_CONFIG[chainId]
                );
                if (successRateError) {
                  if (!haveRetriedForSuccessRate) {
                    haveRetriedForSuccessRate = true;
                    gasLimitOverride = successRateFailureOverrides.gasLimitOverride;
                    multicallChunk = successRateFailureOverrides.multicallChunk;
                    retryAll = true;
                  }
                }
              }
              if (retryAll) {
                const normalizedChunk2 = Math.ceil(inputs.length / Math.ceil(inputs.length / multicallChunk));
                const inputsChunked2 = chunk(inputs, normalizedChunk2);
                quoteStates = inputsChunked2.map((inputChunk, index) => {
                  return {
                    order: index,
                    status: "pending",
                    inputs: inputChunk
                  };
                });
              }
              if (failedQuoteStates.length > 0) {
                throw new Error(`Failed to get ${failedQuoteStates.length} quotes. Reasons: ${reasonForFailureStr}`);
              }
              if (successRateError) {
                throw successRateError;
              }
              const orderedSuccessfulQuoteStates = successfulQuoteStates.sort((a, b) => a.order < b.order ? -1 : 1);
              const callResults = orderedSuccessfulQuoteStates.map((quoteState) => quoteState.results);
              return {
                results: flatMap(callResults, (result) => result.results),
                blockNumber: JSBI17.BigInt(successfulQuoteStates[0].results.blockNumber),
                approxGasUsedPerSuccessCall: stats2.percentile(
                  callResults.map((result) => result.approxGasUsedPerSuccessCall),
                  100
                )
              };
            }),
            __spreadValues({}, retryOptions)
          );
          const routesWithQuote = processQuoteResults(quoteResults, routes, gasModel, adjustQuoteForGas);
          return routesWithQuote;
        });
      };
    };
    return {
      getRouteWithQuotesExactIn: createGetRoutesWithQuotes(true),
      getRouteWithQuotesExactOut: createGetRoutesWithQuotes(false)
    };
  };
}
function partitionQuotes(quoteStates) {
  const successfulQuoteStates = quoteStates.filter(
    (quoteState) => quoteState.status === "success"
  );
  const failedQuoteStates = quoteStates.filter(
    (quoteState) => quoteState.status === "failed"
  );
  const pendingQuoteStates = quoteStates.filter(
    (quoteState) => quoteState.status === "pending"
  );
  return [successfulQuoteStates, failedQuoteStates, pendingQuoteStates];
}
function validateSuccessRate(allResults, haveRetriedForSuccessRate, quoteMinSuccessRate) {
  const numResults = allResults.length;
  const numSuccessResults = allResults.filter((result) => result.success).length;
  const successRate = 1 * numSuccessResults / numResults;
  if (successRate < quoteMinSuccessRate) {
    if (haveRetriedForSuccessRate) {
      return;
    }
    return new SuccessRateError(`Quote success rate below threshold of ${quoteMinSuccessRate}: ${successRate}`);
  }
}
function validateBlockNumbers(successfulQuoteStates, totalCalls, gasLimitOverride) {
  if (successfulQuoteStates.length <= 1) {
    return null;
  }
  const results = successfulQuoteStates.map((quoteState) => quoteState.results);
  const blockNumbers = results.map((result) => result.blockNumber);
  const blockStrs = blockNumbers.map((blockNumber) => blockNumber.toString());
  const uniqBlocks = uniq(blockStrs);
  if (uniqBlocks.length === 1) {
    return null;
  }
  return new BlockConflictError(
    `Quotes returned from different blocks. ${uniqBlocks}. ${totalCalls} calls were made with gas limit ${gasLimitOverride}`
  );
}
function processQuoteResults(quoteResults, routes, gasModel, adjustQuoteForGas) {
  const routesWithQuote = [];
  for (let i = 0; i < quoteResults.length; i += 1) {
    const route = routes[i];
    const quoteResult = quoteResults[i];
    if (!quoteResult) {
      continue;
    }
    const { success } = quoteResult;
    if (!success) {
      continue;
    }
    const quoteCurrency = getQuoteCurrency(route, route.amount.currency);
    const quote = CurrencyAmount25.fromRawAmount(quoteCurrency.wrapped, quoteResult.result[0]);
    const { gasEstimate, gasCostInToken, gasCostInUSD } = gasModel.estimateGasCost(
      __spreadProps(__spreadValues({}, route), {
        quote
      }),
      { initializedTickCrossedList: quoteResult.result[2] }
    );
    routesWithQuote.push(__spreadProps(__spreadValues({}, route), {
      quote,
      quoteAdjustedForGas: adjustQuoteForGas(quote, gasCostInToken),
      // sqrtPriceX96AfterList: quoteResult.result[1],
      gasEstimate,
      gasCostInToken,
      gasCostInUSD
    }));
  }
  return routesWithQuote;
}
var createMixedRouteOnChainQuoteProvider = onChainQuoteProviderFactory({
  getQuoterAddress: (chainId) => MIXED_ROUTE_QUOTER_ADDRESSES[chainId],
  getQuoteFunctionName: () => "quoteExactInput",
  contractInterface: new Interface(IMixedRouteQuoterV1_default),
  getCallInputs: (route, isExactIn) => [
    encodeMixedRouteToPath(route, !isExactIn),
    route.pools.map((pool) => {
      if (isV3Pool(pool)) {
        return 0;
      }
      if (isV2Pool(pool)) {
        return 1;
      }
      if (isStablePool(pool)) {
        if (pool.balances.length === 2) {
          return 2;
        }
        if (pool.balances.length === 3) {
          return 3;
        }
      }
      return -1;
    }).filter((index) => index >= 0),
    `0x${route.amount.quotient.toString(16)}`
  ]
});
var createV3OnChainQuoteProvider = onChainQuoteProviderFactory({
  getQuoterAddress: (chainId) => V3_QUOTER_ADDRESSES[chainId],
  getQuoteFunctionName: (isExactIn) => isExactIn ? "quoteExactInput" : "quoteExactOutput",
  contractInterface: new Interface(IQuoterV2_default),
  getCallInputs: (route, isExactIn) => [
    encodeMixedRouteToPath(route, !isExactIn),
    `0x${route.amount.quotient.toString(16)}`
  ]
});

// evm/v3-router/providers/quoteProviders.ts
function createQuoteProvider({ onChainProvider }) {
  const offChainQuoteProvider = createOffChainQuoteProvider();
  const mixedRouteOnChainQuoteProvider = createMixedRouteOnChainQuoteProvider({ onChainProvider });
  const v3OnChainQuoteProvider = createV3OnChainQuoteProvider({ onChainProvider });
  const createGetRouteWithQuotes = (isExactIn = true) => {
    const getOffChainQuotes = isExactIn ? offChainQuoteProvider.getRouteWithQuotesExactIn : offChainQuoteProvider.getRouteWithQuotesExactOut;
    const getMixedRouteQuotes = isExactIn ? mixedRouteOnChainQuoteProvider.getRouteWithQuotesExactIn : mixedRouteOnChainQuoteProvider.getRouteWithQuotesExactOut;
    const getV3Quotes = isExactIn ? v3OnChainQuoteProvider.getRouteWithQuotesExactIn : v3OnChainQuoteProvider.getRouteWithQuotesExactOut;
    return function getRoutesWithQuotes(_0, _1) {
      return __async(this, arguments, function* (routes, { blockNumber, gasModel }) {
        const v3Routes = [];
        const mixedRoutesHaveV3Pool = [];
        const routesCanQuoteOffChain = [];
        for (const route of routes) {
          if (route.type === 0 /* V2 */ || route.type === 2 /* STABLE */) {
            routesCanQuoteOffChain.push(route);
            continue;
          }
          if (route.type === 1 /* V3 */) {
            v3Routes.push(route);
            continue;
          }
          const { pools: pools3 } = route;
          if (pools3.some((pool) => isV3Pool(pool))) {
            mixedRoutesHaveV3Pool.push(route);
            continue;
          }
          routesCanQuoteOffChain.push(route);
        }
        const [offChainQuotes, mixedRouteQuotes, v3Quotes] = yield Promise.all([
          getOffChainQuotes(routesCanQuoteOffChain, { blockNumber, gasModel }),
          getMixedRouteQuotes(mixedRoutesHaveV3Pool, { blockNumber, gasModel }),
          getV3Quotes(v3Routes, { blockNumber, gasModel })
        ]);
        return [...offChainQuotes, ...mixedRouteQuotes, ...v3Quotes];
      });
    };
  };
  return {
    getRouteWithQuotesExactIn: createGetRouteWithQuotes(true),
    getRouteWithQuotesExactOut: createGetRouteWithQuotes(false)
  };
}

// evm/v3-router/providers/staticPoolProvider.ts
function createStaticPoolProvider(pools3) {
  const defaultAllowedProtocols = [0 /* V2 */, 1 /* V3 */];
  return {
    getCandidatePools: (_0, _1, _2) => __async(this, [_0, _1, _2], function* (_a, _b, { protocols = defaultAllowedProtocols }) {
      return pools3.filter((pool) => protocols.includes(pool.type));
    }),
    getPools: (_0, _1) => __async(this, [_0, _1], function* (pairs, { protocols = defaultAllowedProtocols }) {
      const relatedPools = [];
      for (const [currencyA, currencyB] of pairs) {
        for (const pool of pools3) {
          if (involvesCurrency(pool, currencyA) && involvesCurrency(pool, currencyB) && protocols.includes(pool.type)) {
            relatedPools.push(pool);
          }
        }
      }
      return relatedPools;
    })
  };
}

// evm/v3-router/providers/subgraphProvider.ts
import { JSBI as JSBI18, WNATIVE as WNATIVE3, INIT_CODE_HASH_MAP_V3 as INIT_CODE_HASH_MAP_V32 } from "@madmeerkatfinance/sdk";
import { parseProtocolFees as parseProtocolFees2, Pool as Pool6, FeeAmount as FeeAmount2 } from "@madmeerkatfinance/v3-sdk";
import { gql } from "graphql-request";
var query = gql`
  query getPools($pageSize: Int!, $poolAddrs: [String]) {
    pools(first: $pageSize, where: { id_in: $poolAddrs }) {
      id
      tick
      sqrtPrice
      feeTier
      liquidity
      feeProtocol
      totalValueLockedUSD
    }
  }
`;
var getV3PoolSubgraph = (_0) => __async(void 0, [_0], function* ({
  provider,
  pairs
}) {
  var _a, _b;
  const chainId = (_b = (_a = pairs[0]) == null ? void 0 : _a[0]) == null ? void 0 : _b.chainId;
  if (!chainId) {
    return [];
  }
  const client = provider({ chainId });
  if (!client) {
    console.error("No subgraph client found for chainId", chainId);
    return [];
  }
  metric(`Get V3 pools from subgraph start`, pairs);
  const metaMap = /* @__PURE__ */ new Map();
  for (const pair of pairs) {
    const v3Metas = getV3PoolMetas(pair);
    for (const meta of v3Metas) {
      metaMap.set(meta.address.toLocaleLowerCase(), meta);
    }
  }
  const addresses = Array.from(metaMap.keys());
  const { pools: poolsFromSubgraph } = yield client.request(query, {
    pageSize: 1e3,
    poolAddrs: addresses
  });
  const pools3 = poolsFromSubgraph.map(({ id, liquidity, sqrtPrice, tick, totalValueLockedUSD, feeProtocol }) => {
    const { fee, currencyA, currencyB, address } = metaMap.get(id);
    const [token0, token1] = currencyA.wrapped.sortsBefore(currencyB.wrapped) ? [currencyA, currencyB] : [currencyB, currencyA];
    const [token0ProtocolFee, token1ProtocolFee] = parseProtocolFees2(feeProtocol);
    return {
      type: 1 /* V3 */,
      fee,
      token0,
      token1,
      liquidity: JSBI18.BigInt(liquidity),
      sqrtRatioX96: JSBI18.BigInt(sqrtPrice),
      tick: Number(tick),
      address,
      tvlUSD: JSBI18.BigInt(Number.parseInt(totalValueLockedUSD)),
      token0ProtocolFee,
      token1ProtocolFee
    };
  });
  metric(`Got V3 pools from subgraph end`, pools3);
  return pools3;
});
function getV3PoolMetas([currencyA, currencyB]) {
  return [FeeAmount2.LOWEST, FeeAmount2.LOW, FeeAmount2.MEDIUM, FeeAmount2.HIGH].map((fee) => ({
    address: Pool6.getAddress(currencyA.wrapped, currencyB.wrapped, fee, INIT_CODE_HASH_MAP_V32[currencyA.chainId]),
    currencyA,
    currencyB,
    fee
  }));
}
var POOL_SELECTION_CONFIG = {
  topN: 2,
  topNDirectSwaps: 2,
  topNTokenInOut: 2,
  topNSecondHop: 1,
  topNWithEachBaseToken: 3,
  topNWithBaseToken: 3
};
var sortByTvl = (a, b) => JSBI18.greaterThanOrEqual(a.tvlUSD, b.tvlUSD) ? -1 : 1;
function v3PoolSubgraphSelection(currencyA, currencyB, poolsFromSubgraph) {
  var _a;
  if (!poolsFromSubgraph.length) {
    return [];
  }
  const {
    token0: { chainId }
  } = poolsFromSubgraph[0];
  const baseTokens = (_a = BASES_TO_CHECK_TRADES_AGAINST[chainId]) != null ? _a : [];
  const poolSet = /* @__PURE__ */ new Set();
  const addToPoolSet = (pools4) => {
    for (const pool of pools4) {
      poolSet.add(pool.address);
    }
  };
  const topByBaseWithTokenIn = baseTokens.map((token) => {
    return poolsFromSubgraph.filter((subgraphPool) => {
      return subgraphPool.token0.wrapped.equals(token) && subgraphPool.token1.wrapped.equals(currencyA.wrapped) || subgraphPool.token1.wrapped.equals(token) && subgraphPool.token0.wrapped.equals(currencyA.wrapped);
    }).sort(sortByTvl).slice(0, POOL_SELECTION_CONFIG.topNWithEachBaseToken);
  }).reduce((acc, cur) => [...acc, ...cur], []).sort(sortByTvl).slice(0, POOL_SELECTION_CONFIG.topNWithBaseToken);
  addToPoolSet(topByBaseWithTokenIn);
  const topByBaseWithTokenOut = baseTokens.map((token) => {
    return poolsFromSubgraph.filter((subgraphPool) => {
      if (poolSet.has(subgraphPool.address)) {
        return false;
      }
      return subgraphPool.token0.wrapped.equals(token) && subgraphPool.token1.wrapped.equals(currencyB.wrapped) || subgraphPool.token1.wrapped.equals(token) && subgraphPool.token0.wrapped.equals(currencyB.wrapped);
    }).sort(sortByTvl).slice(0, POOL_SELECTION_CONFIG.topNWithEachBaseToken);
  }).reduce((acc, cur) => [...acc, ...cur], []).sort(sortByTvl).slice(0, POOL_SELECTION_CONFIG.topNWithBaseToken);
  addToPoolSet(topByBaseWithTokenOut);
  const top2DirectPools = poolsFromSubgraph.filter((subgraphPool) => {
    if (poolSet.has(subgraphPool.address)) {
      return false;
    }
    return subgraphPool.token0.wrapped.equals(currencyA.wrapped) && subgraphPool.token1.wrapped.equals(currencyB.wrapped) || subgraphPool.token1.wrapped.equals(currencyA.wrapped) && subgraphPool.token0.wrapped.equals(currencyB.wrapped);
  }).slice(0, POOL_SELECTION_CONFIG.topNDirectSwaps);
  addToPoolSet(top2DirectPools);
  const nativeToken = WNATIVE3[chainId];
  const top2EthBaseTokenPool = nativeToken ? poolsFromSubgraph.filter((subgraphPool) => {
    if (poolSet.has(subgraphPool.address)) {
      return false;
    }
    return subgraphPool.token0.wrapped.equals(nativeToken) && subgraphPool.token1.wrapped.equals(currencyA.wrapped) || subgraphPool.token1.wrapped.equals(nativeToken) && subgraphPool.token0.wrapped.equals(currencyA.wrapped);
  }).slice(0, 1) : [];
  addToPoolSet(top2EthBaseTokenPool);
  const top2EthQuoteTokenPool = nativeToken ? poolsFromSubgraph.filter((subgraphPool) => {
    if (poolSet.has(subgraphPool.address)) {
      return false;
    }
    return subgraphPool.token0.wrapped.equals(nativeToken) && subgraphPool.token1.wrapped.equals(currencyB.wrapped) || subgraphPool.token1.wrapped.equals(nativeToken) && subgraphPool.token0.wrapped.equals(currencyB.wrapped);
  }).slice(0, 1) : [];
  addToPoolSet(top2EthQuoteTokenPool);
  const topByTVL = poolsFromSubgraph.slice(0, POOL_SELECTION_CONFIG.topN).filter((pool) => !poolSet.has(pool.address));
  addToPoolSet(topByTVL);
  const topByTVLUsingTokenBase = poolsFromSubgraph.filter((subgraphPool) => {
    if (poolSet.has(subgraphPool.address)) {
      return false;
    }
    return subgraphPool.token0.wrapped.equals(currencyA.wrapped) || subgraphPool.token1.wrapped.equals(currencyA.wrapped);
  }).slice(0, POOL_SELECTION_CONFIG.topNTokenInOut);
  addToPoolSet(topByTVLUsingTokenBase);
  const topByTVLUsingTokenQuote = poolsFromSubgraph.filter((subgraphPool) => {
    if (poolSet.has(subgraphPool.address)) {
      return false;
    }
    return subgraphPool.token0.wrapped.equals(currencyB.wrapped) || subgraphPool.token1.wrapped.equals(currencyB.wrapped);
  }).slice(0, POOL_SELECTION_CONFIG.topNTokenInOut);
  addToPoolSet(topByTVLUsingTokenQuote);
  const topByTVLUsingTokenInSecondHops = topByTVLUsingTokenBase.map((subgraphPool) => {
    return subgraphPool.token0.wrapped.equals(currencyA.wrapped) ? subgraphPool.token1 : subgraphPool.token0;
  }).map((secondHopToken) => {
    return poolsFromSubgraph.filter((subgraphPool) => {
      if (poolSet.has(subgraphPool.address)) {
        return false;
      }
      return subgraphPool.token0.wrapped.equals(secondHopToken.wrapped) || subgraphPool.token1.wrapped.equals(secondHopToken.wrapped);
    }).slice(0, POOL_SELECTION_CONFIG.topNSecondHop);
  }).reduce((acc, cur) => [...acc, ...cur], []).sort(sortByTvl).slice(0, POOL_SELECTION_CONFIG.topNSecondHop);
  addToPoolSet(topByTVLUsingTokenInSecondHops);
  const topByTVLUsingTokenOutSecondHops = topByTVLUsingTokenQuote.map((subgraphPool) => {
    return subgraphPool.token0.wrapped.equals(currencyB.wrapped) ? subgraphPool.token1 : subgraphPool.token0;
  }).map((secondHopToken) => {
    return poolsFromSubgraph.filter((subgraphPool) => {
      if (poolSet.has(subgraphPool.address)) {
        return false;
      }
      return subgraphPool.token0.wrapped.equals(secondHopToken.wrapped) || subgraphPool.token1.wrapped.equals(secondHopToken.wrapped);
    }).slice(0, POOL_SELECTION_CONFIG.topNSecondHop);
  }).reduce((acc, cur) => [...acc, ...cur], []).sort(sortByTvl).slice(0, POOL_SELECTION_CONFIG.topNSecondHop);
  addToPoolSet(topByTVLUsingTokenOutSecondHops);
  const pools3 = [
    ...topByBaseWithTokenIn,
    ...topByBaseWithTokenOut,
    ...top2DirectPools,
    ...top2EthBaseTokenPool,
    ...top2EthQuoteTokenPool,
    ...topByTVL,
    ...topByTVLUsingTokenBase,
    ...topByTVLUsingTokenQuote,
    ...topByTVLUsingTokenInSecondHops,
    ...topByTVLUsingTokenOutSecondHops
  ];
  return pools3.map((_b) => {
    var _c = _b, { tvlUSD } = _c, rest = __objRest(_c, ["tvlUSD"]);
    return rest;
  });
}

// evm/v3-router/providers/v2SubgraphProvider.ts
import { JSBI as JSBI19, WNATIVE as WNATIVE4, Pair as Pair9 } from "@madmeerkatfinance/sdk";
import { gql as gql2 } from "graphql-request";
var query2 = gql2`
  query getPools($pageSize: Int!, $poolAddrs: [ID!]) {
    pairs(first: $pageSize, where: { id_in: $poolAddrs }) {
      id
      reserve0
      reserve1
      reserveUSD
    }
  }
`;
var getV2PoolSubgraph = (_0) => __async(void 0, [_0], function* ({
  provider,
  pairs
}) {
  var _a, _b;
  const chainId = (_b = (_a = pairs[0]) == null ? void 0 : _a[0]) == null ? void 0 : _b.chainId;
  if (!chainId) {
    return [];
  }
  const client = provider({ chainId });
  if (!client) {
    return [];
  }
  const metaMap = /* @__PURE__ */ new Map();
  const addresses = [];
  for (const pair of pairs) {
    const meta = getV2PoolMeta(pair);
    const address = meta.address.toLocaleLowerCase();
    if (metaMap.get(address)) {
      continue;
    }
    metaMap.set(address, meta);
    addresses.push(address);
  }
  metric(`Get V2 pools from subgraph start`, pairs);
  const { pairs: poolsFromSubgraph } = yield client.request(query2, {
    pageSize: 1e3,
    poolAddrs: addresses
  });
  const pools3 = poolsFromSubgraph.map(({ id, reserveUSD, reserve0, reserve1 }) => {
    const { currencyA, currencyB, address } = metaMap.get(id.toLocaleLowerCase());
    const [token0, token1] = currencyA.wrapped.sortsBefore(currencyB.wrapped) ? [currencyA, currencyB] : [currencyB, currencyA];
    const reserve0Amount = tryParseAmount_default(reserve0, token0);
    const reserve1Amount = tryParseAmount_default(reserve1, token1);
    if (!reserve0 || !reserve1) {
      return null;
    }
    return {
      address,
      type: 0 /* V2 */,
      reserve0: reserve0Amount,
      reserve1: reserve1Amount,
      tvlUSD: JSBI19.BigInt(Number.parseInt(reserveUSD))
    };
  }).filter((p) => !!p);
  metric(`Got V2 pools from subgraph end`, pools3);
  return pools3;
});
function getV2PoolMeta([currencyA, currencyB]) {
  return {
    currencyA,
    currencyB,
    address: Pair9.getAddress(currencyA.wrapped, currencyB.wrapped)
  };
}
var POOL_SELECTION_CONFIG2 = {
  topN: 3,
  topNDirectSwaps: 2,
  topNTokenInOut: 2,
  topNSecondHop: 1,
  topNWithEachBaseToken: 3,
  topNWithBaseToken: 3
};
var sortByTvl2 = (a, b) => JSBI19.greaterThanOrEqual(a.tvlUSD, b.tvlUSD) ? -1 : 1;
function v2PoolSubgraphSelection(currencyA, currencyB, poolsFromSubgraph) {
  var _a;
  if (!poolsFromSubgraph.length) {
    return [];
  }
  const {
    reserve0: {
      currency: { chainId }
    }
  } = poolsFromSubgraph[0];
  const baseTokens = (_a = BASES_TO_CHECK_TRADES_AGAINST[chainId]) != null ? _a : [];
  const poolSet = /* @__PURE__ */ new Set();
  const addToPoolSet = (pools4) => {
    for (const pool of pools4) {
      poolSet.add(pool.address);
    }
  };
  const topByBaseWithTokenIn = baseTokens.map((token) => {
    return poolsFromSubgraph.filter((subgraphPool) => {
      return subgraphPool.reserve0.currency.wrapped.equals(token) && subgraphPool.reserve1.currency.wrapped.equals(currencyA.wrapped) || subgraphPool.reserve1.currency.wrapped.equals(token) && subgraphPool.reserve0.currency.wrapped.equals(currencyA.wrapped);
    }).sort(sortByTvl2).slice(0, POOL_SELECTION_CONFIG2.topNWithEachBaseToken);
  }).reduce((acc, cur) => [...acc, ...cur], []).sort(sortByTvl2).slice(0, POOL_SELECTION_CONFIG2.topNWithBaseToken);
  addToPoolSet(topByBaseWithTokenIn);
  const topByBaseWithTokenOut = baseTokens.map((token) => {
    return poolsFromSubgraph.filter((subgraphPool) => {
      if (poolSet.has(subgraphPool.address)) {
        return false;
      }
      return subgraphPool.reserve0.currency.wrapped.equals(token) && subgraphPool.reserve1.currency.wrapped.equals(currencyB.wrapped) || subgraphPool.reserve1.currency.wrapped.equals(token) && subgraphPool.reserve0.currency.wrapped.equals(currencyB.wrapped);
    }).sort(sortByTvl2).slice(0, POOL_SELECTION_CONFIG2.topNWithEachBaseToken);
  }).reduce((acc, cur) => [...acc, ...cur], []).sort(sortByTvl2).slice(0, POOL_SELECTION_CONFIG2.topNWithBaseToken);
  addToPoolSet(topByBaseWithTokenOut);
  const top2DirectPools = poolsFromSubgraph.filter((subgraphPool) => {
    if (poolSet.has(subgraphPool.address)) {
      return false;
    }
    return subgraphPool.reserve0.currency.wrapped.equals(currencyA.wrapped) && subgraphPool.reserve1.currency.wrapped.equals(currencyB.wrapped) || subgraphPool.reserve1.currency.wrapped.equals(currencyA.wrapped) && subgraphPool.reserve0.currency.wrapped.equals(currencyB.wrapped);
  }).slice(0, POOL_SELECTION_CONFIG2.topNDirectSwaps);
  addToPoolSet(top2DirectPools);
  const nativeToken = WNATIVE4[chainId];
  const top2EthBaseTokenPool = nativeToken ? poolsFromSubgraph.filter((subgraphPool) => {
    if (poolSet.has(subgraphPool.address)) {
      return false;
    }
    return subgraphPool.reserve0.currency.wrapped.equals(nativeToken) && subgraphPool.reserve1.currency.wrapped.equals(currencyA.wrapped) || subgraphPool.reserve1.currency.wrapped.equals(nativeToken) && subgraphPool.reserve0.currency.wrapped.equals(currencyA.wrapped);
  }).slice(0, 1) : [];
  addToPoolSet(top2EthBaseTokenPool);
  const top2EthQuoteTokenPool = nativeToken ? poolsFromSubgraph.filter((subgraphPool) => {
    if (poolSet.has(subgraphPool.address)) {
      return false;
    }
    return subgraphPool.reserve0.currency.wrapped.equals(nativeToken) && subgraphPool.reserve1.currency.wrapped.equals(currencyB.wrapped) || subgraphPool.reserve1.currency.wrapped.equals(nativeToken) && subgraphPool.reserve0.currency.wrapped.equals(currencyB.wrapped);
  }).slice(0, 1) : [];
  addToPoolSet(top2EthQuoteTokenPool);
  const topByTVL = poolsFromSubgraph.slice(0, POOL_SELECTION_CONFIG2.topN).filter((pool) => !poolSet.has(pool.address));
  addToPoolSet(topByTVL);
  const topByTVLUsingTokenBase = poolsFromSubgraph.filter((subgraphPool) => {
    if (poolSet.has(subgraphPool.address)) {
      return false;
    }
    return subgraphPool.reserve0.currency.wrapped.equals(currencyA.wrapped) || subgraphPool.reserve1.currency.wrapped.equals(currencyA.wrapped);
  }).slice(0, POOL_SELECTION_CONFIG2.topNTokenInOut);
  addToPoolSet(topByTVLUsingTokenBase);
  const topByTVLUsingTokenQuote = poolsFromSubgraph.filter((subgraphPool) => {
    if (poolSet.has(subgraphPool.address)) {
      return false;
    }
    return subgraphPool.reserve0.currency.wrapped.equals(currencyB.wrapped) || subgraphPool.reserve1.currency.wrapped.equals(currencyB.wrapped);
  }).slice(0, POOL_SELECTION_CONFIG2.topNTokenInOut);
  addToPoolSet(topByTVLUsingTokenQuote);
  const topByTVLUsingTokenInSecondHops = topByTVLUsingTokenBase.map((subgraphPool) => {
    return subgraphPool.reserve0.currency.wrapped.equals(currencyA.wrapped) ? subgraphPool.reserve1.currency : subgraphPool.reserve0.currency;
  }).map((secondHopToken) => {
    return poolsFromSubgraph.filter((subgraphPool) => {
      if (poolSet.has(subgraphPool.address)) {
        return false;
      }
      return subgraphPool.reserve0.currency.wrapped.equals(secondHopToken.wrapped) || subgraphPool.reserve1.currency.wrapped.equals(secondHopToken.wrapped);
    }).slice(0, POOL_SELECTION_CONFIG2.topNSecondHop);
  }).reduce((acc, cur) => [...acc, ...cur], []).sort(sortByTvl2).slice(0, POOL_SELECTION_CONFIG2.topNSecondHop);
  addToPoolSet(topByTVLUsingTokenInSecondHops);
  const topByTVLUsingTokenOutSecondHops = topByTVLUsingTokenQuote.map((subgraphPool) => {
    return subgraphPool.reserve0.currency.wrapped.equals(currencyB.wrapped) ? subgraphPool.reserve1.currency : subgraphPool.reserve0.currency;
  }).map((secondHopToken) => {
    return poolsFromSubgraph.filter((subgraphPool) => {
      if (poolSet.has(subgraphPool.address)) {
        return false;
      }
      return subgraphPool.reserve0.currency.wrapped.equals(secondHopToken.wrapped) || subgraphPool.reserve1.currency.wrapped.equals(secondHopToken.wrapped);
    }).slice(0, POOL_SELECTION_CONFIG2.topNSecondHop);
  }).reduce((acc, cur) => [...acc, ...cur], []).sort(sortByTvl2).slice(0, POOL_SELECTION_CONFIG2.topNSecondHop);
  addToPoolSet(topByTVLUsingTokenOutSecondHops);
  const pools3 = [
    ...topByBaseWithTokenIn,
    ...topByBaseWithTokenOut,
    ...top2DirectPools,
    ...top2EthBaseTokenPool,
    ...top2EthQuoteTokenPool,
    ...topByTVL,
    ...topByTVLUsingTokenBase,
    ...topByTVLUsingTokenQuote,
    ...topByTVLUsingTokenInSecondHops,
    ...topByTVLUsingTokenOutSecondHops
  ];
  return pools3.map((_b) => {
    var _c = _b, { tvlUSD } = _c, rest = __objRest(_c, ["tvlUSD"]);
    return rest;
  });
}

// evm/v3-router/utils/swapRouter.ts
import { Interface as Interface5 } from "@ethersproject/abi";
import { CurrencyAmount as CurrencyAmount27, Percent as Percent12, TradeType as TradeType13, validateAndParseAddress as validateAndParseAddress2, WNATIVE as WNATIVE5 } from "@madmeerkatfinance/sdk";
import { Payments as Payments2, Position as Position2, SelfPermit, toHex as toHex4 } from "@madmeerkatfinance/v3-sdk";
import invariant7 from "tiny-invariant";
import JSBI22 from "jsbi";

// evm/abis/ISwapRouter02.json
var ISwapRouter02_default = [
  {
    inputs: [
      { internalType: "address", name: "_factoryV2", type: "address" },
      { internalType: "address", name: "_deployer", type: "address" },
      { internalType: "address", name: "_factoryV3", type: "address" },
      { internalType: "address", name: "_positionManager", type: "address" },
      { internalType: "address", name: "_stableFactory", type: "address" },
      { internalType: "address", name: "_stableInfo", type: "address" },
      { internalType: "address", name: "_WETH9", type: "address" }
    ],
    stateMutability: "nonpayable",
    type: "constructor"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "previousOwner", type: "address" },
      { indexed: true, internalType: "address", name: "newOwner", type: "address" }
    ],
    name: "OwnershipTransferred",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "factory", type: "address" },
      { indexed: true, internalType: "address", name: "info", type: "address" }
    ],
    name: "SetStableSwap",
    type: "event"
  },
  {
    inputs: [],
    name: "WETH9",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "token", type: "address" }],
    name: "approveMax",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "token", type: "address" }],
    name: "approveMaxMinusOne",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "token", type: "address" }],
    name: "approveZeroThenMax",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "token", type: "address" }],
    name: "approveZeroThenMaxMinusOne",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [{ internalType: "bytes", name: "data", type: "bytes" }],
    name: "callPositionManager",
    outputs: [{ internalType: "bytes", name: "result", type: "bytes" }],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "bytes[]", name: "paths", type: "bytes[]" },
      { internalType: "uint128[]", name: "amounts", type: "uint128[]" },
      { internalType: "uint24", name: "maximumTickDivergence", type: "uint24" },
      { internalType: "uint32", name: "secondsAgo", type: "uint32" }
    ],
    name: "checkOracleSlippage",
    outputs: [],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "bytes", name: "path", type: "bytes" },
      { internalType: "uint24", name: "maximumTickDivergence", type: "uint24" },
      { internalType: "uint32", name: "secondsAgo", type: "uint32" }
    ],
    name: "checkOracleSlippage",
    outputs: [],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "deployer",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          { internalType: "bytes", name: "path", type: "bytes" },
          { internalType: "address", name: "recipient", type: "address" },
          { internalType: "uint256", name: "amountIn", type: "uint256" },
          { internalType: "uint256", name: "amountOutMinimum", type: "uint256" }
        ],
        internalType: "struct IV3SwapRouter.ExactInputParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "exactInput",
    outputs: [{ internalType: "uint256", name: "amountOut", type: "uint256" }],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          { internalType: "address", name: "tokenIn", type: "address" },
          { internalType: "address", name: "tokenOut", type: "address" },
          { internalType: "uint24", name: "fee", type: "uint24" },
          { internalType: "address", name: "recipient", type: "address" },
          { internalType: "uint256", name: "amountIn", type: "uint256" },
          { internalType: "uint256", name: "amountOutMinimum", type: "uint256" },
          { internalType: "uint160", name: "sqrtPriceLimitX96", type: "uint160" }
        ],
        internalType: "struct IV3SwapRouter.ExactInputSingleParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "exactInputSingle",
    outputs: [{ internalType: "uint256", name: "amountOut", type: "uint256" }],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address[]", name: "path", type: "address[]" },
      { internalType: "uint256[]", name: "flag", type: "uint256[]" },
      { internalType: "uint256", name: "amountIn", type: "uint256" },
      { internalType: "uint256", name: "amountOutMin", type: "uint256" },
      { internalType: "address", name: "to", type: "address" }
    ],
    name: "exactInputStableSwap",
    outputs: [{ internalType: "uint256", name: "amountOut", type: "uint256" }],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          { internalType: "bytes", name: "path", type: "bytes" },
          { internalType: "address", name: "recipient", type: "address" },
          { internalType: "uint256", name: "amountOut", type: "uint256" },
          { internalType: "uint256", name: "amountInMaximum", type: "uint256" }
        ],
        internalType: "struct IV3SwapRouter.ExactOutputParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "exactOutput",
    outputs: [{ internalType: "uint256", name: "amountIn", type: "uint256" }],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          { internalType: "address", name: "tokenIn", type: "address" },
          { internalType: "address", name: "tokenOut", type: "address" },
          { internalType: "uint24", name: "fee", type: "uint24" },
          { internalType: "address", name: "recipient", type: "address" },
          { internalType: "uint256", name: "amountOut", type: "uint256" },
          { internalType: "uint256", name: "amountInMaximum", type: "uint256" },
          { internalType: "uint160", name: "sqrtPriceLimitX96", type: "uint160" }
        ],
        internalType: "struct IV3SwapRouter.ExactOutputSingleParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "exactOutputSingle",
    outputs: [{ internalType: "uint256", name: "amountIn", type: "uint256" }],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address[]", name: "path", type: "address[]" },
      { internalType: "uint256[]", name: "flag", type: "uint256[]" },
      { internalType: "uint256", name: "amountOut", type: "uint256" },
      { internalType: "uint256", name: "amountInMax", type: "uint256" },
      { internalType: "address", name: "to", type: "address" }
    ],
    name: "exactOutputStableSwap",
    outputs: [{ internalType: "uint256", name: "amountIn", type: "uint256" }],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [],
    name: "factory",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "factoryV2",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "token", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" }
    ],
    name: "getApprovalType",
    outputs: [{ internalType: "enum IApproveAndCall.ApprovalType", name: "", type: "uint8" }],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          { internalType: "address", name: "token0", type: "address" },
          { internalType: "address", name: "token1", type: "address" },
          { internalType: "uint256", name: "tokenId", type: "uint256" },
          { internalType: "uint256", name: "amount0Min", type: "uint256" },
          { internalType: "uint256", name: "amount1Min", type: "uint256" }
        ],
        internalType: "struct IApproveAndCall.IncreaseLiquidityParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "increaseLiquidity",
    outputs: [{ internalType: "bytes", name: "result", type: "bytes" }],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          { internalType: "address", name: "token0", type: "address" },
          { internalType: "address", name: "token1", type: "address" },
          { internalType: "uint24", name: "fee", type: "uint24" },
          { internalType: "int24", name: "tickLower", type: "int24" },
          { internalType: "int24", name: "tickUpper", type: "int24" },
          { internalType: "uint256", name: "amount0Min", type: "uint256" },
          { internalType: "uint256", name: "amount1Min", type: "uint256" },
          { internalType: "address", name: "recipient", type: "address" }
        ],
        internalType: "struct IApproveAndCall.MintParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "mint",
    outputs: [{ internalType: "bytes", name: "result", type: "bytes" }],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "bytes32", name: "previousBlockhash", type: "bytes32" },
      { internalType: "bytes[]", name: "data", type: "bytes[]" }
    ],
    name: "multicall",
    outputs: [{ internalType: "bytes[]", name: "", type: "bytes[]" }],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "deadline", type: "uint256" },
      { internalType: "bytes[]", name: "data", type: "bytes[]" }
    ],
    name: "multicall",
    outputs: [{ internalType: "bytes[]", name: "", type: "bytes[]" }],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [{ internalType: "bytes[]", name: "data", type: "bytes[]" }],
    name: "multicall",
    outputs: [{ internalType: "bytes[]", name: "results", type: "bytes[]" }],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "int256", name: "amount0Delta", type: "int256" },
      { internalType: "int256", name: "amount1Delta", type: "int256" },
      { internalType: "bytes", name: "_data", type: "bytes" }
    ],
    name: "pancakeV3SwapCallback",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "positionManager",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "token", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" }
    ],
    name: "pull",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  { inputs: [], name: "refundETH", outputs: [], stateMutability: "payable", type: "function" },
  { inputs: [], name: "renounceOwnership", outputs: [], stateMutability: "nonpayable", type: "function" },
  {
    inputs: [
      { internalType: "address", name: "token", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" },
      { internalType: "uint256", name: "deadline", type: "uint256" },
      { internalType: "uint8", name: "v", type: "uint8" },
      { internalType: "bytes32", name: "r", type: "bytes32" },
      { internalType: "bytes32", name: "s", type: "bytes32" }
    ],
    name: "selfPermit",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "token", type: "address" },
      { internalType: "uint256", name: "nonce", type: "uint256" },
      { internalType: "uint256", name: "expiry", type: "uint256" },
      { internalType: "uint8", name: "v", type: "uint8" },
      { internalType: "bytes32", name: "r", type: "bytes32" },
      { internalType: "bytes32", name: "s", type: "bytes32" }
    ],
    name: "selfPermitAllowed",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "token", type: "address" },
      { internalType: "uint256", name: "nonce", type: "uint256" },
      { internalType: "uint256", name: "expiry", type: "uint256" },
      { internalType: "uint8", name: "v", type: "uint8" },
      { internalType: "bytes32", name: "r", type: "bytes32" },
      { internalType: "bytes32", name: "s", type: "bytes32" }
    ],
    name: "selfPermitAllowedIfNecessary",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "token", type: "address" },
      { internalType: "uint256", name: "value", type: "uint256" },
      { internalType: "uint256", name: "deadline", type: "uint256" },
      { internalType: "uint8", name: "v", type: "uint8" },
      { internalType: "bytes32", name: "r", type: "bytes32" },
      { internalType: "bytes32", name: "s", type: "bytes32" }
    ],
    name: "selfPermitIfNecessary",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "_factory", type: "address" },
      { internalType: "address", name: "_info", type: "address" }
    ],
    name: "setStableSwap",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "stableSwapFactory",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "stableSwapInfo",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "amountIn", type: "uint256" },
      { internalType: "uint256", name: "amountOutMin", type: "uint256" },
      { internalType: "address[]", name: "path", type: "address[]" },
      { internalType: "address", name: "to", type: "address" }
    ],
    name: "swapExactTokensForTokens",
    outputs: [{ internalType: "uint256", name: "amountOut", type: "uint256" }],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "amountOut", type: "uint256" },
      { internalType: "uint256", name: "amountInMax", type: "uint256" },
      { internalType: "address[]", name: "path", type: "address[]" },
      { internalType: "address", name: "to", type: "address" }
    ],
    name: "swapTokensForExactTokens",
    outputs: [{ internalType: "uint256", name: "amountIn", type: "uint256" }],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "token", type: "address" },
      { internalType: "uint256", name: "amountMinimum", type: "uint256" },
      { internalType: "address", name: "recipient", type: "address" }
    ],
    name: "sweepToken",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "token", type: "address" },
      { internalType: "uint256", name: "amountMinimum", type: "uint256" }
    ],
    name: "sweepToken",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "token", type: "address" },
      { internalType: "uint256", name: "amountMinimum", type: "uint256" },
      { internalType: "uint256", name: "feeBips", type: "uint256" },
      { internalType: "address", name: "feeRecipient", type: "address" }
    ],
    name: "sweepTokenWithFee",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "token", type: "address" },
      { internalType: "uint256", name: "amountMinimum", type: "uint256" },
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "feeBips", type: "uint256" },
      { internalType: "address", name: "feeRecipient", type: "address" }
    ],
    name: "sweepTokenWithFee",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "amountMinimum", type: "uint256" },
      { internalType: "address", name: "recipient", type: "address" }
    ],
    name: "unwrapWETH9",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "amountMinimum", type: "uint256" },
      { internalType: "address", name: "recipient", type: "address" },
      { internalType: "uint256", name: "feeBips", type: "uint256" },
      { internalType: "address", name: "feeRecipient", type: "address" }
    ],
    name: "unwrapWETH9WithFee",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      { internalType: "uint256", name: "amountMinimum", type: "uint256" },
      { internalType: "uint256", name: "feeBips", type: "uint256" },
      { internalType: "address", name: "feeRecipient", type: "address" }
    ],
    name: "unwrapWETH9WithFee",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "value", type: "uint256" }],
    name: "wrapETH",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  { stateMutability: "payable", type: "receive" }
];

// evm/v3-router/utils/approveAndCall.ts
import { Interface as Interface2 } from "@ethersproject/abi";
import invariant6 from "tiny-invariant";
import { JSBI as JSBI20 } from "@madmeerkatfinance/sdk";
import {
  NonfungiblePositionManager,
  toHex
} from "@madmeerkatfinance/v3-sdk";

// evm/abis/IApproveAndCall.json
var IApproveAndCall_default = [
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      }
    ],
    name: "approveMax",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      }
    ],
    name: "approveMaxMinusOne",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      }
    ],
    name: "approveZeroThenMax",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      }
    ],
    name: "approveZeroThenMaxMinusOne",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "data",
        type: "bytes"
      }
    ],
    name: "callPositionManager",
    outputs: [
      {
        internalType: "bytes",
        name: "result",
        type: "bytes"
      }
    ],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256"
      }
    ],
    name: "getApprovalType",
    outputs: [
      {
        internalType: "enum IApproveAndCall.ApprovalType",
        name: "",
        type: "uint8"
      }
    ],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "token0",
            type: "address"
          },
          {
            internalType: "address",
            name: "token1",
            type: "address"
          },
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "amount0Min",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "amount1Min",
            type: "uint256"
          }
        ],
        internalType: "struct IApproveAndCall.IncreaseLiquidityParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "increaseLiquidity",
    outputs: [
      {
        internalType: "bytes",
        name: "result",
        type: "bytes"
      }
    ],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "token0",
            type: "address"
          },
          {
            internalType: "address",
            name: "token1",
            type: "address"
          },
          {
            internalType: "uint24",
            name: "fee",
            type: "uint24"
          },
          {
            internalType: "int24",
            name: "tickLower",
            type: "int24"
          },
          {
            internalType: "int24",
            name: "tickUpper",
            type: "int24"
          },
          {
            internalType: "uint256",
            name: "amount0Min",
            type: "uint256"
          },
          {
            internalType: "uint256",
            name: "amount1Min",
            type: "uint256"
          },
          {
            internalType: "address",
            name: "recipient",
            type: "address"
          }
        ],
        internalType: "struct IApproveAndCall.MintParams",
        name: "params",
        type: "tuple"
      }
    ],
    name: "mint",
    outputs: [
      {
        internalType: "bytes",
        name: "result",
        type: "bytes"
      }
    ],
    stateMutability: "payable",
    type: "function"
  }
];

// evm/v3-router/utils/approveAndCall.ts
function isMint(options) {
  return Object.keys(options).some((k) => k === "recipient");
}
var _ApproveAndCall = class {
  /**
   * Cannot be constructed.
   */
  // eslint-disable-next-line no-useless-constructor, @typescript-eslint/no-empty-function
  constructor() {
  }
  static encodeApproveMax(token) {
    return _ApproveAndCall.INTERFACE.encodeFunctionData("approveMax", [token.address]);
  }
  static encodeApproveMaxMinusOne(token) {
    return _ApproveAndCall.INTERFACE.encodeFunctionData("approveMaxMinusOne", [token.address]);
  }
  static encodeApproveZeroThenMax(token) {
    return _ApproveAndCall.INTERFACE.encodeFunctionData("approveZeroThenMax", [token.address]);
  }
  static encodeApproveZeroThenMaxMinusOne(token) {
    return _ApproveAndCall.INTERFACE.encodeFunctionData("approveZeroThenMaxMinusOne", [token.address]);
  }
  static encodeCallPositionManager(calldatas) {
    invariant6(calldatas.length > 0, "NULL_CALLDATA");
    if (calldatas.length === 1) {
      return _ApproveAndCall.INTERFACE.encodeFunctionData("callPositionManager", calldatas);
    }
    const encodedMulticall = NonfungiblePositionManager.INTERFACE.encodeFunctionData("multicall", [calldatas]);
    return _ApproveAndCall.INTERFACE.encodeFunctionData("callPositionManager", [encodedMulticall]);
  }
  /**
   * Encode adding liquidity to a position in the nft manager contract
   * @param position Forcasted position with expected amount out from swap
   * @param minimalPosition Forcasted position with custom minimal token amounts
   * @param addLiquidityOptions Options for adding liquidity
   * @param slippageTolerance Defines maximum slippage
   */
  static encodeAddLiquidity(position, minimalPosition, addLiquidityOptions, slippageTolerance) {
    let { amount0: amount0Min, amount1: amount1Min } = position.mintAmountsWithSlippage(slippageTolerance);
    if (JSBI20.lessThan(minimalPosition.amount0.quotient, amount0Min)) {
      amount0Min = minimalPosition.amount0.quotient;
    }
    if (JSBI20.lessThan(minimalPosition.amount1.quotient, amount1Min)) {
      amount1Min = minimalPosition.amount1.quotient;
    }
    if (isMint(addLiquidityOptions)) {
      return _ApproveAndCall.INTERFACE.encodeFunctionData("mint", [
        {
          token0: position.pool.token0.address,
          token1: position.pool.token1.address,
          fee: position.pool.fee,
          tickLower: position.tickLower,
          tickUpper: position.tickUpper,
          amount0Min: toHex(amount0Min),
          amount1Min: toHex(amount1Min),
          recipient: addLiquidityOptions.recipient
        }
      ]);
    }
    return _ApproveAndCall.INTERFACE.encodeFunctionData("increaseLiquidity", [
      {
        token0: position.pool.token0.address,
        token1: position.pool.token1.address,
        amount0Min: toHex(amount0Min),
        amount1Min: toHex(amount1Min),
        tokenId: toHex(addLiquidityOptions.tokenId)
      }
    ]);
  }
  static encodeApprove(token, approvalType) {
    switch (approvalType) {
      case 1 /* MAX */:
        return _ApproveAndCall.encodeApproveMax(token.wrapped);
      case 2 /* MAX_MINUS_ONE */:
        return _ApproveAndCall.encodeApproveMaxMinusOne(token.wrapped);
      case 3 /* ZERO_THEN_MAX */:
        return _ApproveAndCall.encodeApproveZeroThenMax(token.wrapped);
      case 4 /* ZERO_THEN_MAX_MINUS_ONE */:
        return _ApproveAndCall.encodeApproveZeroThenMaxMinusOne(token.wrapped);
      default:
        throw new Error("Error: invalid ApprovalType");
    }
  }
};
var ApproveAndCall = _ApproveAndCall;
ApproveAndCall.INTERFACE = new Interface2(IApproveAndCall_default);

// evm/v3-router/utils/multicallExtended.ts
import { Interface as Interface3 } from "@ethersproject/abi";
import { Multicall, toHex as toHex2 } from "@madmeerkatfinance/v3-sdk";

// evm/abis/IMulticallExtended.json
var IMulticallExtended_default = [
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "previousBlockhash",
        type: "bytes32"
      },
      {
        internalType: "bytes[]",
        name: "data",
        type: "bytes[]"
      }
    ],
    name: "multicall",
    outputs: [
      {
        internalType: "bytes[]",
        name: "results",
        type: "bytes[]"
      }
    ],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "deadline",
        type: "uint256"
      },
      {
        internalType: "bytes[]",
        name: "data",
        type: "bytes[]"
      }
    ],
    name: "multicall",
    outputs: [
      {
        internalType: "bytes[]",
        name: "results",
        type: "bytes[]"
      }
    ],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes[]",
        name: "data",
        type: "bytes[]"
      }
    ],
    name: "multicall",
    outputs: [
      {
        internalType: "bytes[]",
        name: "results",
        type: "bytes[]"
      }
    ],
    stateMutability: "payable",
    type: "function"
  }
];

// evm/v3-router/utils/multicallExtended.ts
function validateAndParseBytes32(bytes32) {
  if (!bytes32.match(/^0x[0-9a-fA-F]{64}$/)) {
    throw new Error(`${bytes32} is not valid bytes32.`);
  }
  return bytes32.toLowerCase();
}
var _MulticallExtended = class {
  /**
   * Cannot be constructed.
   */
  // eslint-disable-next-line no-useless-constructor, @typescript-eslint/no-empty-function
  constructor() {
  }
  static encodeMulticall(calldatas, validation) {
    if (typeof validation === "undefined") {
      return Multicall.encodeMulticall(calldatas);
    }
    if (!Array.isArray(calldatas)) {
      calldatas = [calldatas];
    }
    if (typeof validation === "string" && validation.startsWith("0x")) {
      const previousBlockhash = validateAndParseBytes32(validation);
      return _MulticallExtended.INTERFACE.encodeFunctionData("multicall(bytes32,bytes[])", [
        previousBlockhash,
        calldatas
      ]);
    }
    const deadline = toHex2(validation);
    return _MulticallExtended.INTERFACE.encodeFunctionData("multicall(uint256,bytes[])", [deadline, calldatas]);
  }
};
var MulticallExtended = _MulticallExtended;
MulticallExtended.INTERFACE = new Interface3(IMulticallExtended_default);

// evm/v3-router/utils/paymentsExtended.ts
import { Interface as Interface4 } from "@ethersproject/abi";
import { validateAndParseAddress } from "@madmeerkatfinance/sdk";
import { Payments, toHex as toHex3 } from "@madmeerkatfinance/v3-sdk";

// evm/abis/IPeripheryPaymentsWithFeeExtended.json
var IPeripheryPaymentsWithFeeExtended_default = [
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256"
      }
    ],
    name: "pull",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [],
    name: "refundETH",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "amountMinimum",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address"
      }
    ],
    name: "sweepToken",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "amountMinimum",
        type: "uint256"
      }
    ],
    name: "sweepToken",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "amountMinimum",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "feeBips",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "feeRecipient",
        type: "address"
      }
    ],
    name: "sweepTokenWithFee",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "amountMinimum",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "feeBips",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "feeRecipient",
        type: "address"
      }
    ],
    name: "sweepTokenWithFee",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amountMinimum",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address"
      }
    ],
    name: "unwrapWETH9",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amountMinimum",
        type: "uint256"
      }
    ],
    name: "unwrapWETH9",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amountMinimum",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "feeBips",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "feeRecipient",
        type: "address"
      }
    ],
    name: "unwrapWETH9WithFee",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amountMinimum",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "feeBips",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "feeRecipient",
        type: "address"
      }
    ],
    name: "unwrapWETH9WithFee",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "value",
        type: "uint256"
      }
    ],
    name: "wrapETH",
    outputs: [],
    stateMutability: "payable",
    type: "function"
  }
];

// evm/v3-router/utils/paymentsExtended.ts
function encodeFeeBips(fee) {
  return toHex3(fee.multiply(1e4).quotient);
}
var _PaymentsExtended = class {
  /**
   * Cannot be constructed.
   */
  // eslint-disable-next-line no-useless-constructor, @typescript-eslint/no-empty-function
  constructor() {
  }
  static encodeUnwrapWETH9(amountMinimum, recipient, feeOptions) {
    if (typeof recipient === "string") {
      return Payments.encodeUnwrapWETH9(amountMinimum, recipient, feeOptions);
    }
    if (!!feeOptions) {
      const feeBips = encodeFeeBips(feeOptions.fee);
      const feeRecipient = validateAndParseAddress(feeOptions.recipient);
      return _PaymentsExtended.INTERFACE.encodeFunctionData("unwrapWETH9WithFee(uint256,uint256,address)", [
        toHex3(amountMinimum),
        feeBips,
        feeRecipient
      ]);
    }
    return _PaymentsExtended.INTERFACE.encodeFunctionData("unwrapWETH9(uint256)", [toHex3(amountMinimum)]);
  }
  static encodeSweepToken(token, amountMinimum, recipient, feeOptions) {
    if (typeof recipient === "string") {
      return Payments.encodeSweepToken(token, amountMinimum, recipient, feeOptions);
    }
    if (!!feeOptions) {
      const feeBips = encodeFeeBips(feeOptions.fee);
      const feeRecipient = validateAndParseAddress(feeOptions.recipient);
      return _PaymentsExtended.INTERFACE.encodeFunctionData("sweepTokenWithFee(address,uint256,uint256,address)", [
        token.address,
        toHex3(amountMinimum),
        feeBips,
        feeRecipient
      ]);
    }
    return _PaymentsExtended.INTERFACE.encodeFunctionData("sweepToken(address,uint256)", [
      token.address,
      toHex3(amountMinimum)
    ]);
  }
  static encodePull(token, amount) {
    return _PaymentsExtended.INTERFACE.encodeFunctionData("pull", [token.address, toHex3(amount)]);
  }
  static encodeWrapETH(amount) {
    return _PaymentsExtended.INTERFACE.encodeFunctionData("wrapETH", [toHex3(amount)]);
  }
};
var PaymentsExtended = _PaymentsExtended;
PaymentsExtended.INTERFACE = new Interface4(IPeripheryPaymentsWithFeeExtended_default);

// evm/v3-router/utils/partitionMixedRouteByProtocol.ts
var partitionMixedRouteByProtocol = (route) => {
  const acc = [];
  let left = 0;
  let right = 0;
  while (right < route.pools.length) {
    if (route.pools[left].type !== route.pools[right].type) {
      acc.push(route.pools.slice(left, right));
      left = right;
    }
    right++;
    if (right === route.pools.length) {
      acc.push(route.pools.slice(left, right));
    }
  }
  return acc;
};

// evm/v3-router/utils/getOutputOfPools.ts
var getOutputOfPools = (pools3, firstInputToken) => {
  const { inputToken: outputToken } = pools3.reduce(
    ({ inputToken }, pool) => {
      if (!involvesCurrency(pool, inputToken))
        throw new Error("PATH");
      const output = getOutputCurrency(pool, inputToken);
      return {
        inputToken: output
      };
    },
    { inputToken: firstInputToken }
  );
  return outputToken;
};

// evm/v3-router/utils/getPriceImpact.ts
import { CurrencyAmount as CurrencyAmount26, Percent as Percent11 } from "@madmeerkatfinance/sdk";
function getPriceImpact(trade) {
  let spotOutputAmount = CurrencyAmount26.fromRawAmount(trade.outputAmount.currency.wrapped, 0);
  for (const route of trade.routes) {
    const { inputAmount } = route;
    const midPrice = getMidPrice2(route);
    spotOutputAmount = spotOutputAmount.add(midPrice.quote(inputAmount.wrapped));
  }
  const priceImpact2 = spotOutputAmount.subtract(trade.outputAmount.wrapped).divide(spotOutputAmount);
  return new Percent11(priceImpact2.numerator, priceImpact2.denominator);
}

// evm/v3-router/utils/swapRouter.ts
var ZERO8 = JSBI22.BigInt(0);
var REFUND_ETH_PRICE_IMPACT_THRESHOLD = new Percent12(JSBI22.BigInt(50), JSBI22.BigInt(100));
var _SwapRouter = class {
  /**
   * Cannot be constructed.
   */
  // eslint-disable-next-line no-useless-constructor, @typescript-eslint/no-empty-function
  constructor() {
  }
  /**
   * @notice Generates the calldata for a Swap with a V2 Route.
   * @param trade The V2Trade to encode.
   * @param options SwapOptions to use for the trade.
   * @param routerMustCustody Flag for whether funds should be sent to the router
   * @param performAggregatedSlippageCheck Flag for whether we want to perform an aggregated slippage check
   * @returns A string array of calldatas for the trade.
   */
  static encodeV2Swap(trade, options, routerMustCustody, performAggregatedSlippageCheck) {
    const amountIn = toHex4(maximumAmountIn2(trade, options.slippageTolerance).quotient);
    const amountOut = toHex4(minimumAmountOut2(trade, options.slippageTolerance).quotient);
    const route = trade.routes[0];
    const path = route.path.map((token) => token.wrapped.address);
    const recipient = routerMustCustody ? ADDRESS_THIS : typeof options.recipient === "undefined" ? MSG_SENDER : validateAndParseAddress2(options.recipient);
    if (trade.tradeType === TradeType13.EXACT_INPUT) {
      const exactInputParams = [amountIn, performAggregatedSlippageCheck ? 0 : amountOut, path, recipient];
      return _SwapRouter.INTERFACE.encodeFunctionData("swapExactTokensForTokens", exactInputParams);
    }
    const exactOutputParams = [amountOut, amountIn, path, recipient];
    return _SwapRouter.INTERFACE.encodeFunctionData("swapTokensForExactTokens", exactOutputParams);
  }
  /**
   * @notice Generates the calldata for a Swap with a Stable Route.
   * @param trade The Trade to encode.
   * @param options SwapOptions to use for the trade.
   * @param routerMustCustody Flag for whether funds should be sent to the router
   * @param performAggregatedSlippageCheck Flag for whether we want to perform an aggregated slippage check
   * @returns A string array of calldatas for the trade.
   */
  static encodeStableSwap(trade, options, routerMustCustody, performAggregatedSlippageCheck) {
    const amountIn = toHex4(maximumAmountIn2(trade, options.slippageTolerance).quotient);
    const amountOut = toHex4(minimumAmountOut2(trade, options.slippageTolerance).quotient);
    if (trade.routes.length > 1 || trade.routes[0].pools.some((p) => !isStablePool(p))) {
      throw new Error("Unsupported trade to encode");
    }
    const route = trade.routes[0];
    const path = route.path.map((token) => token.wrapped.address);
    const flags = route.pools.map((p) => p.balances.length);
    const recipient = routerMustCustody ? ADDRESS_THIS : typeof options.recipient === "undefined" ? MSG_SENDER : validateAndParseAddress2(options.recipient);
    if (trade.tradeType === TradeType13.EXACT_INPUT) {
      const exactInputParams = [path, flags, amountIn, performAggregatedSlippageCheck ? 0 : amountOut, recipient];
      return _SwapRouter.INTERFACE.encodeFunctionData("exactInputStableSwap", exactInputParams);
    }
    const exactOutputParams = [path, flags, amountOut, amountIn, recipient];
    return _SwapRouter.INTERFACE.encodeFunctionData("exactOutputStableSwap", exactOutputParams);
  }
  /**
   * @notice Generates the calldata for a Swap with a V3 Route.
   * @param trade The V3Trade to encode.
   * @param options SwapOptions to use for the trade.
   * @param routerMustCustody Flag for whether funds should be sent to the router
   * @param performAggregatedSlippageCheck Flag for whether we want to perform an aggregated slippage check
   * @returns A string array of calldatas for the trade.
   */
  static encodeV3Swap(trade, options, routerMustCustody, performAggregatedSlippageCheck) {
    const calldatas = [];
    for (const route of trade.routes) {
      const { inputAmount, outputAmount, pools: pools3, path } = route;
      const amountIn = toHex4(maximumAmountIn2(trade, options.slippageTolerance, inputAmount).quotient);
      const amountOut = toHex4(minimumAmountOut2(trade, options.slippageTolerance, outputAmount).quotient);
      const singleHop = pools3.length === 1;
      const recipient = routerMustCustody ? ADDRESS_THIS : typeof options.recipient === "undefined" ? MSG_SENDER : validateAndParseAddress2(options.recipient);
      if (singleHop) {
        if (trade.tradeType === TradeType13.EXACT_INPUT) {
          const exactInputSingleParams = {
            tokenIn: path[0].wrapped.address,
            tokenOut: path[1].wrapped.address,
            fee: pools3[0].fee,
            recipient,
            amountIn,
            amountOutMinimum: performAggregatedSlippageCheck ? 0 : amountOut,
            sqrtPriceLimitX96: 0
          };
          calldatas.push(_SwapRouter.INTERFACE.encodeFunctionData("exactInputSingle", [exactInputSingleParams]));
        } else {
          const exactOutputSingleParams = {
            tokenIn: path[0].wrapped.address,
            tokenOut: path[1].wrapped.address,
            fee: pools3[0].fee,
            recipient,
            amountOut,
            amountInMaximum: amountIn,
            sqrtPriceLimitX96: 0
          };
          calldatas.push(_SwapRouter.INTERFACE.encodeFunctionData("exactOutputSingle", [exactOutputSingleParams]));
        }
      } else {
        const pathStr = encodeMixedRouteToPath(
          __spreadProps(__spreadValues({}, route), { input: inputAmount.currency, output: outputAmount.currency }),
          trade.tradeType === TradeType13.EXACT_OUTPUT
        );
        if (trade.tradeType === TradeType13.EXACT_INPUT) {
          const exactInputParams = {
            path: pathStr,
            recipient,
            amountIn,
            amountOutMinimum: performAggregatedSlippageCheck ? 0 : amountOut
          };
          calldatas.push(_SwapRouter.INTERFACE.encodeFunctionData("exactInput", [exactInputParams]));
        } else {
          const exactOutputParams = {
            path: pathStr,
            recipient,
            amountOut,
            amountInMaximum: amountIn
          };
          calldatas.push(_SwapRouter.INTERFACE.encodeFunctionData("exactOutput", [exactOutputParams]));
        }
      }
    }
    return calldatas;
  }
  /**
   * @notice Generates the calldata for a MixedRouteSwap. Since single hop routes are not MixedRoutes, we will instead generate
   *         them via the existing encodeV3Swap and encodeV2Swap methods.
   * @param trade The MixedRouteTrade to encode.
   * @param options SwapOptions to use for the trade.
   * @param routerMustCustody Flag for whether funds should be sent to the router
   * @param performAggregatedSlippageCheck Flag for whether we want to perform an aggregated slippage check
   * @returns A string array of calldatas for the trade.
   */
  static encodeMixedRouteSwap(trade, options, routerMustCustody, performAggregatedSlippageCheck) {
    let calldatas = [];
    const isExactIn = trade.tradeType === TradeType13.EXACT_INPUT;
    for (const route of trade.routes) {
      const { inputAmount, outputAmount, pools: pools3 } = route;
      const amountIn = toHex4(maximumAmountIn2(trade, options.slippageTolerance, inputAmount).quotient);
      const amountOut = toHex4(minimumAmountOut2(trade, options.slippageTolerance, outputAmount).quotient);
      const singleHop = pools3.length === 1;
      const recipient = routerMustCustody ? ADDRESS_THIS : typeof options.recipient === "undefined" ? MSG_SENDER : validateAndParseAddress2(options.recipient);
      const mixedRouteIsAllV3 = (r) => {
        return r.pools.every(isV3Pool);
      };
      const mixedRouteIsAllV2 = (r) => {
        return r.pools.every(isV2Pool);
      };
      const mixedRouteIsAllStable = (r) => {
        return r.pools.every(isStablePool);
      };
      if (singleHop) {
        if (mixedRouteIsAllV3(route)) {
          calldatas = [
            ...calldatas,
            ..._SwapRouter.encodeV3Swap(
              __spreadProps(__spreadValues({}, trade), {
                routes: [route],
                inputAmount,
                outputAmount
              }),
              options,
              routerMustCustody,
              performAggregatedSlippageCheck
            )
          ];
        } else if (mixedRouteIsAllV2(route)) {
          calldatas = [
            ...calldatas,
            _SwapRouter.encodeV2Swap(
              __spreadProps(__spreadValues({}, trade), {
                routes: [route],
                inputAmount,
                outputAmount
              }),
              options,
              routerMustCustody,
              performAggregatedSlippageCheck
            )
          ];
        } else if (mixedRouteIsAllStable(route)) {
          calldatas = [
            ...calldatas,
            _SwapRouter.encodeStableSwap(
              __spreadProps(__spreadValues({}, trade), {
                routes: [route],
                inputAmount,
                outputAmount
              }),
              options,
              routerMustCustody,
              performAggregatedSlippageCheck
            )
          ];
        } else {
          throw new Error("Unsupported route to encode");
        }
      } else {
        const sections = partitionMixedRouteByProtocol(route);
        const isLastSectionInRoute = (i) => {
          return i === sections.length - 1;
        };
        let outputToken;
        let inputToken = inputAmount.currency.wrapped;
        for (let i = 0; i < sections.length; i++) {
          const section = sections[i];
          outputToken = getOutputOfPools(section, inputToken);
          const newRoute = buildBaseRoute([...section], inputToken, outputToken);
          inputToken = outputToken.wrapped;
          const lastSectionInRoute = isLastSectionInRoute(i);
          const recipientAddress = lastSectionInRoute ? recipient : ADDRESS_THIS;
          const inAmount = i === 0 ? amountIn : 0;
          const outAmount = !lastSectionInRoute ? 0 : amountOut;
          if (mixedRouteIsAllV3(newRoute)) {
            const pathStr = encodeMixedRouteToPath(newRoute, !isExactIn);
            if (isExactIn) {
              const exactInputParams = {
                path: pathStr,
                recipient: recipientAddress,
                amountIn: inAmount,
                amountOutMinimum: outAmount
              };
              calldatas.push(_SwapRouter.INTERFACE.encodeFunctionData("exactInput", [exactInputParams]));
            } else {
              const exactOutputParams = {
                path: pathStr,
                recipient,
                amountOut: outAmount,
                amountInMaximum: inAmount
              };
              calldatas.push(_SwapRouter.INTERFACE.encodeFunctionData("exactOutput", [exactOutputParams]));
            }
          } else if (mixedRouteIsAllV2(newRoute)) {
            const path = newRoute.path.map((token) => token.wrapped.address);
            if (isExactIn) {
              const exactInputParams = [
                inAmount,
                // amountIn
                outAmount,
                // amountOutMin
                path,
                // path
                recipientAddress
                // to
              ];
              calldatas.push(_SwapRouter.INTERFACE.encodeFunctionData("swapExactTokensForTokens", exactInputParams));
            } else {
              const exactOutputParams = [outAmount, inAmount, path, recipientAddress];
              calldatas.push(_SwapRouter.INTERFACE.encodeFunctionData("swapTokensForExactTokens", exactOutputParams));
            }
          } else if (mixedRouteIsAllStable(newRoute)) {
            const path = newRoute.path.map((token) => token.wrapped.address);
            const flags = newRoute.pools.map((pool) => pool.balances.length);
            if (isExactIn) {
              const exactInputParams = [
                path,
                // path
                flags,
                // stable pool types
                inAmount,
                // amountIn
                outAmount,
                // amountOutMin
                recipientAddress
                // to
              ];
              calldatas.push(_SwapRouter.INTERFACE.encodeFunctionData("exactInputStableSwap", exactInputParams));
            } else {
              const exactOutputParams = [path, flags, outAmount, inAmount, recipientAddress];
              calldatas.push(_SwapRouter.INTERFACE.encodeFunctionData("exactOutputStableSwap", exactOutputParams));
            }
          } else {
            throw new Error("Unsupported route");
          }
        }
      }
    }
    return calldatas;
  }
  static encodeSwaps(anyTrade, options, isSwapAndAdd) {
    const trades = !Array.isArray(anyTrade) ? [anyTrade] : anyTrade;
    const numberOfTrades = trades.reduce((numOfTrades, trade) => numOfTrades + trade.routes.length, 0);
    const sampleTrade = trades[0];
    invariant7(
      trades.every((trade) => trade.inputAmount.currency.equals(sampleTrade.inputAmount.currency)),
      "TOKEN_IN_DIFF"
    );
    invariant7(
      trades.every((trade) => trade.outputAmount.currency.equals(sampleTrade.outputAmount.currency)),
      "TOKEN_OUT_DIFF"
    );
    invariant7(
      trades.every((trade) => trade.tradeType === sampleTrade.tradeType),
      "TRADE_TYPE_DIFF"
    );
    const calldatas = [];
    const inputIsNative = sampleTrade.inputAmount.currency.isNative;
    const outputIsNative = sampleTrade.outputAmount.currency.isNative;
    const performAggregatedSlippageCheck = sampleTrade.tradeType === TradeType13.EXACT_INPUT && numberOfTrades > 2;
    const routerMustCustody = outputIsNative || !!options.fee || !!isSwapAndAdd || performAggregatedSlippageCheck;
    if (options.inputTokenPermit) {
      invariant7(sampleTrade.inputAmount.currency.isToken, "NON_TOKEN_PERMIT");
      calldatas.push(SelfPermit.encodePermit(sampleTrade.inputAmount.currency, options.inputTokenPermit));
    }
    for (const trade of trades) {
      if (trade.routes.length === 1 && trade.routes[0].type === 0 /* V2 */) {
        calldatas.push(_SwapRouter.encodeV2Swap(trade, options, routerMustCustody, performAggregatedSlippageCheck));
      } else if (trade.routes.every((r) => r.type === 1 /* V3 */)) {
        for (const calldata of _SwapRouter.encodeV3Swap(
          trade,
          options,
          routerMustCustody,
          performAggregatedSlippageCheck
        )) {
          calldatas.push(calldata);
        }
      } else {
        for (const calldata of _SwapRouter.encodeMixedRouteSwap(
          trade,
          options,
          routerMustCustody,
          performAggregatedSlippageCheck
        )) {
          calldatas.push(calldata);
        }
      }
    }
    const ZERO_IN = CurrencyAmount27.fromRawAmount(sampleTrade.inputAmount.currency, 0);
    const ZERO_OUT = CurrencyAmount27.fromRawAmount(sampleTrade.outputAmount.currency, 0);
    const minAmountOut = trades.reduce(
      (sum2, trade) => sum2.add(minimumAmountOut2(trade, options.slippageTolerance)),
      ZERO_OUT
    );
    const quoteAmountOut = trades.reduce(
      (sum2, trade) => sum2.add(trade.outputAmount),
      ZERO_OUT
    );
    const totalAmountIn = trades.reduce(
      (sum2, trade) => sum2.add(maximumAmountIn2(trade, options.slippageTolerance)),
      ZERO_IN
    );
    return {
      calldatas,
      sampleTrade,
      routerMustCustody,
      inputIsNative,
      outputIsNative,
      totalAmountIn,
      minimumAmountOut: minAmountOut,
      quoteAmountOut
    };
  }
  /**
   * Produces the on-chain method name to call and the hex encoded parameters to pass as arguments for a given trade.
   * @param trades to produce call parameters for
   * @param options options for the call parameters
   */
  static swapCallParameters(trades, options) {
    const {
      calldatas,
      sampleTrade,
      routerMustCustody,
      inputIsNative,
      outputIsNative,
      totalAmountIn,
      minimumAmountOut: minAmountOut
    } = _SwapRouter.encodeSwaps(trades, options);
    if (routerMustCustody) {
      if (outputIsNative) {
        calldatas.push(PaymentsExtended.encodeUnwrapWETH9(minAmountOut.quotient, options.recipient, options.fee));
      } else {
        calldatas.push(
          PaymentsExtended.encodeSweepToken(
            sampleTrade.outputAmount.currency.wrapped,
            minAmountOut.quotient,
            options.recipient,
            options.fee
          )
        );
      }
    }
    if (inputIsNative && (sampleTrade.tradeType === TradeType13.EXACT_OUTPUT || _SwapRouter.riskOfPartialFill(trades))) {
      calldatas.push(Payments2.encodeRefundETH());
    }
    return {
      calldata: MulticallExtended.encodeMulticall(calldatas, options.deadlineOrPreviousBlockhash),
      value: toHex4(inputIsNative ? totalAmountIn.quotient : ZERO8)
    };
  }
  /**
   * Produces the on-chain method name to call and the hex encoded parameters to pass as arguments for a given trade.
   * @param trades to produce call parameters for
   * @param options options for the call parameters
   */
  static swapAndAddCallParameters(trades, options, position, addLiquidityOptions, tokenInApprovalType, tokenOutApprovalType) {
    const {
      calldatas,
      inputIsNative,
      outputIsNative,
      sampleTrade,
      totalAmountIn: totalAmountSwapped,
      quoteAmountOut,
      minimumAmountOut: minAmountOut
    } = _SwapRouter.encodeSwaps(trades, options, true);
    if (options.outputTokenPermit) {
      invariant7(quoteAmountOut.currency.isToken, "NON_TOKEN_PERMIT_OUTPUT");
      calldatas.push(SelfPermit.encodePermit(quoteAmountOut.currency, options.outputTokenPermit));
    }
    const {
      inputAmount: {
        currency: { chainId }
      }
    } = sampleTrade;
    const zeroForOne = position.pool.token0.wrapped.address === totalAmountSwapped.currency.wrapped.address;
    const { positionAmountIn, positionAmountOut } = _SwapRouter.getPositionAmounts(position, zeroForOne);
    const tokenIn = inputIsNative ? WNATIVE5[chainId] : positionAmountIn.currency.wrapped;
    const tokenOut = outputIsNative ? WNATIVE5[chainId] : positionAmountOut.currency.wrapped;
    const amountOutRemaining = positionAmountOut.subtract(quoteAmountOut.wrapped);
    if (amountOutRemaining.greaterThan(CurrencyAmount27.fromRawAmount(positionAmountOut.currency, 0))) {
      if (outputIsNative) {
        calldatas.push(PaymentsExtended.encodeWrapETH(amountOutRemaining.quotient));
      } else {
        calldatas.push(PaymentsExtended.encodePull(tokenOut, amountOutRemaining.quotient));
      }
    }
    if (inputIsNative) {
      calldatas.push(PaymentsExtended.encodeWrapETH(positionAmountIn.quotient));
    } else {
      calldatas.push(PaymentsExtended.encodePull(tokenIn, positionAmountIn.quotient));
    }
    if (tokenInApprovalType !== 0 /* NOT_REQUIRED */)
      calldatas.push(ApproveAndCall.encodeApprove(tokenIn, tokenInApprovalType));
    if (tokenOutApprovalType !== 0 /* NOT_REQUIRED */)
      calldatas.push(ApproveAndCall.encodeApprove(tokenOut, tokenOutApprovalType));
    const minimalPosition = Position2.fromAmounts({
      pool: position.pool,
      tickLower: position.tickLower,
      tickUpper: position.tickUpper,
      amount0: zeroForOne ? position.amount0.quotient.toString() : minAmountOut.quotient.toString(),
      amount1: zeroForOne ? minAmountOut.quotient.toString() : position.amount1.quotient.toString(),
      useFullPrecision: false
    });
    calldatas.push(
      ApproveAndCall.encodeAddLiquidity(position, minimalPosition, addLiquidityOptions, options.slippageTolerance)
    );
    if (inputIsNative) {
      calldatas.push(PaymentsExtended.encodeUnwrapWETH9(ZERO8));
    } else {
      calldatas.push(PaymentsExtended.encodeSweepToken(tokenIn, ZERO8));
    }
    if (outputIsNative) {
      calldatas.push(PaymentsExtended.encodeUnwrapWETH9(ZERO8));
    } else {
      calldatas.push(PaymentsExtended.encodeSweepToken(tokenOut, ZERO8));
    }
    let value;
    if (inputIsNative) {
      value = totalAmountSwapped.wrapped.add(positionAmountIn.wrapped).quotient;
    } else if (outputIsNative) {
      value = amountOutRemaining.quotient;
    } else {
      value = ZERO8;
    }
    return {
      calldata: MulticallExtended.encodeMulticall(calldatas, options.deadlineOrPreviousBlockhash),
      value: value.toString()
    };
  }
  // if price impact is very high, there's a chance of hitting max/min prices resulting in a partial fill of the swap
  static riskOfPartialFill(trades) {
    if (Array.isArray(trades)) {
      return trades.some((trade) => {
        return _SwapRouter.v3TradeWithHighPriceImpact(trade);
      });
    }
    return _SwapRouter.v3TradeWithHighPriceImpact(trades);
  }
  static v3TradeWithHighPriceImpact(trade) {
    return !(trade.routes.length === 1 && trade.routes[0].type === 0 /* V2 */) && getPriceImpact(trade).greaterThan(REFUND_ETH_PRICE_IMPACT_THRESHOLD);
  }
  static getPositionAmounts(position, zeroForOne) {
    const { amount0, amount1 } = position.mintAmounts;
    const currencyAmount0 = CurrencyAmount27.fromRawAmount(position.pool.token0, amount0);
    const currencyAmount1 = CurrencyAmount27.fromRawAmount(position.pool.token1, amount1);
    const [positionAmountIn, positionAmountOut] = zeroForOne ? [currencyAmount0, currencyAmount1] : [currencyAmount1, currencyAmount0];
    return { positionAmountIn, positionAmountOut };
  }
};
var SwapRouter = _SwapRouter;
SwapRouter.INTERFACE = new Interface5(ISwapRouter02_default);
export {
  ADDITIONAL_BASES,
  ADDRESS_THIS,
  BASES_TO_CHECK_TRADES_AGAINST,
  BASES_TO_TRACK_LIQUIDITY_FOR,
  BASE_SWAP_COST_STABLE_SWAP,
  BASE_SWAP_COST_V2,
  BASE_SWAP_COST_V3,
  BETTER_TRADE_LESS_HOPS_THRESHOLD,
  BIG_INT_TEN,
  BIPS_BASE,
  COST_PER_EXTRA_HOP_STABLE_SWAP,
  COST_PER_EXTRA_HOP_V2,
  COST_PER_HOP_V3,
  COST_PER_INIT_TICK,
  COST_PER_UNINIT_TICK,
  CUSTOM_BASES,
  Route as LegacyRoute,
  RouteType as LegacyRouteType,
  legacyRouter_exports as LegacyRouter,
  Trade4 as LegacyTrade,
  MIN_BNB,
  MIXED_ROUTE_QUOTER_ADDRESSES,
  MSG_SENDER,
  PINNED_PAIRS,
  PoolType,
  ROUTER_ADDRESS,
  RouteType2 as RouteType,
  STABLE_SWAP_INFO_ADDRESS,
  SUGGESTED_BASES,
  SWAP_ROUTER_ADDRESSES,
  smartRouter_exports as SmartRouter,
  stableSwap_exports as StableSwap,
  SwapRouter,
  V2_FEE_PATH_PLACEHOLDER,
  V3_QUOTER_ADDRESSES,
  getStableSwapPools,
  nativeWrappedTokenByChain,
  usdGasTokensByChain
};
