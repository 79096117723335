import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | MadMeerkatFinance',
  defaultTitle: 'MadMeerkatFinance',
  description:
    'Our DEX is community focused and only charges 0.17% fees per trade. We are also the first to incorporate defi and NFT seamlessly on Arbitrum.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@MadMeerkatFinance',
    site: '@MadMeerkatFinance',
  },
  openGraph: {
    title: 'MadMeerkatFinance - A next evolution DeFi exchange on Arbitrum',
    description:
      'Our DEX is community focused and only charges 0.17% fees per trade. We are also the first to incorporate defi and NFT seamlessly on Arbitrum.',
    images: [{ url: '/arbibanner.png' }],
  },
}
