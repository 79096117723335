import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="16" height="20" fill-rule="evenodd" {...props}>
      <path d="M 2.132812 5.164062 C 2.15625 4.945312 2.070312 4.726562 1.90625 4.578125 L 0.226562 2.550781 L 0.226562 2.25 L 5.445312 2.25 L 9.480469 11.097656 L 13.023438 2.25 L 18 2.25 L 18 2.550781 L 16.5625 3.929688 C 16.4375 4.023438 16.378906 4.179688 16.402344 4.332031 L 16.402344 14.457031 C 16.378906 14.609375 16.4375 14.765625 16.5625 14.859375 L 17.964844 16.238281 L 17.964844 16.539062 L 10.90625 16.539062 L 10.90625 16.238281 L 12.363281 14.824219 C 12.503906 14.683594 12.503906 14.640625 12.503906 14.421875 L 12.503906 6.242188 L 8.460938 16.507812 L 7.917969 16.507812 L 3.210938 6.242188 L 3.210938 13.121094 C 3.171875 13.410156 3.265625 13.703125 3.46875 13.910156 L 5.359375 16.203125 L 5.359375 16.507812 L 0 16.507812 L 0 16.203125 L 1.890625 13.910156 C 2.09375 13.699219 2.183594 13.40625 2.132812 13.121094 Z M 2.132812 5.164062" />
    </Svg>
  );
};

export default Icon;
