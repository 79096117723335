import { FarmConfigV3, SerializedFarmConfig } from '@madmeerkatfinance/farms'
import { cronosTokens } from '@madmeerkatfinance/tokens'
import { FeeAmount } from '@madmeerkatfinance/v3-sdk'

export const farmsV3 = [
  {
    pid: 1,
    lpSymbol: 'MMF-CRO LP',
    token: cronosTokens.mmf,
    quoteToken: cronosTokens.wcro,
    lpAddress: '0xF22C0A3D4435ceb5f14E257dB7D939aEDc219473',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 2,
    lpSymbol: 'WCRO-USDC LP',
    lpAddress: '0xeF38674A56aA519E71EF7b70b71473A7A1b60f0b',
    token: cronosTokens.wcro,
    quoteToken: cronosTokens.usdc,
    feeAmount: FeeAmount.MEDIUM,
  },
  // {
  //   pid: 3,
  //   lpSymbol: 'WBTC-USDC LP',
  //   lpAddress: '0x3e7AD5F9a569A78196547f69629b5D577185652A',
  //   token: cronosTokens.wbtc,
  //   quoteToken: cronosTokens.usdc,
  //   feeAmount: FeeAmount.MEDIUM,
  // },
  // {
  //   pid: 4,
  //   lpSymbol: 'USDC-USDT LP',
  //   lpAddress: '0xFeba7E93e26A395d5b5685920927c75129777732',
  //   token: cronosTokens.usdt,
  //   quoteToken: cronosTokens.usdc,
  //   feeAmount: FeeAmount.LOWEST,
  // },
  // {
  //   pid: 5,
  //   lpSymbol: 'ARB-USDC LP',
  //   lpAddress: '0x251Ae6900C293DBd11143A063269A5f4ddE2312f',
  //   token: cronosTokens.arb,
  //   quoteToken: cronosTokens.usdc,
  //   feeAmount: FeeAmount.MEDIUM,
  // },
] satisfies FarmConfigV3[]

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  {
    pid: 0,
    v1pid: 0,
    lpSymbol: 'MMF',
    lpAddress: '0xbA452A1c0875D33a440259B1ea4DcA8f5d86D9Ae',
    token: cronosTokens.mmf,
    quoteToken: cronosTokens.wcro,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 1,
    v1pid: 1,
    lpSymbol: 'MMF-CRO LP',
    lpAddress: '0xbA452A1c0875D33a440259B1ea4DcA8f5d86D9Ae',
    token: cronosTokens.mmf,
    quoteToken: cronosTokens.wcro,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 2,
    v1pid: 2,
    lpSymbol: 'MMF-USDC LP',
    lpAddress: '0x722f19bd9A1E5bA97b3020c6028c279d27E4293C',
    token: cronosTokens.mmf,
    quoteToken: cronosTokens.usdc,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 3,
    v1pid: 3,
    lpSymbol: 'MMF-MUSD LP',
    lpAddress: '0xeF2dC4849bDCC120acB7274cd5A557B5145DA149',
    token: cronosTokens.mmf,
    quoteToken: cronosTokens.musd,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 4,
    v1pid: 4,
    lpSymbol: 'WBTC-CRO LP',
    lpAddress: '0x5383202D48C24aAA19873366168f2Ed558a00ff0',
    token: cronosTokens.wbtc,
    quoteToken: cronosTokens.wcro,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 5,
    v1pid: 5,
    lpSymbol: 'CRO-USDC LP',
    lpAddress: '0xa68466208F1A3Eb21650320D2520ee8eBA5ba623',
    token: cronosTokens.wcro,
    quoteToken: cronosTokens.usdc,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 6,
    v1pid: 6,
    lpSymbol: 'CRO-USDT LP',
    lpAddress: '0xEB28c926A7Afc75fcC8d6671Acd4c4A298b38419',
    token: cronosTokens.wcro,
    quoteToken: cronosTokens.usdt,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 7,
    v1pid: 7,
    lpSymbol: 'USDC-USDT LP',
    lpAddress: '0x6F186E4BEd830D13DcE638e40bA27Fd6d91BAd0B',
    token: cronosTokens.usdt,
    quoteToken: cronosTokens.usdc,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 8,
    v1pid: 8,
    lpSymbol: 'ETH-CRO LP',
    lpAddress: '0x019d9479606FBDd4aCB16488e0aAE49E4684322b',
    token: cronosTokens.weth,
    quoteToken: cronosTokens.wcro,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 9,
    v1pid: 9,
    lpSymbol: 'ETH-WBTC LP',
    lpAddress: '0x0101112C7aDdb2E8197922e9cFa17cbAA935ECCc',
    token: cronosTokens.weth,
    quoteToken: cronosTokens.wbtc,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 10,
    v1pid: 10,
    lpSymbol: 'MMF-USDT LP',
    lpAddress: '0x5801d37e04ab1f266c35a277e06c9d3afa1c9ca2',
    token: cronosTokens.mmf,
    quoteToken: cronosTokens.usdt,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 11,
    v1pid: 11,
    lpSymbol: 'MMO-CRO LP',
    lpAddress: '0xf0b5074DBf73c96d766C9A48726CEe7a6074D436',
    token: cronosTokens.mmo,
    quoteToken: cronosTokens.wcro,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 12,
    v1pid: 2,
    lpSymbol: 'DAI-USDC LP',
    lpAddress: '0x787A47b0596fa8F7D6666F3C59696b3c57bB612b',
    token: cronosTokens.dai,
    quoteToken: cronosTokens.usdc,
    isV2: true,
    rewardToken: "MMF",
  },
  // {
  //   pid: 13,
  //   lpSymbol: 'MUSD-3MM LP',
  //   isStableSwap: true,
  //   numTokens: 2,
  //   basePool: '0xdB04E53eC3FAB887Be2F55C3fD79bC57855bC827',
  //   lpAddress: '0xdB04E53eC3FAB887Be2F55C3fD79bC57855bC827',
  //   token: cronosTokens.musdmm3, // 3MMLP token address
  //   quoteToken: cronosTokens.mm3,
  //   isV2: true,
  // },
  {
    pid: 14,
    v1pid: 14,
    lpSymbol: 'MUSD-USDC LP',
    lpAddress: '0x0B083d50417FEC1390C2C07Eba85f31D5EeFC350',
    token: cronosTokens.musd,
    quoteToken: cronosTokens.usdc,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 15,
    v1pid: 15,
    lpSymbol: 'METF-MMF LP',
    lpAddress: '0xd7385f46FFb877d8c8Fe78E5f5a7c6b2F18C05A7',
    token: cronosTokens.metf,
    quoteToken: cronosTokens.mmf,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 16,
    v1pid: 16,
    lpSymbol: 'CRO-MUSD LP',
    lpAddress: '0x28F9Eb94c0E954ceE2E0407B084c760BaBf9F39c',
    token: cronosTokens.wcro,
    quoteToken: cronosTokens.musd,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 30,
    v1pid: 30,
    lpSymbol: 'MINTME-MMF LP',
    lpAddress: '0xb3941CA26cD367D556E7D0A214F3fd2234c3bbB0',
    token: cronosTokens.mintme,
    quoteToken: cronosTokens.mmf,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 31,
    v1pid: 31,
    lpSymbol: 'CROISSANT-MMF LP',
    lpAddress: '0xDE991150329dbe53389dB41DB459cAe3fF220bac',
    token: cronosTokens.croissant,
    quoteToken: cronosTokens.mmf,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 32,
    v1pid: 32,
    lpSymbol: 'SINGLE-USDC LP',
    lpAddress: '0x2d485E96e02dcF502B1F8C367523B29d4139d596',
    token: cronosTokens.single,
    quoteToken: cronosTokens.usdc,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 33,
    v1pid: 33,
    lpSymbol: 'VERSA-USDC LP',
    lpAddress: '0xB6894f071da907301b4F0C4D87D5f2e42E22402d',
    token: cronosTokens.versa,
    quoteToken: cronosTokens.usdc,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 34,
    v1pid: 34,
    lpSymbol: 'MIMAS-CRO LP',
    lpAddress: '0xf56FDfeeF0Ba3de23DaB13a85602bd7BF135E80f',
    token: cronosTokens.mimas,
    quoteToken: cronosTokens.wcro,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 35,
    v1pid: 35,
    lpSymbol: 'bCRO-CRO LP',
    lpAddress: '0xB3b66bE228b930C2F319621955CA507aDE3453dd',
    token: cronosTokens.bcro,
    quoteToken: cronosTokens.wcro,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 36,
    v1pid: 36,
    lpSymbol: 'MAD-SVN LP',
    lpAddress: '0x4673749a3cb3B3E787b3e88Ac36e356DddBe99B9',
    token: cronosTokens.mad,
    quoteToken: cronosTokens.svn,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 37,
    v1pid: 37,
    lpSymbol: 'DONUT-SVN LP',
    lpAddress: '0xCA5aa86E4e40a353020EcFAc4814A8F61FBB00B0',
    token: cronosTokens.donut,
    quoteToken: cronosTokens.svn,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 38,
    v1pid: 38,
    lpSymbol: 'MNG-MMF LP',
    lpAddress: '0xa7bE5cB8Be6484c06aBb8942fe682797bb9A76Ec',
    token: cronosTokens.mng,
    quoteToken: cronosTokens.mmf,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 39,
    v1pid: 39,
    lpSymbol: 'ANN-MMF LP',
    lpAddress: '0xD41B1d8edEa36795f9BA4802e5A195925E720589',
    token: cronosTokens.ann,
    quoteToken: cronosTokens.mmf,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 40,
    v1pid: 40,
    lpSymbol: 'ICY-CRO LP',
    lpAddress: '0x43DE574857F2a736D8db7386A687e4A09ca7471F',
    token: cronosTokens.icy,
    quoteToken: cronosTokens.wcro,
    isV2: true,
    rewardToken: "MMF",
  },
  {
    pid: 42,
    v1pid: 42,
    lpSymbol: 'ICY-bCRO LP',
    lpAddress: '0x02f958cb9774807Cc31290F653ee7b15575778cB',
    token: cronosTokens.icy,
    quoteToken: cronosTokens.bcro,
    isV2: true,
    rewardToken: "MMF",
  },
].map((p) => ({ ...p, token: p.token.serialize, quoteToken: p.quoteToken.serialize }))

export default farms
