import { createSlice } from '@reduxjs/toolkit'
import { XMmfState } from '@madmeerkatfinance/vemmf/src/types'
import fetchXMmfBarAllowance, {
  fetchXMmfBarTotalSupply,
  fetchListUnlocking,
  fetchXMmfUnlockedBoost,
} from './fetchXMmfBarUser'

const initialState: XMmfState = {
  userDataLoaded: false,
  data: {
    allowance: {
      xMmfAllowance: '0',
      xMmfUnlockerAllowance: '0',
    },
    totalSupply: '0',
    lockBoost: {},
    vaults: '0',
    mine: '0',
    listUnlocking: [],
  },
}

export const fetchXMmfBarPoolDataAsync = (chainId: number) => async (dispatch) => {
  const totalSupply = await fetchXMmfBarTotalSupply(chainId)
  const lockBoost = await fetchXMmfUnlockedBoost(chainId)

  const userData = {
    totalSupply,
    lockBoost,
  }

  dispatch(updateXMmfBarUserData(userData))
}

export const fetchXMmfBarUserDataAsync =
  (account: string, chainId: number) =>
  async (dispatch) => {
    const allowance = await fetchXMmfBarAllowance(account, chainId)

    const userData = {
      allowance,
    }

    dispatch(updateXMmfBarUserData(userData))
  }

export const fetchListUnlockingDataAsync =
  (account: string, chainId: number) =>
  async (dispatch) => {
    const listUnlocking = await fetchListUnlocking(account, chainId)

    const userData = {
      listUnlocking,
    }

    dispatch(updateXMmfBarUserData(userData))
  }

export const xMmfSlice = createSlice({
  name: 'xmmf',
  initialState,
  reducers: {
    updateXMmfBarUserData: (state, action) => {
      const value = action.payload
      state.data = { ...state.data, ...value }
      state.userDataLoaded = true
    },
  },
})

// Actions
export const { updateXMmfBarUserData } = xMmfSlice.actions

export default xMmfSlice.reducer
