import { getFullDecimalMultiplier } from '@madmeerkatfinance/utils/getFullDecimalMultiplier'

export const BSC_BLOCK_TIME = 15

// CAKE_PER_BLOCK details
// 40 MMF is minted per block
// 20 MMF per block is sent to Burn pool (A farm just for burning cake)
// 10 MMF per block goes to MMF syrup pool
// 9 MMF per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// MMF/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const CAKE_PER_BLOCK = 24
export const CRONOS_CAKE_PER_BLOCK = 50
export const BLOCKS_PER_DAY = (60 / BSC_BLOCK_TIME) * 60 * 24
export const BLOCKS_PER_YEAR = BLOCKS_PER_DAY * 365 // 10512000
export const CRONOS_BLOCK_TIME = 5.7
export const CRONOS_BLOCKS_PER_YEAR = (60 / CRONOS_BLOCK_TIME) * 60 * 24 * 365 // 10512000
export const CRONOS_CAKE_PER_YEAR = CRONOS_CAKE_PER_BLOCK * CRONOS_BLOCKS_PER_YEAR
export const CAKE_PER_YEAR = CAKE_PER_BLOCK * BLOCKS_PER_YEAR
export const V2_BASE_URL = 'https://mm.finance'
export const BASE_URL = 'https://mm.finance'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const DEFAULT_TOKEN_DECIMAL = getFullDecimalMultiplier(18)
export const DEFAULT_GAS_LIMIT = 1200000
export const BOOSTED_FARM_GAS_LIMIT = 500000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
export const BLACKHOLE = '0x0000000000000000000000000000000000000000'

export const getBlocksPerYear = (chainId: number) => {
  switch (chainId) {
    case 25:
      return CRONOS_BLOCKS_PER_YEAR
    case 42161:
      return BLOCKS_PER_YEAR
    default:
      return BLOCKS_PER_YEAR
  }
}

export const getCakePerYear = (chainId: number) => {
  switch (chainId) {
    case 25:
      return CRONOS_CAKE_PER_YEAR
    case 42161:
      return CAKE_PER_YEAR
    default:
      return CAKE_PER_YEAR
  }
}
