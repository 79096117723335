import useSWRImmutable from 'swr/immutable'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from './bigNumber'
import { ChainId } from '@madmeerkatfinance/sdk'

export const useCakePrice = (chainId: ChainId) => {
  return useSWRImmutable(
    ['cake-usd-price'],
    async () => {
      const cake = await fetchCakePrice(chainId)
      return cake.price as string
    },
    {
      refreshInterval: 1_000 * 10,
    },
  )
}

export const fetchCakePrice = async (chainId: ChainId) => {
  if (chainId === ChainId.ARBITRUM) {
    const response = await fetch(
      `https://api.dexscreener.com/latest/dex/tokens/0x56b251d4b493ee3956E3f899D36b7290902D2326`,
    )
    const data = (await response.json()) as any

    // return formatUnits(latestAnswer, 8)
    return { price: data?.pairs?.[0]?.priceUsd, updatedAt: new Date().toISOString() }
  }

  if (chainId === ChainId.CRONOS) {
    const response = await fetch(
      `https://api.dexscreener.com/latest/dex/tokens/0x97749c9B61F878a880DfE312d2594AE07AEd7656`,
    )
    const data = (await response.json()) as any

    // return formatUnits(latestAnswer, 8)
    return { price: data?.pairs?.[0]?.priceUsd, updatedAt: new Date().toISOString() }
  }

  const response = await fetch(
    `https://api.dexscreener.com/latest/dex/tokens/0x56b251d4b493ee3956E3f899D36b7290902D2326`,
  )
  const data = (await response.json()) as any

  // return formatUnits(latestAnswer, 8)
  return { price: data?.pairs?.[0]?.priceUsd, updatedAt: new Date().toISOString() }
}

// for migration to bignumber.js to avoid breaking changes
export const useCakePriceAsBN = (chainId: ChainId) => {
  const { data } = useSWRImmutable(
    ['cake-usd-price-bn'],
    async () => {
      await fetchCakePrice(chainId)
      const cake = await fetchCakePrice(chainId)
      return new BigNumber(cake.price)
    },
    {
      compare: (a, b) => {
        if (!a && !b) return true
        if (!a || !b) return false
        return a.eq(b)
      },
      refreshInterval: 1_000 * 10,
    },
  )

  return data ?? BIG_ZERO
}
