import BigNumber from 'bignumber.js'
import { arbitrumTokens as tokens } from '@madmeerkatfinance/tokens'
import { getCakeContract } from 'utils/contractHelpers'

const croContract = getCakeContract()

export const fetchVeMMFBalance = async (veMMFContract) => {
  const balance = await croContract.balanceOf(veMMFContract.address.toString())
  return new BigNumber(balance._hex).toJSON()
}

export const fetchVeMMFTotalSupply = async (veMMFContract) => {
  const totalSupply = await veMMFContract.totalSupply()
  return new BigNumber(totalSupply._hex).toJSON()
}

const fetchVeMMFAllowance = async (veMMFContract, account: string) => {
  try {
    const allowance = await veMMFContract.allowance(account, tokens.vemmf.address)
    return new BigNumber(allowance._hex).toJSON()
  } catch (error) {
    return '0'
  }
}

export default fetchVeMMFAllowance
