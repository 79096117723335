import { FormatTypes } from '@ethersproject/abi'
import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import { PositionDetails } from '@madmeerkatfinance/farms'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useMasterchefV3, useV3NFTPositionManagerContract } from 'hooks/useContract'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useContractRead } from 'wagmi'
import chunk from 'lodash/chunk'
import concat from 'lodash/concat'
import flatMap from 'lodash/flatMap'
import { multicallv2 } from 'utils/multicall'

interface UseV3PositionsResults {
  loading: boolean
  positions: PositionDetails[] | undefined
}

interface UseV3PositionResults {
  loading: boolean
  position: PositionDetails | undefined
}

let fetchedPositionsAtom = ''

export function useV3PositionsFromTokenIds(tokenIds: BigNumber[] | undefined): UseV3PositionsResults {
  const [isLoading, setLoading] = useState(false)
  const [positions, setPositions] = useState<PositionDetails[]>()
  const positionManager = useV3NFTPositionManagerContract()
  const { chainId } = useActiveChainId()

  const calls = tokenIds.map((tokenId) => ({
    address: positionManager.address as `0x${string}`,
    name: 'positions',
    params: [tokenId]
  }))

  useEffect(() => {
    if (fetchedPositionsAtom === JSON.stringify(tokenIds)) {
      setLoading(false)
      return
    }

    async function fetchTokenIds() {
      setLoading(true)
      fetchedPositionsAtom = JSON.stringify(tokenIds)
      const splitCalls = chunk(calls, 190)
      const resp = await Promise.all(splitCalls.map((c) => multicallv2({
        abi: positionManager.interface.format(FormatTypes.json) as any,
        calls: c,
        chainId
      })))
      const result = []
      for (let i = 0; i < resp.length; i++) {
        result.push(...resp[i])
      }
      setPositions(result)
      setLoading(false)
    }

    fetchTokenIds()
  }, [calls, chainId, positionManager.interface, tokenIds])

  return {
    loading: isLoading,
    positions: useMemo(
      () =>
        (positions as Omit<PositionDetails, 'tokenId'>[])
        ?.map((position, i) => position && tokenIds?.[i]
          ? {
            ...position,
            tokenId: tokenIds?.[i]
          }
          : null
        )
        .filter(Boolean),
      [tokenIds, positions]
    )
  }
}

export function useV3PositionFromTokenId(tokenId: BigNumber | undefined): UseV3PositionResults {
  const position = useV3PositionsFromTokenIds(tokenId ? [tokenId] : undefined)

  return useMemo(
    () => ({
      loading: position.loading,
      position: position.positions?.[0]
    }),
    [position.loading, position.positions]
  )
}

export function useV3TokenIdsByAccount(
  contract: Contract,
  account: string | null | undefined
): { tokenIds: BigNumber[]; loading: boolean } {
  const { chainId } = useActiveChainId()
  const [tokenIds, setTokenIds] = useState<BigNumber[]>([])
  const {
    isLoading: balanceLoading,
    data: accountBalance_,
    refetch: refetchBalance
  } = useContractRead<any, any, BigNumber>({
    abi: contract.interface.format(FormatTypes.json) as any,
    address: contract.address as `0x${string}`,
    args: [account ?? undefined],
    functionName: 'balanceOf',
    enabled: !!account,
    watch: true,
    chainId
  })

  // we don't expect any account balance to ever exceed the bounds of max safe int
  const accountBalance: number | undefined = accountBalance_?.toNumber()

  const fetchTokenIds = useCallback(async () => {
    const calls = []
    for (let i = 0; i < accountBalance; i++) {
      calls.push({
        address: contract.address as `0x${string}`,
        name: 'tokenOfOwnerByIndex',
        params: [account, i]
      })
    }

    const splitCalls = chunk(calls, 190)
    const resp = await Promise.all(splitCalls.map((c) => multicallv2({
      abi: contract.interface.format(FormatTypes.json) as any,
      calls: c,
      chainId
    })))
    setTokenIds(flatMap(concat(...resp)))
  }, [account, accountBalance, chainId, contract.address, contract.interface])

  // refetch when account changes, It seems like the useContractReads doesn't refetch when the account changes on production
  // check if we can remove this effect when we upgrade to the latest version of wagmi
  useEffect(() => {
    if (account) {
      refetchBalance()
      fetchTokenIds()
    }
  }, [account, refetchBalance, fetchTokenIds])

  return {
    tokenIds: useMemo(() => tokenIds.filter(Boolean) as BigNumber[], [tokenIds]),
    loading: balanceLoading
  }
}

export function useV3Positions(account: string | null | undefined): UseV3PositionsResults {
  const positionManager = useV3NFTPositionManagerContract()
  const masterchefV3 = useMasterchefV3()

  const { tokenIds, loading: tokenIdsLoading } = useV3TokenIdsByAccount(positionManager, account)

  const { tokenIds: stakedTokenIds } = useV3TokenIdsByAccount(masterchefV3, account)

  const totalTokenIds = useMemo(() => [...stakedTokenIds, ...tokenIds], [stakedTokenIds, tokenIds])

  const { positions, loading: positionsLoading } = useV3PositionsFromTokenIds(totalTokenIds)

  return useMemo(
    () => ({
      loading: tokenIdsLoading || positionsLoading,
      positions: positions?.map((position) => ({
        ...position,
        isStaked: Boolean(stakedTokenIds?.find((s) => s.eq(position.tokenId)))
      }))
    }),
    [positions, positionsLoading, stakedTokenIds, tokenIdsLoading]
  )
}
