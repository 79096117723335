import React, { createElement } from "react";
import styled from "styled-components";
import LogoRound from "../Svg/Icons/LogoRound";
import Text from "../Text/Text";
import Skeleton from "../Skeleton/Skeleton";
import { Colors } from "../../theme";
import MenuItems from "../MenuItems";
import { DropdownMenuItemType, DropdownMenuProps } from "../DropdownMenu/types";

export interface Props {
  color?: keyof Colors;
  cakePriceUsd?: number;
  showSkeleton?: boolean;
  isMobile?: boolean;
}

const PriceLink = styled.a`
  display: flex;
  align-items: center;
  svg {
    transition: transform 0.3s;
  }
  :hover {
    svg {
      transform: scale(1.2);
    }
  }
`;

const PriceMarkets = styled.div`
  display: flex;
  align-items: center;
  svg {
    transition: transform 0.3s;
  }
  :hover {
    svg {
      transform: scale(1.2);
    }
  }
`;

const CakePrice: React.FC<React.PropsWithChildren<Props>> = ({
  cakePriceUsd,
  color = "textSubtle",
  showSkeleton = true,
}) => {
  if (cakePriceUsd) {
    const menuItems = [
      {
        label: <Text color={color} bold>{`$${cakePriceUsd.toFixed(3)}`}</Text>,
        href: "#",
        items: [
          {
            label: "DexScreener",
            href: "https://dexscreener.com/arbitrum/0x4119fe7ef21809629239ed2496211d61af78546e",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "GeckoTerminal",
            href: "https://www.geckoterminal.com/arbitrum/pools/0x4119fe7ef21809629239ed2496211d61af78546e",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
        ],
      },
    ];

    return (
      <PriceMarkets>
        <LogoRound width="24px" style={{ marginRight: "8px" }} />
        <MenuItems items={menuItems} originalStyle />
      </PriceMarkets>
    );
  }

  if (showSkeleton) return <Skeleton width={80} height={24} />;

  return null;
};

export default React.memo(CakePrice);
