import { FarmConfigV3, SerializedFarmConfig } from '@madmeerkatfinance/farms'
import { arbitrumTokens } from '@madmeerkatfinance/tokens'
import { FeeAmount } from '@madmeerkatfinance/v3-sdk'
import { CAKE_BNB_LP_MAINNET } from './common'

export const farmsV3 = [
  {
    pid: 1,
    lpSymbol: 'MMF-USDC LP',
    token: arbitrumTokens.mmf,
    quoteToken: arbitrumTokens.usdc,
    lpAddress: '0x1C27E9331C13773cE5bEe58D228ec29697c95241',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 2,
    lpSymbol: 'WETH-USDC LP',
    lpAddress: '0x8B34d24154F6A423B870e8029Ae2A85223105Bdc',
    token: arbitrumTokens.weth,
    quoteToken: arbitrumTokens.usdc,
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 6,
    lpSymbol: 'MMF-WETH LP',
    token: arbitrumTokens.mmf,
    quoteToken: arbitrumTokens.weth,
    lpAddress: '0xA1b74FadAb5770A58cA3BbDFB71F41A5BC943F55',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 3,
    lpSymbol: 'WBTC-USDC LP',
    lpAddress: '0x3e7AD5F9a569A78196547f69629b5D577185652A',
    token: arbitrumTokens.wbtc,
    quoteToken: arbitrumTokens.usdc,
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 4,
    lpSymbol: 'USDC-USDT LP',
    lpAddress: '0xFeba7E93e26A395d5b5685920927c75129777732',
    token: arbitrumTokens.usdt,
    quoteToken: arbitrumTokens.usdc,
    feeAmount: FeeAmount.LOWEST,
  },
  {
    pid: 5,
    lpSymbol: 'ARB-USDC LP',
    lpAddress: '0x251Ae6900C293DBd11143A063269A5f4ddE2312f',
    token: arbitrumTokens.arb,
    quoteToken: arbitrumTokens.usdc,
    feeAmount: FeeAmount.MEDIUM,
  },
] satisfies FarmConfigV3[]

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  {
    pid: 0,
    v1pid: 0,
    nftBoost: true,
    lpSymbol: 'xMMF',
    lpAddress: '0x4119fE7EF21809629239ED2496211D61af78546e',
    token: arbitrumTokens.mmf,
    quoteToken: arbitrumTokens.usdc,
  },
  {
    pid: 1,
    v1pid: 1,
    nftBoost: true,
    lpSymbol: 'MMF-USDC LP',
    lpAddress: '0x4119fE7EF21809629239ED2496211D61af78546e',
    token: arbitrumTokens.mmf,
    quoteToken: arbitrumTokens.usdc,
  },
  {
    pid: 2,
    v1pid: 2,
    nftBoost: true,
    lpSymbol: 'WETH-USDC LP',
    lpAddress: '0x32481a0466e66eE80e9d50A0DA120f8D16041787',
    token: arbitrumTokens.weth,
    quoteToken: arbitrumTokens.usdc,
  },
  {
    pid: 3,
    v1pid: 3,
    nftBoost: true,
    lpSymbol: 'WBTC-USDC LP',
    lpAddress: '0xC082398767Ae77C73f0BC006B0efE460944177df',
    token: arbitrumTokens.wbtc,
    quoteToken: arbitrumTokens.usdc,
  },
  {
    pid: 4,
    v1pid: 4,
    nftBoost: true,
    lpSymbol: 'USDT-USDC LP',
    lpAddress: '0x590fF65633bB0CBDf323877d05539E47798786dA',
    token: arbitrumTokens.usdt,
    quoteToken: arbitrumTokens.usdc,
  },
  {
    pid: 5,
    v1pid: 5,
    nftBoost: true,
    lpSymbol: 'WBTC-WETH LP',
    lpAddress: '0x446822663Bc5A00798BE82f5ceAff4cF10cf06BC',
    token: arbitrumTokens.weth,
    quoteToken: arbitrumTokens.wbtc,
  },
  {
    pid: 6,
    v1pid: 6,
    nftBoost: true,
    lpSymbol: 'ARB-USDC LP',
    lpAddress: '0x1Fba5288C108dfe673B4d8b3fE39fC6675bEdB17',
    token: arbitrumTokens.arb,
    quoteToken: arbitrumTokens.usdc,
  },
  {
    pid: 7,
    v1pid: 7,
    nftBoost: true,
    lpSymbol: 'GMX-USDC LP',
    lpAddress: '0x155F7aC18e7a1C3b4C5Ff96cC18d2692C1CdFb41',
    token: arbitrumTokens.gmx,
    quoteToken: arbitrumTokens.usdc,
  },

  // {
  //   pid: 0,
  //   v1pid: 0,
  //   lpSymbol: 'MMF',
  //   lpAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   token: bscTokens.syrup,
  //   quoteToken: bscTokens.wbnb,
  // },
  // {
  //   pid: 2,
  //   v1pid: 251,
  //   lpSymbol: 'MMF-BNB LP',
  //   lpAddress: CAKE_BNB_LP_MAINNET,
  //   token: bscTokens.cake,
  //   quoteToken: bscTokens.wbnb,
  //   boosted: true,
  // },
  // {
  //   pid: 39,
  //   v1pid: 389,
  //   lpSymbol: 'MMF-BUSD LP',
  //   lpAddress: '0x804678fa97d91B974ec2af3c843270886528a9E6',
  //   boosted: true,
  //   token: bscTokens.cake,
  //   quoteToken: bscTokens.busd,
  // },
  // {
  //   pid: 47,
  //   v1pid: 422,
  //   lpSymbol: 'MMF-USDT LP',
  //   lpAddress: '0xA39Af17CE4a8eb807E076805Da1e2B8EA7D0755b',
  //   token: bscTokens.cake,
  //   quoteToken: bscTokens.usdt,
  //   boosted: true,
  // },
  // {
  //   pid: 3,
  //   v1pid: 252,
  //   lpSymbol: 'BUSD-BNB LP',
  //   lpAddress: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
  //   token: bscTokens.busd,
  //   quoteToken: bscTokens.wbnb,
  // },
].map((p) => ({ ...p, token: p.token.serialize, quoteToken: p.quoteToken.serialize }))

export default farms
