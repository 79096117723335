/* eslint-disable @typescript-eslint/no-shadow */
import poolsConfig from 'config/constants/pools'
import sousChefABI from 'config/abi/sousChef.json'
import erc20ABI from 'config/abi/erc20.json'
import multicall, { multicallv3 } from 'utils/multicall'
import { getAddress, getMasterChefAddress, getMulticallAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import uniq from 'lodash/uniq'
import fromPairs from 'lodash/fromPairs'
import multiCallAbi from 'config/abi/Multicall.json'
import masterChef from 'config/abi/masterchef.json'
import { getMasterchefContract } from 'utils/contractHelpers'

// Pool 0, Cake / Cake is a different kind of contract (master chef)
// BNB pools use the native BNB token (wrapping ? unwrapping is done at the contract level)
const nonMasterPools = poolsConfig.filter((pool) => pool.sousId !== 0)
const MasterPools = poolsConfig.filter((pool) => pool.sousId === 0)
const masterChefContract = getMasterchefContract()
const multicallAddress = getMulticallAddress()

export const fetchPoolsAllowance = async (account, chainId) => {
  const nonBnbPools = poolsConfig.filter((pool) => pool.stakingToken[chainId]?.symbol !== 'ETH')
  const calls = nonBnbPools.map((pool) => ({
    address: pool.stakingToken[chainId]?.address,
    name: 'allowance',
    params: [account, getAddress(pool.contractAddress, chainId)],
  }))

  const allowances = await multicall(erc20ABI, calls, chainId)
  return fromPairs(nonBnbPools.map((pool, index) => [pool.sousId, new BigNumber(allowances[index]).toJSON()]))
}

export const fetchUserBalances = async (account, chainId) => {
  // Non BNB pools
  const bnbPools = poolsConfig.filter((pool) => pool.stakingToken[chainId]?.symbol === 'ETH')
  const nonBnbPools = poolsConfig.filter((pool) => pool.stakingToken[chainId]?.symbol !== 'ETH')
  const tokens = uniq(nonBnbPools.map((pool) => pool.stakingToken[chainId]?.address))
  const tokenBalanceCalls = tokens.map((token) => ({
    abi: erc20ABI,
    address: token,
    name: 'balanceOf',
    params: [account],
  }))
  const bnbBalanceCall = {
    abi: multiCallAbi,
    address: multicallAddress,
    name: 'getEthBalance',
    params: [account],
  }
  const tokenBnbBalancesRaw = await multicallv3({ calls: [...tokenBalanceCalls, bnbBalanceCall], chainId })
  const bnbBalance = tokenBnbBalancesRaw.pop()
  const tokenBalances = fromPairs(tokens.map((token, index) => [token, tokenBnbBalancesRaw[index]]))
  // console.log(tokens, tokenBnbBalancesRaw, tokenBalances, bnbBalance);
  const poolTokenBalances = fromPairs(
    nonBnbPools
      .map((pool) => {
        if (!tokenBalances[pool.stakingToken[chainId]?.address]) return null
        return [pool.sousId, new BigNumber(tokenBalances[pool.stakingToken[chainId]?.address]).toJSON()]
      })
      .filter(Boolean),
  )
  // console.log("nonbnbPools", tokenBalances, poolTokenBalances)

  // BNB pools
  const bnbBalanceJson = new BigNumber(bnbBalance.toString()).toJSON()
  const bnbBalances = fromPairs(bnbPools.map((pool) => [pool.sousId, bnbBalanceJson]))
  // console.log("bnbPools", bnbBalances, bnbBalanceJson)
  return { ...poolTokenBalances, ...bnbBalances }
}

export const fetchUserStakeBalances = async (account, chainId) => {
  // console.log(nonMasterPools);
  const calls = nonMasterPools.map((p) => ({
    address: getAddress(p.contractAddress, chainId),
    name: 'userInfo',
    params: [account],
  }))
  const userInfo = await multicall(sousChefABI, calls, chainId)
  // return fromPairs(
  //   nonMasterPools.map((pool, index) => [pool.sousId, new BigNumber(userInfo[index].amount._hex).toJSON()]),
  // )
  const stakedBalances = nonMasterPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(userInfo[index].amount._hex).toJSON(),
    }),
    {},
  )

  // Masterpools
  const calls2 = MasterPools.map((pool) => {
    return {
      address: getMasterChefAddress(chainId),
      name: 'userInfo',
      params: [0, account],
    }
  })

  const rawStakedBalances = await multicall(masterChef, calls2, chainId)
  const stakedBalances2 = MasterPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(rawStakedBalances[index].amount._hex).toJSON(),
    }),
    {},
  )
  // console.log("userstakedbalances", stakedBalances, stakedBalances2);
  return { ...stakedBalances, ...stakedBalances2 }
}

export const fetchUserPendingRewards = async (account, chainId) => {
  const calls = nonMasterPools.map((p) => ({
    address: getAddress(p.contractAddress, chainId),
    name: 'pendingReward',
    params: [account],
  }))
  // const res = await multicall(sousChefABI, calls)
  // return fromPairs(nonMasterPools.map((pool, index) => [pool.sousId, new BigNumber(res[index]).toJSON()]))
  const res = await multicall(sousChefABI, calls, chainId)
  const pendingRewards = nonMasterPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(res[index]).toJSON(),
    }),
    {},
  )

  // Masterpools
  const calls2 = MasterPools.map((pool) => {
    return {
      address: getMasterChefAddress(chainId),
      name: 'pendingMeerkat',
      params: [0, account],
    }
  })

  const rawStakedBalances = await multicall(masterChef, calls2, chainId)
  const pendingMeerkat2 = MasterPools.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(rawStakedBalances[0][0].toString()).toJSON(),
    }),
    {},
  )
  // const pendingReward = await masterChefContract.pendingMeerkat('0', account)

  return { ...pendingRewards, ...pendingMeerkat2 }
}
