import { createSlice } from '@reduxjs/toolkit'
import { VeMmfState } from '@madmeerkatfinance/vemmf/src/types'
import fetchVeMMFAllowance, { fetchVeMMFTotalSupply } from './fetchVeMmfUser'

const initialState: VeMmfState = {
  userDataLoaded: false,
  data: {
    allowance: '0',
    totalSupply: '0',
    vaults: '0',
    mine: '0',
  },
}

export const fetchVeMMFPoolDataAsync = (veMMF) => async (dispatch) => {
  const totalSupply = await fetchVeMMFTotalSupply(veMMF)

  const userData = {
    totalSupply,
  }

  dispatch(updateVeMMFUserData(userData))
}

export const fetchVeMMFUserDataAsync =
  (veMMF, account: string) =>
  async (dispatch) => {
    const allowance = await fetchVeMMFAllowance(veMMF, account)

    const userData = {
      allowance,
    }

    dispatch(updateVeMMFUserData(userData))
  }

export const veMmfSlice = createSlice({
  name: 'vemmf',
  initialState,
  reducers: {
    setVeMMFUserData: (state, action) => {
      state.data = action.payload
      state.userDataLoaded = true
    },
    updateVeMMFUserData: (state, action) => {
      const value = action.payload
      state.data = { ...state.data, ...value }
      state.userDataLoaded = true
    },
  },
})

// Actions
export const {
  updateVeMMFUserData,
  setVeMMFUserData,
} = veMmfSlice.actions

export default veMmfSlice.reducer
