import BigNumber from 'bignumber.js'
import { getMmfUnlockerAddress } from 'utils/addressHelpers'
import { getBep20Contract, getMmfUnlockerContract, getXMmfContract } from 'utils/contractHelpers'
import { multicallv2 } from 'utils/multicall'
import mmfUnlockerAbi from 'config/abi/mmfUnlocker.json'
import { BIG_TEN } from '@madmeerkatfinance/utils/bigNumber'
import { arbitrumTokens } from '@madmeerkatfinance/tokens'

export const fetchXMmfBarTotalSupply = async (chainId: number) => {
  const xmmfBarContract = getXMmfContract(null, chainId)
  const totalSupply = await xmmfBarContract.totalSupply()
  return new BigNumber(totalSupply._hex).toJSON()
}

export const fetchXMmfUnlockedBoost = async (chainId: number) => {
  const lockTimes = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21]
  const calls = lockTimes.map((x) => ({
    address: getMmfUnlockerAddress(chainId),
    name: 'getLockBoost',
    params: [x * 86400],
  }))
  const lockBoost = await multicallv2({
    abi: mmfUnlockerAbi,
    calls,
  })
  return lockBoost
    .map((data, key) => ({
      [lockTimes[key] * 86400]: data.toString(),
    }))
    .reduce((obj, item) => ({ ...obj, ...item }), {})
}

const fetchXMmfBarAllowance = async (account: string, chainId: number) => {
  try {
    const contract = getBep20Contract(arbitrumTokens.mmf.address)
    const xmmfUnlockerContract = getMmfUnlockerContract(null, chainId)
    const xmmfBarContract = getXMmfContract(null, chainId)

    const allowance = await contract.allowance(account, xmmfUnlockerContract.address)
    const allowanceXmmfUnlocker = await xmmfBarContract.allowance(account, xmmfUnlockerContract.address)

    return {
      xMmfAllowance: allowance.toString(),
      xMmfUnlockerAllowance: allowanceXmmfUnlocker.toString(),
    }
  } catch (error) {
    return {
      xMmfAllowance: '0',
      xMmfUnlockerAllowance: '0',
    }
  }
}

export const fetchListUnlocking = async (account: string, chainId: number) => {
  try {
    const xmmfUnlockerContract = getMmfUnlockerContract(null, chainId)
    const numberUnlockRequest = await xmmfUnlockerContract.getDepositId(account)
    const numberUnlock = new BigNumber(numberUnlockRequest._hex).toNumber()
    if (numberUnlock > 0) {
      const calls = [...Array(numberUnlock).keys()].map((x) => ({
        address: getMmfUnlockerAddress(chainId),
        name: 'getUserInfo',
        params: [account, x + 1],
      }))
      const userInfos = await multicallv2({
        abi: mmfUnlockerAbi,
        calls,
      })
      return userInfos.map((userInfo, index) => {
        const depositedAmount = userInfo[0]
        const depositedAt = userInfo[1]
        const lockTime = userInfo[2]
        const hasClaimed = userInfo[3]

        return {
          depositId: index + 1, // the ID of the unlock request
          depositedAmount: new BigNumber(depositedAmount._hex).div(BIG_TEN.pow(18)).toString(),
          depositedAt: depositedAt.toString(),
          lockTime: lockTime.toNumber(),
          hasClaimed,
        }
      })
    }
    return []
  } catch (error) {
    console.error(error)
    return []
  }
}

export default fetchXMmfBarAllowance
