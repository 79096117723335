import { useMemo } from 'react'
import styled from 'styled-components'
import { Text, Flex, CloseIcon, IconButton } from '@madmeerkatfinance/uikit'
import { useTranslation } from '@madmeerkatfinance/localization'
import { usePhishingBanner } from '@madmeerkatfinance/utils/user'

const Container = styled(Flex)`
  overflow: hidden;
  height: 100%;
  padding: 12px;
  align-items: center;
  background: linear-gradient(180deg, #03ffff 0%, #0b89c2 100%);
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 0px;
  }
`

const InnerContainer = styled(Flex)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const SpeechBubble = styled.div`
  background: rgba(0, 0, 0, 0.7);
  border-radius: 16px;
  padding: 8px 20px;
  flex-wrap: wrap;

  & ${Text} {
    flex-shrink: 0;
    margin-right: 4px;
  }
`

const domain = 'https://arbimm.finance'

const PhishingWarningBanner: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation()
  const [, hideBanner] = usePhishingBanner()
  const warningTextAsParts = useMemo(() => {
    const warningText = t("please make sure you're visiting %domain% - check the URL carefully.", { domain })
    return warningText.split(/(https:\/\/mm.finance)/g)
  }, [t])
  const warningTextComponent = (
    <>
      <Text as="span" color="warning" small bold textTransform="uppercase">
        {t('Phishing warning: ')}
      </Text>
      {warningTextAsParts.map((text, i) => (
        <Text
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          small
          as="span"
          bold={text === domain}
          color="#FFFFFF"
        >
          {text}
        </Text>
      ))}
    </>
  )
  return (
    <Container>
      <>
        <InnerContainer>
          <SpeechBubble>{warningTextComponent}</SpeechBubble>
        </InnerContainer>
        <IconButton onClick={hideBanner} variant="text">
          <CloseIcon color="#000" />
        </IconButton>
      </>
    </Container>
  )
}

export default PhishingWarningBanner
