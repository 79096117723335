import { StaticJsonRpcProvider } from '@ethersproject/providers'
import { ChainId } from '@madmeerkatfinance/sdk'
import sample from 'lodash/sample'

export const BSC_PROD_NODE = process.env.NEXT_PUBLIC_NODE_PRODUCTION || 'https://bsc.nodereal.io'

export const bscRpcProvider = new StaticJsonRpcProvider(
  {
    url: BSC_PROD_NODE,
    skipFetchSetup: true,
  },
  ChainId.BSC,
)

// @see https://github.com/ethers-io/ethers.js/issues/1886#issuecomment-934822755
export const bscTestnetRpcProvider = new StaticJsonRpcProvider(
  {
    url: process.env.NEXT_PUBLIC_NODE_TESTNET || 'https://data-seed-prebsc-1-s2.binance.org:8545',
    skipFetchSetup: true,
  },
  ChainId.BSC_TESTNET,
)

export const ethMainnetRpcProvider = new StaticJsonRpcProvider(
  {
    url: `https://eth-mainnet.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODE_REAL_API_ETH}`,
    skipFetchSetup: true,
  },
  ChainId.ETHEREUM,
)

export const ethGoerliRpcProvider = new StaticJsonRpcProvider(
  {
    url: `https://eth-goerli.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODE_REAL_API_GOERLI}`,
    skipFetchSetup: true,
  },
  ChainId.GOERLI,
)

const arbArray = [
  'https://arbitrum.drpc.org',
  'wss://arbitrum-one-rpc.publicnode.com',
  'https://arbitrum.meowrpc.com',
]
const arbiRPC = arbArray[Math.floor(Math.random() * arbArray.length)]

export const arbitrumProvider = new StaticJsonRpcProvider(
  {
    url: arbiRPC,
    skipFetchSetup: true,
  },
  ChainId.ARBITRUM,
)

export const cronosProvider = new StaticJsonRpcProvider(
  {
    url: 'https://mmf-rpc.xstaking.sg',
    skipFetchSetup: true,
  },
  ChainId.CRONOS,
)

export const provider = ({ chainId }: { chainId?: ChainId }) => {
  if (chainId === ChainId.BSC_TESTNET) {
    return bscTestnetRpcProvider
  }
  if (chainId === ChainId.BSC) {
    return bscRpcProvider
  }
  if (chainId === ChainId.ARBITRUM) {
    return arbitrumProvider
  }
  if (chainId === ChainId.CRONOS) {
    return cronosProvider
  }
  if (chainId === ChainId.ETHEREUM) {
    return ethMainnetRpcProvider
  }
  if (chainId === ChainId.GOERLI) {
    return ethGoerliRpcProvider
  }
  return bscRpcProvider
}

export default null
