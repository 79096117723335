const variants = {
  warning: {
    background: "rgba(29, 18, 3, 0.62)",
    borderColor: "warning",
  },
  danger: {
    background: "rgba(36, 8, 22, 0.62)",
    borderColor: "failure",
  },
  success: {
    background: "rgba(0, 31, 22, 0.62)",
    borderColor: "success",
  },
  primary: {
    background: "rgba(0, 33, 33, 0.62)",
    borderColor: "secondary",
  },
};

export default variants;
